/* eslint-disable camelcase */

import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';
import { isExist } from 'util/helper';


import { State as GlobalState } from './reducers';

// is_memorial: 0 -> 否, 1 -> 是
// is_southern_taipei_event: 0 -> 否, 1 -> 為城南空間 no.1, 2 -> 城南空間 no.2

const API = {
	"DEFAULT": 'is_memorial=0&is_southern_taipei_event=0',
	"MEMORIAL": `is_memorial=1&is_southern_taipei_event=0`,
	"SOUTHERN-1": 'is_memorial=0&is_southern_taipei_event=1',
	"SOUTHERN-2": 'is_memorial=0&is_southern_taipei_event=2',
}

export interface EventItemProperty {
	id: number;
	name: string;
	image: string;
	categoryName: string;
	categoryID: string;
	date: string;
	content?: string;
	weight?: number;
	description?: string;
}

type EventListItemPayload = Pick<State['eventList'], 'data' | 'currentCategoryId'>;

const handleDateRange = (begin: string, end: string) => {
	let date = begin.split('-').join('.');

	if (begin !== end) {
		date += " - ";
		date += end.split('-').join('.');
	}

	return date;
}

export type EventType = 'DEFAULT' | 'MEMORIAL' | 'SOUTHERN-1' | 'SOUTHERN-2';

type getEventListProperty = {
	type: EventType;
	categoryId?: number;
}


export const getEventList = createAction<Promise<EventListItemPayload>, getEventListProperty>('GET_EVENT_LIST', async ({ type, categoryId }) => {

	try {

		let query = `events?${API[type]}`;

		if (categoryId !== 0) {
			query += `&event_category_id=${categoryId}`;
		};

		const { status, message, data } = await wrapFetch(
			query,
			{
				method: 'GET',
			},
		);

		if (status !== 200) {
			throw new Error(message);
		}

		const { data: eventData } = data;


		return {
			data: eventData.map(({ eventCategoryName, eventCategoryId, eventBeginAt, eventEndAt, ...args }) => ({
				categoryName: eventCategoryName,
				categoryID: eventCategoryId,
				date: handleDateRange(eventBeginAt, eventEndAt),
				...args,
			})),
			currentSouthTaipeiSpace: type.split('-')[0] === 'SOUTHERN' && type.split('-')[1],
			currentCategoryId: categoryId || 0,
		};
	} catch (error) {
		throw new Error((error as Error).message);
	}
});

export interface accordionItemsProperty {
	title: string;
  content: string;
}

export interface EventItemAccordion extends EventItemProperty {
	accordionItems: accordionItemsProperty[];
}

export interface EventInfoProperty extends EventItemProperty {
	subEvents: EventItemAccordion[];
	mostImportantThree: EventItemProperty[];
	accordionItems: accordionItemsProperty[];
}

type EventInfoPayload = Pick<State['eventInfo'], 'data'>;

export const getEventInfo = createAction<Promise<EventInfoPayload>, string>(
	'GET_EVENT_INFO',
	async eventId => {
		try {
			const { status, message, data } = await wrapFetch(
				`events/${eventId}`,
				{ method: 'GET', },
			);

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			const { data: eventInfoData } = data;

			return {
				...eventInfoData,
				categoryName: eventInfoData.eventCategoryName,
				subEvents: eventInfoData.subEvents.length > 0 ? eventInfoData.subEvents : [],
				date: handleDateRange(eventInfoData.eventBeginAt, eventInfoData.eventEndAt),
				image: eventInfoData.image,
				description: eventInfoData.description,
				mostImportantThree: isExist(eventInfoData.mostImportantThree) ? eventInfoData.mostImportantThree.map(({ eventBeginAt: sub_eventBeginAt, eventEndAt: sub_eventEndAt, eventCategoryName, ...subArgs }) => ({
					date: handleDateRange(sub_eventBeginAt, sub_eventEndAt),
					categoryName: eventCategoryName,
					...subArgs,
				})) : [],
			};
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

export const setEventInfoPreview = createAction('SET_EVENT_INFO_PREVIEW', (eventInfoData: EventInfoPayload) => ({
	...eventInfoData,
	categoryName: eventInfoData.eventCategoryName,
	date: handleDateRange(eventInfoData.eventBeginAt, eventInfoData.eventEndAt),
	image: eventInfoData.image,
	description: eventInfoData.description,
	mostImportantThree: [],
}));

type setEventInfoSubEventPayload = Pick<State['eventInfo'], 'currentSubEventId'>;


export const setEventInfoSubEventId = createAction<Promise<setEventInfoSubEventPayload>, string>('SET_EVENT_INFO_SUB_EVENT_ID', async subEventId => ({
	currentSubEventId: subEventId
}),
);

export const setSubEventInfoPreview = createAction('SET_SUB_EVENT_INFO_PREVIEW', (eventInfoData: EventInfoPayload) => ({
	...eventInfoData,
	categoryName: eventInfoData.eventCategoryName,
	date: handleDateRange(eventInfoData.eventBeginAt, eventInfoData.eventEndAt),
	image: eventInfoData.image,
	description: eventInfoData.description,
	mostImportantThree: [],
	currentSubEventId: eventInfoData.id,
}));

export interface eventCategoryProperty {
	id: number;
	name: string;
}

type EventCategoryPayload = Pick<State['eventCategory'], 'data'>;

export const getEventCategory = createAction<Promise<EventCategoryPayload>>('GET_EVENT_CATEGORY', async () => {

	try {
		const { status, message, data } = await wrapFetch(
			'event-categories',
			{
				method: 'GET',
			},
		);

		if (status !== 200) {
			throw new Error(message);
		}

		return data;
	} catch (error) {
		throw new Error((error as Error).message);
	}
});

type EventYearPayload = Pick<State['eventYear'], 'data'>;

export const getEventYear = createAction<Promise<EventYearPayload>, EventType>('GET_EVENT_YEAR', async type => {

	try {
		const { status, message, data } = await wrapFetch(
			`events/years?${API[type]}`,
			{
				method: 'GET',
			},
		);

		if (status !== 200) {
			throw new Error(message);
		}

		return data;
	} catch (error) {
		throw new Error((error as Error).message);
	}
});

export type SouthTaipeiSpaceNumber = 1 | 2;


export interface State {
	eventList: {
		loading: boolean;
		error: string;
		data: EventItemProperty[];
		currentSouthTaipeiSpace: SouthTaipeiSpaceNumber;
		currentCategoryId: number;
	};
	eventInfo: {
		loading: boolean;
		error: string;
		data: EventInfoProperty;
		currentSubEventId: string;
	};
	eventCategory: {
		loading: boolean;
		error: string;
		data: eventCategoryProperty[];
	},
	eventYear: {
		loading: boolean;
		error: string;
		data: string[];
	}
}

export const defaultState: State = {
	eventList: {
		loading: false,
		error: '',
		data: [],
		currentSouthTaipeiSpace: 1,
		currentCategoryId: 0,
	},
	eventInfo: {
		loading: false,
		error: '',
		data: {
			id: 0,
			name: '',
			content: '',
			image: '',
			date: '',
			categoryName: '',
			categoryID: '',
			weight: 0,
			subEvents: [],
			description: '',
			mostImportantThree: [
				{
					id: 1,
					name: '',
					content: '',
					image: '',
					date: '',
					categoryName: '',
					categoryID: '',
				},
				{
					id: 2,
					name: '',
					content: '',
					image: '',
					date: '',
					categoryName: '',
					categoryID: '',
				},
				{
					id: 3,
					name: '',
					content: '',
					image: '',
					date: '',
					categoryName: '',
					categoryID: '',
				},
			],
		},
		currentSubEventId: '',
	},
	eventCategory: {
		loading: false,
		error: '',
		data: [],
	},
	eventYear: {
		loading: false,
		error: '',
		data: [],
	}
};

export const reducer = {
	event: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_EVENT_LIST_PENDING: state => ({
				...state,
				eventList: {
					...state.eventList,
					loading: true,
					error: '',
				},
			}),

			GET_EVENT_LIST_FULFILLED: (state, action: Action<EventListItemPayload>) => ({
				...state,
				eventList: {
					...state.eventList,
					...action.payload,
					loading: false,
					data: [...action.payload.data],
					currentCategoryId: action.payload.currentCategoryId,
				},
			}),

			GET_EVENT_LIST_REJECTED: (state, action) => ({
				...state,
				eventList: {
					...state.eventList,
					loading: false,
					error: action.payload.message,
				},
			}),

			GET_EVENT_INFO_PENDING: state => ({
				...state,
				eventInfo: {
					...state.eventInfo,
					loading: true,
					error: '',
				},
			}),

			GET_EVENT_INFO_FULFILLED: (state, action: Action<EventInfoPayload>) => ({
				...state,
				eventInfo: {
					...state.eventInfo,
					loading: false,
					data: { ...action.payload },
				},
			}),

			GET_EVENT_INFO_REJECTED: (state, action) => ({
				...state,
				eventInfo: {
					...state.eventInfo,
					loading: false,
					error: action.payload.message,
				},
			}),

			SET_EVENT_INFO_PREVIEW: (state, action) => ({
				...state,
				eventInfo: {
					...state.eventInfo,
					data: { ...action.payload },
				},
			}),

			SET_EVENT_INFO_SUB_EVENT_ID_PENDING: state => ({
				...state,
				eventInfo: {
					...state.eventInfo,
					loading: true,
					error: '',
				},
			}),

			SET_EVENT_INFO_SUB_EVENT_ID_FULFILLED: (state, action) => ({
				...state,
				eventInfo: {
					...state.eventInfo,
					currentSubEventId: action.payload.currentSubEventId,
				},
			}),

			SET_EVENT_INFO_SUB_EVENT_ID_REJECTED: (state, action) => ({
				...state,
				eventInfo: {
					...state.eventInfo,
					loading: false,
					error: action.payload.message,
				},
			}),

			SET_SUB_EVENT_INFO_PREVIEW: (state, action) => ({
				...state,
				eventInfo: {
					...state.eventInfo,
					data: { ...action.payload },
				},
			}),

			GET_EVENT_CATEGORY_PENDING: state => ({
				...state,
				eventCategory: {
					...state.eventCategory,
					loading: true,
					error: '',
				},
			}),

			GET_EVENT_CATEGORY_FULFILLED: (state, action: Action<EventCategoryPayload>) => ({
				...state,
				eventCategory: {
					...state.eventCategory,
					...action.payload,
					loading: false,
					data: action.payload.data,
				},
			}),

			GET_EVENT_CATEGORY_REJECTED: (state, action) => ({
				...state,
				eventCategory: {
					...state.eventCategory,
					loading: false,
					error: action.payload.message,
				},
			}),

			GET_EVENT_YEAR_PENDING: state => ({
				...state,
				eventYear: {
					...state.eventYear,
					loading: true,
					error: '',
				},
			}),

			GET_EVENT_YEAR_FULFILLED: (state, action: Action<EventListItemPayload>) => ({
				...state,
				eventYear: {
					...state.eventYear,
					...action.payload,
					loading: false,
					data: [...action.payload.data],
				},
			}),

			GET_EVENT_YEAR_REJECTED: (state, action) => ({
				...state,
				eventYear: {
					...state.eventYear,
					loading: false,
					error: action.payload.message,
				},
			}),
		},
		defaultState,
	), // eslint-disable-line @typescript-eslint/no-explicit-any
};

/* +----------------------------------------------------------------------
++ useEventList ++
++----------------------------------------------------------------------*/

const selectEventList = (state: GlobalState) => state.event.eventList;
// for local demo
// export const useEventList = () => useRedux(selectEventList, {});



const eventListActionMap = { getEventList };
export const useEventList = () => useRedux(selectEventList, eventListActionMap);

/* +----------------------------------------------------------------------
++ useEventInfo ++
++----------------------------------------------------------------------*/
const selectEventInfo = (state: GlobalState) => state.event.eventInfo;
// for local demo
// export const useEventInfo = () => useRedux(selectEventInfo, {});

const eventInfoActionMap = { getEventInfo, setEventInfoSubEventId };
export const useEventInfo = () => useRedux(selectEventInfo, eventInfoActionMap);

/* +----------------------------------------------------------------------
++ useEventCategory ++
++----------------------------------------------------------------------*/

const selectEventCategory = (state: GlobalState) => state.event.eventCategory;
// for local demo
// export const useEventCategory = () => useRedux(selectEventCategory, {});

const eventCategoryActionMap = { getEventCategory };
export const useEventCategory = () => useRedux(selectEventCategory, eventCategoryActionMap);


/* +----------------------------------------------------------------------
++ useEventYear ++
++----------------------------------------------------------------------*/

const selectEventYear = (state: GlobalState) => state.event.eventYear;
// for local demo
// export const useEventList = () => useRedux(selectEventYear, {});


const eventYearActionMap = { getEventYear };
export const useEventYear = () => useRedux(selectEventYear, eventYearActionMap);