import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { 
	getPublicationList,
	getMagazineList,
	clearPublicationList,
	clearMagazineList,
	getPublicationYearsList,
	getMagazineYearsList,
	getFountainInfoProcess,
	selectFountainInfoType
} from 'models/fountain';

const routes: CustomRoute = {
	path: '/fountain',
	render: (_, children) => children,
	components: () => [],
	children: [
		{
			path: '',
			components: () => [import('./component')],
			render: ([Fountain]) => <Fountain />,
			onEnter: async ({ store }) => {
				console.log('on Enter Fountain');
				await store.dispatch(clearPublicationList());
				await store.dispatch(clearMagazineList());
				await store.dispatch(getPublicationList(''));
				await store.dispatch(getMagazineList(''));
				await store.dispatch(getPublicationYearsList());
				await store.dispatch(getMagazineYearsList());
				console.log('on Enter Fountain / end');
			},
		},

		{
			path: '/:id',
			components: () => [import('./Publication/component'), import('./Magazine/component')],
			onPreEnter: async ({ store, params }) => {
				/**
				 * 說明：
				 * 這個路徑會根據 api 回傳的 type 決定要 render 哪一個頁面
				 * onEnter 會 call api 並回傳 type
				 * render 會接收 components 跟 type，並根據 type 的值來決定實際render 的是哪一個頁面
				 * type === 'FOUNTAIN' render <Publication />
				 * type === 'OLD' render <Magazine />
				 */

				await store.dispatch(getFountainInfoProcess(`${params.id}`));
				const type = selectFountainInfoType(store.getState());

				if (type === 'FOUNTAIN') {
					await store.dispatch(clearPublicationList());
					await store.dispatch(getPublicationList(''));
				} else {
					await store.dispatch(clearMagazineList());
					await store.dispatch(getMagazineList(''));
				};

				return type;
			},
			render: ([Publication, Magazine], _, renderType) => renderType === 'FOUNTAIN' ? <Publication /> : <Magazine />,
		}
	]
};

export default routes;
