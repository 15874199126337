/* eslint-disable indent, @typescript-eslint/no-explicit-any */
import i18n from 'i18next';
import qs from 'qs';

import { updateAccessToken } from 'models/auth';
import { store } from '../index';
import storage from './storage';
import { isExist } from './helper';

const { API_ENDPOINT } = process.env;

const generateUrl = (url: string, params?: object) => {
	const paramsString = qs.stringify(params, { arrayFormat: 'brackets' });

	const URL =
		paramsString !== '' ? `${API_ENDPOINT}/backstage/api/v1/${url}?${paramsString}` : `${API_ENDPOINT}/backstage/api/v1/${url}`;

	return URL;
};

export const wrapFetch = async (
	url: string,
	options: RequestInit = { headers: {} },
	params: object = {},
) => {
	const URL = generateUrl(url, params);
	const localStorageLng = storage.getItem('i18nextLng');

	const headers = new Headers({
		'Content-Type': 'application/json',
		'Content-Language': i18n.language || localStorageLng || 'TW',
		Accept: 'application/json',
		...options.headers,
	});

	const result = await fetch(URL, { ...options, headers });

	return result.json();
};

export const wrapAuthFetch = async (
	url: string,
	options: RequestInit = { headers: {} },
	params: object = {},
	isRetryRequest = false,
): Promise<ReturnType<any>> => {
	const token = storage.getToken();

	if (token === '') {
		throw new Error('Token not exist');
	}

	const requestConfig = {
		...options,
		headers: {
			Authorization: `Bearer ${token}`,
			...options.headers,
		},
	};

	const result = await wrapFetch(url, requestConfig, params);

	if (
		(result.status === 401 || result.message === 'unauthenticated') &&
		!isRetryRequest
	) {
		const { data } = await refreshToken();

		if (!isExist(data?.data?.token)) {
			storage.removeToken();
			window.location.replace('/login');
			return result;
		}

		store.dispatch(updateAccessToken(data?.data?.token));
		return wrapAuthFetch(url, options, params, true);
	}

	return result;
};

const refreshToken = () => {
	const token = storage.getToken();

	if (token === '') {
		throw new Error('Token not exist');
	}

	return wrapFetch(
		'refresh-token',
		{
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
};

export const fetchEdm = async (email: string) => {
	const URL = `${API_ENDPOINT}/backstage/api/v1/edm-subscriptions`;

	const headers = new Headers({
		'Content-Type': 'application/json',
		Accept: 'application/json',
	});

	const result = await fetch(URL, { method: 'POST', body: JSON.stringify({ email }), headers });

	return result.json();
};

export const unsubscribeEdm = async (email: string) => {
	const URL = `${API_ENDPOINT}/backstage/api/v1/edm-subscriptions?email=${email}&status=UNSUBSCRIBED`;

	const headers = new Headers({
		'Content-Type': 'application/json',
		Accept: 'application/json',
	});

	const result = await fetch(URL, { method: 'PUT', body: JSON.stringify({ email }), headers });

	return result.json();
};
