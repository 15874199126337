/* eslint-disable @typescript-eslint/no-explicit-any */

import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';

import storage from "util/storage";
import { wrapFetch, wrapAuthFetch } from 'util/api';
import { updateAccessToken } from '.';

import { openModal, TOAST_TYPE, MODAL_CATEGORY } from '../modal';

import { getUser } from '../user';

export const defaultLoginForm = {
	email: { value: '', error: '' },
	password: { value: '', error: '' },
	remember: { value: false, error: '' },
};

export const defaultSignUpForm = {
	name: { value: '', error: '' },
	email: { value: '', error: '' },
	password: { value: '', error: '' },
	passwordConfirmation: { value: '', error: '' },
	gender: { value: '', error: '' },
	birthday: { year: '', month: '', day: '', error: '' },
	dialCode: { value: '+886', error: '' },
	phone: { value: '', error: '' },
	tel: { value: '', error: '' },
	county: { value: '', error: '' },
	district: { value: '', error: '' },
	address: { value: '', error: '' },
};

export const defaultForgotPasswordForm = {
	email: { value: '', error: '' },
};

export const defaultResetPasswordForm = {
	newPassword: { value: '', error: '' },
	confirmPassword: { value: '', error: '' },
};

export const setEmailToStorage = (email: string) => storage.setItem('email', email);
export const getEmailFromStorage = () => storage.getItem('email');

/**
 * Google 登入
 */
export const loginGoogle = createAction(
	'LOGIN_GOOGLE',
	(credentials: string) =>
		async (dispatch: Dispatch): Promise<null> => {
			console.log('google 登入，google response： ', credentials);

			const { status, message, data } = await wrapFetch('auth/google', {
				method: 'POST',
				body: JSON.stringify({ token: credentials }),
			});

			console.log('google 登入，後端 response: ', status, message, data);

			if (status !== 200 && status !== 201) {
				dispatch(
					openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.WARNING, data: '登入失敗' }),
				);
				throw new Error(message);
			}

			dispatch(updateAccessToken(data.data.token));
			return null;
		},
);

/**
 * Google 綁定
 */
export const bindGoogle = createAction(
	'BIND_GOOGLE',
	(credentials: string) =>
		async (dispatch: Dispatch): Promise<null> => {
			console.log('bind google: ', credentials);

			const { status, message, data } = await wrapAuthFetch('link/google', {
				method: 'POST',
				body: JSON.stringify({ token: credentials }),
			});

			console.log('bind google response: ', status, message, data);

			if (status !== 200 && status !== 201) {
				dispatch(
					openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.WARNING, data: '綁定失敗' }),
				);
				throw new Error(message);
			}

			await dispatch(
				openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.SUCCESS, data: '綁定成功' }),
			);
			await dispatch(getUser());
			return null;
		},
);

/**
 * Google 取消綁定
 */
export const unbindGoogle = createAction(
	'UNBIND_GOOGLE',
	(credentials: string) =>
		async (dispatch: Dispatch): Promise<null> => {
			console.log('unbind google: ', credentials);

			const { status, message, data } = await wrapAuthFetch('unlink/google', {
				method: 'POST',
				body: JSON.stringify({ token: credentials }),
			});

			console.log('unbind google response: ', status, message, data);

			if (status !== 200 && status !== 201) {
				dispatch(
					openModal({
						category: MODAL_CATEGORY.TOAST,
						type: TOAST_TYPE.WARNING,
						data: '取消綁定失敗',
					}),
				);
				throw new Error(message);
			}

			await dispatch(
				openModal({
					category: MODAL_CATEGORY.TOAST,
					type: TOAST_TYPE.SUCCESS,
					data: '取消綁定成功',
				}),
			);
			await dispatch(getUser());
			return null;
		},
);

/**
 * Facebook 登入
 */
const facebookNativeLogin = () =>
	new Promise((resolve, reject) => {
		window.FB.login(
			(response: any) => {
				if (response.status === 'connected') {
					resolve(response);
				} else {
					reject(response);
				}
			},
			{ scope: 'public_profile,email' },
		);
	});

export const loginFacebook = createAction(
	'LOGIN_FACEBOOK',
	() =>
		async (dispatch: Dispatch): Promise<null> => {
			const auth: any = await facebookNativeLogin();

			const { accessToken } = auth.authResponse;

			console.log('facebook 登入: ', accessToken);

			const { status, message, data } = await wrapFetch('auth/facebook', {
				method: 'POST',
				body: JSON.stringify({ token: accessToken }),
			});

			console.log('facebook 登入，後端 response: ', status, message, data);

			if (status !== 200 && status !== 201) {
				dispatch(
					openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.WARNING, data: '登入失敗' }),
				);
				throw new Error(message);
			}

			dispatch(updateAccessToken(data.data.token));
			return null;
		},
);

/**
 * Facebook 綁定
 */
export const bindFacebook = createAction(
	'BIND_FACEBOOK',
	() =>
		async (dispatch: Dispatch): Promise<null> => {
			const auth: any = await facebookNativeLogin();

			const { accessToken } = auth.authResponse;

			console.log('bind facebook: ', accessToken);

			const { status, message, data } = await wrapAuthFetch('link/facebook', {
				method: 'POST',
				body: JSON.stringify({ token: accessToken }),
			});

			console.log('bind facebook response: ', status, message, data);

			if (status !== 200 && status !== 201) {
				dispatch(
					openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.WARNING, data: '綁定失敗' }),
				);
				throw new Error(message);
			}

			await dispatch(
				openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.SUCCESS, data: '綁定成功' }),
			);
			await dispatch(getUser());
			return null;
		},
);

/**
 * Facebook 取消綁定
 */
export const unbindFacebook = createAction(
	'UNBIND_FACEBOOK',
	() =>
		async (dispatch: Dispatch): Promise<null> => {
			const auth: any = await facebookNativeLogin();

			const { accessToken } = auth.authResponse;

			console.log('unbind facebook: ', accessToken);

			const { status, message, data } = await wrapAuthFetch('unlink/facebook', {
				method: 'POST',
				body: JSON.stringify({ token: accessToken }),
			});

			console.log('unbind facebook response: ', status, message, data);

			if (status !== 200 && status !== 201) {
				dispatch(
					openModal({
						category: MODAL_CATEGORY.TOAST,
						type: TOAST_TYPE.WARNING,
						data: '取消綁定失敗',
					}),
				);
				throw new Error(message);
			}

			await dispatch(
				openModal({
					category: MODAL_CATEGORY.TOAST,
					type: TOAST_TYPE.SUCCESS,
					data: '取消綁定成功',
				}),
			);
			await dispatch(getUser());
			return null;
		},
);
