import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { getEventList, getEventYear } from 'models/event';

import SouthTaipeiSpaceDetailRoute from './SouthTaipeiSpaceDetail';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '',
	components: () => [],
	render: (_, children) => children,
	onEnter: async () => {},
	children: [
		{
			path: '',
			components: () => [],
			onEnter: async ({ next }) => {
				const children = await next();
				return children;
			},
			render: (_, children) => children,
			children: [
				{
					path: '/south-taipei-space-one',
					components: () => [import(/* webpackChunkName: 'southTaipeiSpaceOne' */ './component')],
					render: ([Event]) => <Event />,
					onEnter: async ({ next, store }) => {
						console.log('on Enter SouthTaipeiSpaceOne');
						await store.dispatch(getEventList({ type: 'SOUTHERN-1', categoryId: 0 }));
						await store.dispatch(getEventYear('SOUTHERN-1'));
						await next();
						console.log('on Enter SouthTaipeiSpaceOne / end');
					},
				},
				{
					path: '/south-taipei-space-two',
					components: () => [import(/* webpackChunkName: 'southTaipeiSpaceTwo' */ './component')],
					render: ([Event]) => <Event />,
					onEnter: async ({ next, store }) => {
						console.log('on Enter SouthTaipeiSpaceTwo');
						await store.dispatch(getEventList({ type: 'SOUTHERN-2', categoryId: 0 }));
						await store.dispatch(getEventYear('SOUTHERN-2'));
						await next();
						console.log('on Enter SouthTaipeiSpaceTwo / end');
					},
				},
				SouthTaipeiSpaceDetailRoute,
			],
		},
	],
};
export default routes;
