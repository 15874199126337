import { createAction, handleActions } from 'redux-actions';
import moment from 'moment';

import { useRedux } from 'util/hook/redux';
import { LangCode, langCodeList } from 'util/i18n/config';

import { State as GlobalState } from './reducers';

export const changeLanguage = createAction('CHANGE_LANGUAGE', (lang: string) => {
	document.body.setAttribute('lang', lang);
	moment.locale(lang);
	return { lang };
});

// For Global State usage
export interface State {
	lang: LangCode;
}

export const defaultState: State = {
	lang: langCodeList[0],
};


export const reducer = {
	i18n: handleActions(
		{
			CHANGE_LANGUAGE: (state, action) => ({
				...state,
				lang: action.payload.lang,
			}),
		},
		defaultState,
	),
};

const mapHooksToState = (state: GlobalState) => state.i18n.lang;
const languageActionsMap = {};

type LanguageSelector = ReturnType<typeof mapHooksToState>;
type LanguageActionsMap = typeof languageActionsMap;

export const useLanguage = () =>
	useRedux<LanguageSelector, LanguageActionsMap>(mapHooksToState, languageActionsMap);
