import 'whatwg-fetch';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';

// A modern alternative to CSS resets
import 'normalize.css';
// Global css setting
import './global.css';

import Router from 'layouts/Router';

import { loadTokenFromLocalstorage } from 'models/auth';
import { initi18next } from 'util/i18n';

import { defaultState } from 'models/reducers';
import configureStore from 'store';
import history from 'store/history';
import routes from 'routes';

import { loadFacebookLibrary } from 'util/external';

export const store = configureStore(defaultState);

const i18n = initi18next(store);

store.dispatch(loadTokenFromLocalstorage());

loadFacebookLibrary();

const rootElement = document.getElementById('content');

if (rootElement !== null) {
	const root = createRoot(rootElement);

	root.render(
		<I18nextProvider i18n={i18n}>
			<Provider store={store}>
				<Router history={history} routes={routes} store={store} />
			</Provider>
		</I18nextProvider>,
	);
}
