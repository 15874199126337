import React from 'react';
import { isExist } from 'util/helper';

import useNavi from 'util/hook/useNavi';

import classnames from 'classnames';
import { NaviMenuProperty } from 'components/molecules/Navigation';

import Link from 'components/atoms/Link';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';

import minus from 'images/icon/general/minus.inline.svg';
import plus from 'images/icon/general/plus.inline.svg';

import styles from './index.module.css';

interface AccordionProperty {
	className?: string;
	data: NaviMenuProperty[];
}

const Accordion: React.FC<AccordionProperty> = ({ className, data }) => {
	const { menuType, setMenuType } = useNavi();

	return (
		<div className={classnames(styles.accordion, className)}>
			{data.map(menu => {
				if (isExist(menu.sub_group)) {
					return (
						<div
							className={classnames(styles.itemGroup, { [styles.active]: menu.id === menuType })}
							key={menu.title}
							style={
								menu.id === menuType
									? { maxHeight: `calc(${(menu.sub_group.length + 2) * 48}px)` }
									: { maxHeight: '48px' }
							}
						>
							<Button
								className={styles.item}
								color="transparent"
								onClick={() => {
									if (menuType !== `${menu.id}`) {
										setMenuType(`${menu.id}`);
									} else {
										setMenuType(`nav`);
									}
								}}
							>
								{menu.title}
								<Icon
									className={classnames(styles.icon, styles.minus)}
									src={minus}
									size="x-small"
								/>
								<Icon className={classnames(styles.icon, styles.plus)} src={plus} size="x-small" />
							</Button>

							{menu.sub_group?.map(subItem => (
								<Link
									key={subItem.title}
									className={styles.subItem}
									to={subItem.link}
									onClick={subItem.onClick}
								>
									{subItem.title}
								</Link>
							))}
						</div>
					);
				}

				return (
					<Link key={menu.title} className={styles.item} to={menu.link}>
						{menu.title}
					</Link>
				);
			})}
		</div>
	);
};

export default Accordion;
