import classnames from 'classnames';
import { sleep } from '../helper';

export const fixBackgroundScrollY = () => {
	if (!document.body.className.includes('no-scroll')) {
		document.body.style.top = `-${window.pageYOffset}px`;
		document.body.className = classnames(document.body.className, 'no-scroll');
	}
};

export const unfixBackgroundScrollY = async () => {
	const classNameArray = document.body.className.split(' ');
	const newClassName = classNameArray.filter(item => item !== 'no-scroll').join(' ');
	document.body.className = newClassName;

	const matchesTop = document.body.style.top.match(/\d+/g);
	document.body.style.top = 'unset';
	if (matchesTop !== null && matchesTop.length > 0) {
		window.scrollTo(0, parseInt(matchesTop[0], 10));
	}

	await sleep(500);
};
