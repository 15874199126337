import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { Store } from 'redux';

import { baseUrl } from 'store/history';


import { changeLanguage } from 'models/i18n';
import { getLanguageLocalStorage } from 'util/storage';

import { langCodeList, LANGUAGE_NAME_MAP, LANGUAGE_OPTIONS, LangCode } from './config';


export const mapLanguageName = (lang: string) => {
	const [name = '中'] = LANGUAGE_OPTIONS.filter(item => item.value === lang).map(
		item => item.label,
	);

	return name;
};

export const mapLanguageOption = (value: LangCode) => ({
	value,
	label: LANGUAGE_NAME_MAP[value],
});

export const isLanguageSupportWithRoute = (route: string) => Object.keys(LANGUAGE_NAME_MAP).includes(route);




export const initi18next = (store: Store) => {
	const lngDetector = new LanguageDetector();

	const languageDetect = {
		name: 'urlDetect',

		lookup() {
			const normalizePathname = window.location.pathname.replace(`${baseUrl}`, '');
			const pathnames = normalizePathname.split('/');

			console.log('pathnames', pathnames);

			if (pathnames.length >= 2) {
				return pathnames[1];
			}

			return 'TW';
		},
	};

	// Setup Custom detector;
	lngDetector.addDetector(languageDetect);

	i18n
		.use(XHR)
		.use(lngDetector)
		.use(initReactI18next)
		.init(
			{
				fallbackLng: langCodeList[0],
				// whitelist: langCodeList,
				load: 'currentOnly',

				// have a common namespace used around the full app
				ns: ['common'],
				defaultNS: 'common',

				debug: process.env.NODE_ENV !== 'production',

				interpolation: {
					escapeValue: false, // not needed for react!!
				},

				detection: {
					order: ['path', 'localStorage', 'cookie', 'navigator', 'htmlTag'],
					lookupLocalStorage: getLanguageLocalStorage(),
				},

				cache: ['localStorage'],

				backend: {
					loadPath: `/static/resources/{{lng}}/{{ns}}.json`,
				},
			},
			() => {
				if (store) {
					let lang = i18n.language;
					if (!langCodeList.includes(i18n.language as LangCode)) {
						[lang] = langCodeList;

						console.warn(`${i18n.language} is not in the translation resources`);
					}
					i18n.changeLanguage(lang);
				}
			},
		);

	i18n.on('languageChanged', () => {
		if (store) {
			store.dispatch(changeLanguage(i18n.language));
		}
	});

	return i18n;
};

export const changeLng = (lng: LangCode) => {
	let lang = lng;

	if (!langCodeList.includes(lng)) {
		[lang] = langCodeList;

		console.warn(`${lng} is not in the translation resources`);
	}

	i18n.changeLanguage(lang);
};

export const getLanguage = () => i18n.language;

export const t = (keys: string, options?: string) => i18n.t(keys, options);

export const withLang = (pathname: string) => {
	const regex = /^\/+/;
	if (regex.test(pathname)) {
		return `/${i18n.language}${pathname}`;
	}

	return `${i18n.language}/${pathname}`;
};
