import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import i18n from 'i18next';

import { clearForm, updateLoginForm } from 'models/auth';
import { getEmailFromStorage } from 'models/auth/utils';

import { baseUrl } from 'store/history';
import { isExist } from 'util/helper';

const routes: CustomRoute = {
	path: '/login',
	components: () => [import('./component')],
	render: ([Login]) => <Login />,
	onEnter: async ({ store, next, history, search }) => {
		console.log('on Enter Login');

		const {
			auth: { token }
		} = store.getState();

		if (isExist(token)) {
			history.replace(`${baseUrl}/${i18n.language}${isExist(search) ? search : ''}`);
		}

		store.dispatch(clearForm('loginForm'));

		const storageEmail = getEmailFromStorage();
		if (isExist(storageEmail)) {
			store.dispatch(updateLoginForm({ key: 'email', value: storageEmail as string, error: '' }));
			store.dispatch(updateLoginForm({ key: 'remember', value: true, error: '' }));
		}

		const children = await next();

		console.log('on Enter Login / end');
		return children;
	},
};

export default routes;
