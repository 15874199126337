import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

import NewsDetailRoute from './NewsDetail';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/news',
	components: () => [],
	render: (_, children) => children,
	onEnter: async () => {},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'news' */ './component')],
			render: ([News]) => <News />,
			onEnter: async ({ next }) => {
				console.log('on Enter News');
				await next();
				console.log('on Enter News / end');
			},
		},
		NewsDetailRoute,
	],
};
export default routes;
