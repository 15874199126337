import { createAction, handleActions } from 'redux-actions';

import { useRedux } from 'util/hook/redux';
import { fixBackgroundScrollY, unfixBackgroundScrollY } from 'util/hook/useLockScroll';

import { State as GlobalState } from './reducers';

const setInitialed = createAction('SET_INITIALED');

const setInitial = createAction('SET_INITIAL');

const setBackgroundScrollY = createAction('SET_BACKGROUND_SCROLLY', () => {
	fixBackgroundScrollY();
});

const restoreBackgroundScrollY = createAction('RESTORE_BACKGROUND_SCROLLY', async () => {
	await unfixBackgroundScrollY();
});

const setNavOpen = createAction('SET_NAV_OPEN');
const setNavClose = createAction('SET_NAV_CLOSE');


export interface State {
	isInitialed: boolean;
	isBackgroundFixed: boolean;
	isNavOpen: boolean;
}

export const defaultState: State = {
	isInitialed: false,
	isBackgroundFixed: false,
	isNavOpen: false,
};

export const reducer = {
	global: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			SET_INITIALED: state => ({
				...state,
				isInitialed: true,
			}),

			SET_INITIAL: state => ({
				...state,
				isInitialed: false,
			}),

			SET_BACKGROUND_SCROLLY: state => ({
				...state,
				isBackgroundFixed: true,
			}),

			RESTORE_BACKGROUND_SCROLLY_FULFILLED: state => ({
				...state,
				isBackgroundFixed: false,
			}),

			SET_NAV_OPEN: state => ({
				...state,
				isNavOpen: true,
			}),

			SET_NAV_CLOSE: state => ({
				...state,
				isNavOpen: false,
			}),
		},
		defaultState,
	),
};

const mapHooksToState = (state: GlobalState) => state.global;

const globalActionsMap = {
	setInitialed,
	setInitial,
	setBackgroundScrollY,
	restoreBackgroundScrollY,
	setNavOpen,
	setNavClose,
};

type GlobalSelector = ReturnType<typeof mapHooksToState>;
type GlobalActionsMap = typeof globalActionsMap;

export const useGlobal = () =>
	useRedux<GlobalSelector, GlobalActionsMap>(mapHooksToState, globalActionsMap);
