import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { goToStage } from 'models/cart';

const routes: CustomRoute = {
	path: '/sponsorship-failed',
	components: () => [],
	render: (_, children) => children,
	onEnter: async () => {},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'sponsorshipFail' */ './component')],
			render: ([SponsorshipFail]) => <SponsorshipFail />,
			onEnter: async ({ next, store }) => {
				console.log('on Enter sponsorshipFail');
				await store.dispatch(goToStage(2));
				await next();
				console.log('on Enter sponsorshipFail / end');
			},
		},
	],
};

export default routes;
