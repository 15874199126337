import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import i18n from 'i18next';

import { clearForm } from 'models/auth';

import { baseUrl } from 'store/history';
import { isExist } from 'util/helper';

const routes: CustomRoute = {
	path: '/forgot-password',
	components: () => [import('./component')],
	render: ([ForgotPassword]) => <ForgotPassword />,
	onEnter: async ({ store, next, history, search }) => {
		console.log('on Enter ForgotPassword');

		const {
			auth: { token },
		} = store.getState();

		if (isExist(token)) {
			history.replace(`${baseUrl}/${i18n.language}${isExist(search) ? search : ''}`);
		}

		store.dispatch(clearForm('forgotPasswordForm'));

		const children = await next();
		console.log('on Enter ForgotPassword / end');
		return children;
	},
};

export default routes;
