import { createAction, handleActions } from 'redux-actions';
import { createContext, useContext } from 'react';
import { Dispatch } from 'redux';
import { Location, History } from 'history';
import qs from 'qs';

import { useRedux } from 'util/hook/redux';
import { isExist, removeEscapeCharacter } from 'util/helper';
import history from 'store/history';

import { GetState, State as GlobalState } from './reducers';

type Queries = {
	[key: string]: string | number | undefined;
};

export const routeChange = createAction<Location, Location>(
	'ROUTE_LOCATION_CHANGE',
	(location: Location) => ({
		...location,
		queries: {
			...qs.parse(removeEscapeCharacter(location.search), { ignoreQueryPrefix: true }),
		},
	}),
);

export interface PushRouteProperty {
	nextLang?: string;
	queries?: Queries;
	search: string;
	pathname?: string | undefined;
}

export const pushRoute = createAction(
	'PUSH_ROUTE',
	({ pathname, nextLang, search }: PushRouteProperty) =>
		(_: Dispatch, getState: GetState) => {
			const {
				routing: { pathname: statePathname },
				i18n: { lang: stateLang },
			} = getState();

			let newPathname = pathname;

			// 切換語系
			if (nextLang) {
				newPathname = statePathname.replace(`/${stateLang}`, `/${nextLang}`);
			}

			history.push(`${newPathname}${isExist(search) ? search : ''}`);
		},
);

interface RoutingQuery {
	queries: Record<string, unknown>;
}

export type State = Location & RoutingQuery;

export const defaultState: State = {
	...history.location,
	queries: {
		...qs.parse(removeEscapeCharacter(history.location.search), { ignoreQueryPrefix: true }),
	},
};

export const reducer = {
	routing: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			ROUTE_LOCATION_CHANGE: (state, action) => ({
				...state,
				...action.payload,
			}),
		},
		{
			...history.location,
			queries: {
				...qs.parse(removeEscapeCharacter(history.location.search), { ignoreQueryPrefix: true }),
			},
		},
	),
};

export const HistoryContext = createContext<History>(history);

export const useHistory = () => useContext(HistoryContext);

const mapHooksToState = (state: GlobalState) => state.routing;

export const useRouting = () => useRedux(mapHooksToState, { pushRoute });
