import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

import { getMultiMediaThemeInfo } from 'models/multiMedia';

import EpisodeRoute from './Episode';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/:themeID',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next, store, params }) => {
		const { themeID } = params;
		console.log(`on Enter MultiMedia/${themeID}`);

		await store.dispatch(getMultiMediaThemeInfo(`${themeID}`));
		const children = await next();
		console.log(`on Enter MultiMedia/${themeID} end`);

		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'theme' */ './component')],
			render: ([Theme]) => <Theme />,
		},

		EpisodeRoute,
	],
};

export default routes;
