import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/search',
	components: () => [import('./component')],
	render: ([Search]) => <Search />,
};

export default routes;
