import React from 'react';
import i18n from 'i18next';

import { CustomRoute } from 'util/hook/useRouter';

import { getOrderData } from 'models/order';

import { baseUrl } from 'store/history';
import { isExist } from 'util/helper';

import SponsorshipDetailRoute from './SponsorshipDetail';


export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/sponsorship',
	components: () => [],
	render: (_, children) => children,
	onEnter: async () => {},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'sponsorship' */ './component')],
			render: ([Sponsorship]) => <Sponsorship />,
			onEnter: async ({next, store, history, search}) => {
				console.log('on Enter Sponsorship');

				const {
					auth: { token },
				} = store.getState();

				if (!isExist(token)) {
					history.replace(`${baseUrl}/${i18n.language}${isExist(search) ? search : ''}`);
				}

				await store.dispatch(getOrderData());
				await next();
				console.log('on Enter Sponsorship / end');
			},
		},
		SponsorshipDetailRoute,
	],
};
export default routes;
