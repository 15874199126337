import React from 'react';
import classnames from 'classnames';

import history from 'store/history';

import { useTranslation } from 'react-i18next';

import { useAuth } from 'models/auth';
import { useGlobal } from 'models/global';
import { useCart } from 'models/cart';

import { useBoolean } from 'util/hook';
import { useMedia } from 'util/event';

import Search from 'components/atoms/Search';
import Icon from 'components/atoms/Icon';
import Link from 'components/atoms/Link';

import shoppingBagIcon from 'images/icon/general/shopping.inline.svg';
import userIcon from 'images/icon/general/user.inline.svg';
import userLoginIcon from 'images/icon/general/user-filled.inline.svg';
import arrowIcon from 'images/icon/general/arrow-right-with-tail.inline.svg';

import styles from './index.module.css';

const NaviFunctionButtonGroup: React.FC = () => {
	const { t } = useTranslation('nav');
	const device = useMedia();
	const [{ isLogin }, { logout }] = useAuth();
	const [{ products }] = useCart();
	const [{ isNavOpen }, { setBackgroundScrollY, restoreBackgroundScrollY, setNavClose }] =
		useGlobal();
	const [isUserMenuOpen, { toggle: toggleUserMenu }] = useBoolean({
		defaultBoolean: false,
		onTrue: setBackgroundScrollY,
		onFalse: restoreBackgroundScrollY,
	});

	const handleProfileClick = () => {
		if (isNavOpen) {
			setNavClose();
		}

		if (!isLogin) history.push('/login');
		if (isLogin && device !== 'desktop') toggleUserMenu();
	};

	return (
		<div className={styles.naviFunctionButtonGroup}>
			<Search />

			<Link className={styles.toList} to="/list">
				<Icon className={classnames(styles.icon, styles.shop)} src={shoppingBagIcon} />
				{products.length !== 0 && <div className={styles.shopNumber}>{products.length}</div>}
			</Link>

			<div className={styles.userButton}>
				<Icon
					className={classnames(styles.icon, {[styles.login]:isLogin})}
					size='normal'
					src={isLogin ? userLoginIcon : userIcon}
					onClick={handleProfileClick}
				/>
				{isLogin && (
					<div className={styles.userMenuWrapper}>
						<ul className={styles.userMenuContent}>
							<li className={classnames(styles.innerItem)}>
								<Link to="/member">
									{t('personal-info')}
									<Icon src={arrowIcon} size="x-small" />
								</Link>
							</li>
							<li className={classnames(styles.innerItem)}>
								<Link to="/sponsorship">
									{t('sponsorship')}
									<Icon src={arrowIcon} size="x-small" />
								</Link>
							</li>
							<li className={classnames(styles.innerItem)}>
								<button
									type="button"
									onClick={() => {
										logout();
									}}
								>
									{t('logout')}
								</button>
							</li>
						</ul>
					</div>
				)}
			</div>
			<div className={styles.bar} />
			<div className={classnames(styles.mobileMenuWrapper, { [styles.active]: isUserMenuOpen })}>
				{isUserMenuOpen && device !== 'desktop' && (
					<div className={styles.mobileMenu}>
						<Link className={styles.item} to="/member" onClick={toggleUserMenu}>
							{t('personal-info')}
						</Link>
						<Link className={styles.item} to="/sponsorship" onClick={toggleUserMenu}>
							{t('sponsorship')}
						</Link>
						<button
							type="button"
							className={styles.item}
							onClick={() => {
								toggleUserMenu();
								logout();
							}}
						>
							{t('logout')}
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default NaviFunctionButtonGroup;
