/* eslint-disable camelcase */

import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';

import { State as GlobalState } from './reducers';

interface ActionButton {
	text: string;
	link: string;
}

interface NewsItemProperty {
	id: number;
	title: string;
	date: string;
	category: string;
	content: string;
	weight: number;
	image: string;
}

interface WinnerItemProperty {
	subAwardId: number;
	subAwardTitle: string;
	winnerId: string;
	winnerName: string;
	winnerImage: string;
}

interface AlbumItemProperty {
	image: string;
	description: string;
}

export interface AwardTitleProperty {
	id: number;
	title: string;
}

type AwardListItemPayload = Pick<State['awardList'], 'data'>;

export const getAwardList = createAction<Promise<AwardListItemPayload>>(
	'GET_AWARD_LIST',
	async () => {
		try {
			// 是否為城南空間活動，0為否，1為城南空間no.1，2為城南空間no.2
			const { status, message, data } = await wrapFetch('president_awards/title', {
				method: 'GET',
			});

			if (status !== 200) {
				throw new Error(message);
			}

			return data;
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

export interface AwardInfoProperty extends AwardTitleProperty {
	session: number;
	year: string;
	content: string;
	image: string;
	name: string;
	actionButtons: ActionButton[];
	winners: WinnerItemProperty[];
	selectionMethod: string;
	evaluationMethod: string;
	nominationMethod: string;
	eventHighlights: NewsItemProperty[];
	album: AlbumItemProperty[];
}

type AwardInfoPayload = Pick<State['awardInfo'], 'data'>;

export const getAwardInfo = createAction<Promise<AwardInfoPayload>, number | string>(
	'GET_AWARD_INFO',
	async awardId => {
		try {
			const { status, message, data } = await wrapFetch(`president_awards/${awardId}`, {
				method: 'GET',
			});

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return data;
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

export const setAwardInfoPreview = createAction(
	'SET_AWARD_INFO_PREVIEW',
	(data: AwardInfoPayload) => ({
		...data,
	}),
);

export const clearAwardInfo = createAction('CLEAR_AWARD_INFO');

export const clearAwardList = createAction('CLEAR_AWARD_LIST');

export interface presidentAwardSubAwardProperty {
	id: number;
	title: string;
}

export interface WinnerInfoProperty {
	id?: number;
	image?: string;
	name?: string;
	subAwardTitle?: string;
	presidentAwardSubAward?: presidentAwardSubAwardProperty;
	reason?: string;
	firstReviewers?: string;
	secondaryReviewers?: string;
	finalReviewers?: string;
	intros?: string[];
}

type WinnerInfoPayload = Pick<State['winnerInfo'], 'data'>;

export const getWinnerInfo = createAction<Promise<WinnerInfoPayload>, number | string>(
	'GET_WINNER_INFO',
	async winnerId => {
		try {
			const { status, message, data } = await wrapFetch(`president_award_winners/${winnerId}`, {
				method: 'GET',
			});

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return data;
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

export const setAwardWinnerInfoPreview = createAction(
	'SET_AWARD_WINNER_INFO_PREVIEW',
	(data: WinnerInfoPayload) => ({
		...data,
	}),
);

export const clearWinnerInfo = createAction('CLEAR_WINNER_INFO');

export interface State {
	awardList: {
		loading: boolean;
		error: string;
		data: AwardTitleProperty[];
		latestSession: number;
	};
	awardInfo: {
		loading: boolean;
		error: string;
		data: AwardInfoProperty;
	};
	winnerInfo: {
		loading: boolean;
		error: string;
		data: WinnerInfoProperty;
	};
}

export const defaultState: State = {
	awardList: {
		loading: false,
		error: '',
		data: [],
		latestSession: 1,
	},
	awardInfo: {
		loading: false,
		error: '',
		data: {
			id: 1,
			title: '',
			session: 1,
			year: '2022',
			content: '',
			image: '',
			name: '',
			actionButtons: [],
			winners: [],
			selectionMethod: '',
			evaluationMethod: '',
			nominationMethod: '',
			eventHighlights: [],
			album: [],
		},
	},
	winnerInfo: {
		loading: false,
		error: '',
		data: {
			id: 1,
			image: '',
			name: '13',
			subAwardTitle: '',
			reason: '',
			firstReviewers: '',
			secondaryReviewers: '',
			finalReviewers: '',
			intros: [],
		},
	},
};

export const reducer = {
	award: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_AWARD_LIST_PENDING: state => ({
				...state,
				awardList: {
					...state.awardList,
					loading: true,
					error: '',
				},
			}),

			GET_AWARD_LIST_FULFILLED: (state, action: Action<AwardListItemPayload>) => ({
				...state,
				awardList: {
					...state.awardList,
					...action.payload,
					loading: false,
					data: [...state.awardList.data, ...action.payload.data],
					latestSession: action.payload.data[0].id,
				},
			}),

			GET_AWARD_LIST_REJECTED: (state, action) => ({
				...state,
				awardList: {
					...state.awardList,
					loading: false,
					error: action.payload.message,
				},
			}),
			CLEAR_AWARD_LIST: () => defaultState,

			GET_AWARD_INFO_PENDING: state => ({
				...state,
				awardInfo: {
					...state.awardInfo,
					loading: true,
					error: '',
				},
			}),

			GET_AWARD_INFO_FULFILLED: (state, action: Action<AwardInfoPayload>) => ({
				...state,
				awardInfo: {
					...state.awardInfo,
					...action.payload,
					loading: false,
					data: { ...action.payload.data },
				},
			}),

			GET_AWARD_INFO_REJECTED: (state, action) => ({
				...state,
				awardInfo: {
					...state.awardInfo,
					loading: false,
					error: action.payload.message,
				},
			}),

			CLEAR_AWARD_INFO: () => defaultState,

			SET_AWARD_INFO_PREVIEW: (state, action) => ({
				...state,
				awardInfo: {
					...state.awardInfo,
					data: { ...action.payload },
				},
			}),

			GET_WINNER_INFO_PENDING: state => ({
				...state,
				winnerInfo: {
					...state.winnerInfo,
					loading: true,
					error: '',
				},
			}),

			GET_WINNER_INFO_FULFILLED: (state, action: Action<AwardInfoPayload>) => ({
				...state,
				winnerInfo: {
					...state.winnerInfo,
					...action.payload,
					loading: false,
					data: { ...action.payload.data },
				},
			}),

			GET_WINNER_INFO_REJECTED: (state, action) => ({
				...state,
				winnerInfo: {
					...state.winnerInfo,
					loading: false,
					error: action.payload.message,
				},
			}),

			SET_AWARD_WINNER_INFO_PREVIEW: (state, action) => ({
				...state,
				winnerInfo: {
					...state.winnerInfo,
					data: { ...action.payload },
				},
			}),

			CLEAR_WINNER_INFO: () => defaultState,
		},
		defaultState,
	), // eslint-disable-line @typescript-eslint/no-explicit-any
};

/* +----------------------------------------------------------------------
++ useAwardList ++
++----------------------------------------------------------------------*/

const selectAwardList = (state: GlobalState) => state.award.awardList;
// for local demo
// export const useAwardList = () => useRedux(selectAwardList, {});

const awardListActionMap = { getAwardList, clearAwardList };
export const useAwardList = () => useRedux(selectAwardList, awardListActionMap);

/* +----------------------------------------------------------------------
++ useAwardInfo ++
++----------------------------------------------------------------------*/
const selectAwardInfo = (state: GlobalState) => state.award.awardInfo;
// for local demo
// export const useAwardInfo = () => useRedux(selectAwardInfo, {});

const awardInfoActionMap = { getAwardInfo, clearAwardInfo };
export const useAwardInfo = () => useRedux(selectAwardInfo, awardInfoActionMap);

/* +----------------------------------------------------------------------
++ useWinnerInfo ++
++----------------------------------------------------------------------*/

const selectWinnerInfo = (state: GlobalState) => state.award.winnerInfo;
// // for local demo
// // export const useWinnerInfo = () => useRedux(selectWinnerInfo, {});

const WinnerInfoActionMap = { getWinnerInfo, clearWinnerInfo };
export const useWinnerInfo = () => useRedux(selectWinnerInfo, WinnerInfoActionMap);
