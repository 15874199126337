/* eslint-disable camelcase */

import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';

import { State as GlobalState } from './reducers';

export interface CardItemProperty {
	id: number;
	date?: string;
	title: string;
	content?: string;
	image?: string;
	weight?: number;
	categoryID?: string;
	category?: string;
}

type NewsListItemPayload = Pick<State['newsList'], 'data' | 'currentPage' | 'lastPage' | 'category'>;

export const getNewsList = createAction<Promise<NewsListItemPayload>, number, 'ALL' | 'ADMINISTRATION' | 'MEDIA' | 'EVENT'>(
	'GET_NEWS_LIST',
	async (page, categoryName) => {
		try {
			// const { status, message, data } = await fetchNewsListFunc();

			const query = [];
			const hasCategory = categoryName.length > 0 && categoryName.toUpperCase() !== 'ALL';

			if (page) {
				query.push(`page=${page}`);
			}

			if (hasCategory) {
				query.push(`category=${categoryName}`);
			}

			const { status, message, data } = await wrapFetch(`news?${query.join('&')}`, {
				method: 'GET',
			});

			if (status !== 200) {
				throw new Error(message);
			}

			const { data: newsData } = data;

			return {
				data: newsData.map(({ category: categoryID, ...args }) => ({
					categoryID: categoryID.length > 0 ? categoryID : 'MEDIA',
					...args,
				})),
				currentPage: data.meta.currentPage,
				lastPage: data.meta.lastPage,
				category: categoryName
			};
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

export const setNewsCategory = createAction('SET_NEWS_CATEGORY', (category: string) => (
	category
));

export interface NewsInfoProperty extends CardItemProperty {
	latestThree: CardItemProperty[];
}

type NewsInfoPayload = Pick<State['newsInfo'], 'data'>;

export const getNewsInfo = createAction<Promise<NewsInfoPayload>, string>(
	'GET_NEWS_INFO',
	async newsId => {
		try {
			const { status, message, data } = await wrapFetch(`news/${newsId}`, { method: 'GET' });

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			const { data: newsInfoData } = data;

			return {
				...newsInfoData,
				image: newsInfoData.image,
				latestThree: newsInfoData.latestThree.map(({ category, ...args }) => ({
					categoryID: category.length > 0 ? category : 'MEDIA',
					...args,
				})),
				categoryID: newsInfoData.category.length > 0 ? newsInfoData.category : 'MEDIA',
			};
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

export const clearNewsList = createAction('CLEAR_NEWS_LIST');

export const setNewsPreview = createAction('SET_NEWS_PREVIEW', (data: NewsInfoPayload) => ({
	...data,
	latestThree: [],
}));


export interface State {
	newsList: {
		loading: boolean;
		error: string;
		data: CardItemProperty[];
		currentPage: number;
		lastPage: number;
		category: 'ALL' | 'ADMINISTRATION' | 'MEDIA' | 'EVENT',
	};
	newsInfo: {
		loading: boolean;
		error: string;
		data: NewsInfoProperty;
	};
}

export const defaultState: State = {
	newsList: {
		loading: false,
		error: '',
		data: [],
		currentPage: 1,
		lastPage: 1,
		category: 'ALL',
	},
	newsInfo: {
		loading: false,
		error: '',
		data: {
			id: 0,
			date: '',
			title: '',
			content: '',
			image: '',
			weight: 0,
			categoryID: '',
			latestThree: [
				{
					id: 1,
					date: '',
					title: '',
					content: '',
					image: '',
				},
				{
					id: 2,
					date: '',
					title: '',
					content: '',
					image: '',
				},
				{
					id: 3,
					date: '',
					title: '',
					content: '',
					image: '',
				},
			],
		},
	},
};

export const reducer = {
	news: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_NEWS_LIST_PENDING: state => ({
				...state,
				newsList: {
					...state.newsList,
					loading: true,
					error: '',
				},
			}),

			GET_NEWS_LIST_FULFILLED: (state, action: Action<NewsListItemPayload>) => ({
				...state,
				newsList: {
					...state.newsList,
					...action.payload,
					loading: false,
					data: [...action.payload.data],
					currentPage: action.payload.currentPage,
					lastPage: action.payload.lastPage,
					category: action.payload.category,
				},
			}),

			GET_NEWS_LIST_REJECTED: (state, action) => ({
				...state,
				newsList: {
					...state.newsList,
					loading: false,
					error: action.payload.message,
				},
			}),
			CLEAR_NEWS_LIST: () => defaultState,
			SET_NEWS_CATEGORY: (state, action) => ({
				...state,
				newsList: {
					...state.newsList,
					...action.payload,
					loading: false,
					category: action.payload.category,
				}
			}),

			GET_NEWS_INFO_PENDING: state => ({
				...state,
				newsInfo: {
					...state.newsInfo,
					loading: true,
					error: '',
				},
			}),

			GET_NEWS_INFO_FULFILLED: (state, action: Action<NewsInfoPayload>) => ({
				...state,
				newsInfo: {
					...state.newsInfo,

					loading: false,
					data: { ...action.payload },
				},
			}),

			GET_NEWS_INFO_REJECTED: (state, action) => ({
				...state,
				newsInfo: {
					...state.newsInfo,
					loading: false,
					error: action.payload.message,
				},
			}),

			SET_NEWS_PREVIEW: (state, action) => ({
				...state,
				newsInfo: {
					...state.newsInfo,
					data: { ...action.payload },
				},
			}),
		},
		defaultState,
	), // eslint-disable-line @typescript-eslint/no-explicit-any
};

/* +----------------------------------------------------------------------
++ useNewsList ++
++----------------------------------------------------------------------*/

const selectNewsList = (state: GlobalState) => state.news.newsList;
// for local demo
// export const useNewsList = () => useRedux(selectNewsList, {});

const newsListActionMap = { getNewsList, clearNewsList, setNewsCategory };
export const useNewsList = () => useRedux(selectNewsList, newsListActionMap);

/* +----------------------------------------------------------------------
++ useNewsInfo ++
++----------------------------------------------------------------------*/
const selectNewsInfo = (state: GlobalState) => state.news.newsInfo;
// for local demo
// export const useNewsInfo = () => useRedux(selectNewsInfo, {});

const newsInfoActionMap = { getNewsInfo, clearNewsList };
export const useNewsInfo = () => useRedux(selectNewsInfo, newsInfoActionMap);
