import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

import { getCampusAmbassadorList, getCampusAmbassadorEdition, getStudentAmbassadorList} from 'models/campusambassador';

const routes: CustomRoute = {
	path: '/campus-ambassador',
	components: () => [],
	render: (_, children) => children,
	onEnter: async () => {},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'news' */ './component')],
			render: ([Campusambassador]) => <Campusambassador />,
			onEnter: async ({ next, store }) => {
				await store.dispatch(getCampusAmbassadorList());
				await store.dispatch(getCampusAmbassadorEdition());
				await store.dispatch(getStudentAmbassadorList());
				
				console.log('on Enter Campusambassador');
				await next();
				console.log('on Enter Campusambassador / end');
			},
		},
	],
};
export default routes;