import React from 'react';

import { useRouting } from 'models/routing';

import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import Toast from 'components/atoms/Toast';
import SEOHelmet from 'components/atoms/SEOHelmet';

const App: React.FC = ({ children }) => {
	const [{ pathname }] = useRouting();

	const isPreview = pathname.includes('preview');

	if (isPreview) {
		// eslint-disable-next-line react/jsx-no-useless-fragment
		return <>{children}</>;
	}

	return (
		<>
			<SEOHelmet />
			<Toast />
			<Header />
			{children}
			<Footer />
		</>
	);
};

export default App;
