import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import i18n from 'i18next';

import { baseUrl } from 'store/history';
import { isExist } from 'util/helper';

const routes: CustomRoute = {
	path: '/welcome',
	components: () => [import('./component')],
	render: ([Welcome]) => <Welcome />,
	onEnter: async ({ store, next, history, search }) => {
		console.log('on Enter ResetPassword');

		const {
			auth: { token },
		} = store.getState();

		if (!isExist(token)) {
			history.replace(`${baseUrl}/${i18n.language}${isExist(search) ? search : ''}`);
		}

		const children = await next();

		console.log('on Enter ResetPassword / end');
		return children;
	},
};

export default routes;
