/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
/* eslint-disable no-mixed-operators */
import dayjs from "dayjs";
import { Moment } from 'moment';

export const getYoutubeID = (link: string | undefined) => {
	if (link === undefined) {
		return '';
	}
	const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
	const match = link.match(regExp);
	const id = match && match[7].length === 11 ? match[7] : false;
	return id;
};

export const getYoutubeLink = (link: string | undefined) =>
	`https://www.youtube.com/embed/${getYoutubeID(link)}`;

export const getYoutubeCoverImage = (link: string | undefined) =>
	`https://i.ytimg.com/vi/${getYoutubeID(link)}/sddefault.jpg`;

export const formatePublicationTag = (publishDate: string, session: number): string => {
	const date = publishDate.split('-').join('.');
	return `${date} ｜ NO.${session}`;
};

export function getParentNode<T extends HTMLElement>(node: HTMLElement): T {
	return node.parentNode as T;
}

export const editAfterLoaded = (selector: string, callback: (element: HTMLElement) => void) => {
	function queryElements(s: string, c: (element: HTMLElement) => void) {
		const elements = document.querySelectorAll(s);
		elements.forEach(item => c(item as HTMLElement));
	}
	queryElements(selector, callback);
	const observer = new MutationObserver(() => {
		queryElements(selector, callback);
	});
	observer.observe(document.documentElement, {
		attributes: true,
		childList: true,
		characterData: true,
		subtree: true,
	});
};


export const lazyExecute = <I, O>(callBack: (i?: I) => O) => {
	if (isExist(callBack)) {
		setTimeout(() => {
			callBack();
		}, 100)
	}
};

// eslint-disable-next-line no-useless-escape
export const removeEscapeCharacter = (str: string): string => str.replace(/\\=/g, '=');

export const focusInChildren: (
	relatedTarget: EventTarget,
	currentTarget: EventTarget,
	// eslint-disable-next-line @typescript-eslint/ban-types
) => {} | boolean = (relatedTarget: EventTarget, currentTarget: EventTarget) => {
	if (relatedTarget === null) {
		return false;
	}

	if (relatedTarget === currentTarget) {
		return true;
	}

	const targetParent = getParentNode(relatedTarget as HTMLElement);

	return focusInChildren(targetParent, currentTarget);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isExist = (value: any) =>
	value !== null && value !== '' && typeof value !== 'undefined';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEmpty = (value: any) => !isExist(value);

/**
 * 如果值是 null， 返回 undefined
 */
export const nullToUndefined = <T>(value: T): NonNullable<T> | undefined =>
	value === null ? undefined : (value as NonNullable<T>);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const objectHasValue = (obj: any) => {
	const values = Object.values(obj);
	for (let i = 0; i < values.length; i += 1) {
		if (isExist(values[i])) return true;
	}
	return false;
};

export const range = (start: number, length: number) =>
	Array.from(new Array(Math.abs(length)), (_, i) => start + i * (length >= 0 ? 1 : -1));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const chunkArray = <T>(array: T[], chunkSize: number) => {
	const results = [];
	const newArray = [...array];

	while (newArray.length) {
		results.push(newArray.splice(0, chunkSize));
	}

	return results;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const suppleArray = (array: any[], toLength: number) => {
	const newArray = [...array];
	const diffLength = toLength - newArray.length;
	const restSuppleArray = Array.from({ length: diffLength }, () => ({}));
	return [...newArray, ...restSuppleArray];
};

export const thousandComma = (oldNum: number) => {
	let newNum: string = oldNum.toString();
	const pattern = /(-?\d+)(\d{3})/;

	while (pattern.test(newNum)) {
		newNum = newNum.replace(pattern, '$1,$2');
	}
	return newNum;
};

export const sleep = (time: number) =>
	new Promise<void>(resolve => setTimeout(() => resolve(), time));

export const pad = (number = 0, width = 1, z = '0') => {
	const numberLength = `${number}`.length;
	return numberLength >= width ? `${number}` : new Array(width - numberLength + 1).join(z) + number;
};

export const getElementPosition = (element: HTMLElement) => {
	let target: HTMLElement | null = element;
	const pos = { x: target.offsetLeft, y: target.offsetTop };
	target = target.offsetParent as HTMLElement;
	while (target) {
		pos.x += target.offsetLeft;
		pos.y += target.offsetTop;
		target = target.offsetParent as HTMLElement;
	}
	return pos;
};

export const removeHTMLTags = (str: string) => {
	if (str === null || str === '') {
		return '';
	}

	// Regular expression to identify HTML tags in
	// the input string. Replacing the identified
	// HTML tag with a null string.;
	return str.toString().replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/gi, '');
};

export const replaceChar = (myString: string, index: number, replacement: string) =>
	myString.substring(0, index) + replacement + myString.substring(index + replacement.length);

export const mobileAndTabletCheck = () => {
	let check = false;
	(a => {
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
				a,
			) ||
			// eslint-disable-next-line no-useless-escape
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				a.substr(0, 4),
			)
		)
			check = true;
	})(navigator.userAgent || navigator.vendor || window.opera);
	return check;
};

export const groupBy = <T>(key: keyof T) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(data: any, item: T) => {
		// eslint-disable-next-line no-param-reassign
		(data[item[key]] = data[item[key]] || []).push(item);
		return data;
	}
	;

export const assignBy = <T>(key: keyof T) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(data: any, item: T) => {
		// eslint-disable-next-line no-param-reassign
		data[item[key]] = item;
		return data;
	}
	;


export const reduceArr = (
	arr: any[], // eslint-disable-line @typescript-eslint/no-explicit-any
	key: string,
) =>
	arr.reduce((acc, val) => {
		const found = acc.find((a: { [x: string]: any }) => a[key] === val[key]); // eslint-disable-line @typescript-eslint/no-explicit-any
		if (!found) {
			acc.push({ [key]: val[key], data: [val] });
		} else {
			found.data.push(val);
		}
		return acc;
	}, []);


export const isNumber = (value: unknown) => !Number.isNaN(Number(value));

export const validatePhone = (value: string) => {
	const phoneRegex = /^(09|9)[0-9]{8}$/;
	return phoneRegex.test(value);
}

export const validateEmail = (value: string) => {
	const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
	return emailRegex.test(value);
};

export const validateDate = (date: string): boolean => dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') === date;

export const getNextFewYears = (number: number): string[] => {
	const currentYear = new Date().getFullYear();
	const arr = [];
	for (let i = 0; i < number; i++) {
		arr.push((currentYear + i).toString())
	}
	return arr;
}

/**
 * 密碼驗證
 * 
 * 規則：密碼長度至少 6 碼，須包含英文大寫字母、小寫字母、數字，可接受符號
 */
export const validatePassword = (value: string) => {
	const pwRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d._%+-/&/^/*/@/!/#/$]{6,}$/;
	return pwRegex.test(value);
}

export const visaIdFormatter = (id: string) => {
	const formatId = id
		.replace(/[^\d]/g, '')
		.replace(/(\s)/g, '')
		.replace(/(\d{4})/g, '$1 ')
		.replace(/\s*$/, '');
	return formatId;
};

export const handleInvalidInput = (
	key: string,
	changeFrom: (params: any) => void,
	errorMsg: string,
) => {
	changeFrom({
		key,
		data: {
			valid: false,
			error: errorMsg,
		},
	});
};


/**
 * 將信用卡卡號 string 去識別化，index < 15 的數值都回傳 '*'。
 * e.g. **** **** **** 5566
 *
 * @param {string} cardId 去識別化後的信用卡號 string
 */
export const creditCardDeIdentificator = (cardId: string) =>
	cardId.replace(/\w/g, (match, offset) => {
		if (offset === 15 || offset === 16 || offset === 17 || offset === 18) {
			return match;
		}
		return '*';
	});

export const createRandomNumberPostfix = (v: string | number) => `${v}_${`${Math.random()}`.split('.')[1]}`;

/**
 * 將後端回傳的 Error Message 轉換成前端統一的 ABC_DEF_GHI 作為 i18n 翻譯檔的 KEY。
 *
 * @param msg 後端回傳的 Error Message
 * @param splitter
 * @returns 翻譯檔錯誤訊息的 KEY
 */
export const createErrorMsgKey = (msg: string, splitter = ' ') =>
	msg
		.split(splitter)
		.join('_')
		.toLocaleUpperCase();


export const getTimeRange = (start: Moment, end: Moment) => {
	const diff = {
		days: end.diff(start, 'days'),
		hours: end.diff(start, 'hours') % 24,
		mins: end.diff(start, 'minutes') % 60,
		secs: end.diff(start, 'seconds') % 60,
	};
	return diff;
};

export const getTimeDifference = (startTime: string, duration: number): string => {
	const start = new Date(startTime); // 將起始時間轉換成 Date 物件
  const now = new Date(); // 取得當下時間
  const diff = Math.floor((now.getTime() - start.getTime()) / 1000); // 計算與起始時間相差秒數

  const remaining = duration - diff; // 計算還剩下多少秒
  const minutes = Math.floor(remaining / 60).toString().padStart(2, '0'); // 計算還剩下多少分鐘
  const seconds = (remaining % 60).toString().padStart(2, '0'); // 計算還剩下多少秒

	if (parseInt(minutes, 10) <= 0 && parseInt(seconds, 10) <= 0) {
		return '00:00';
	}

  return `${minutes}:${seconds}`; // 回傳時間字串
}

export const chineseNumber = (alaberNumber: string) => {
	const chineseNumberArr = ("零一二三四五六七八九").split('');
	const amountSmallUnit = ['', '十', '百', '千'];
	const amountBigUnit = ['', '萬', '億', '兆', '京', '垓', '秭', '穰', '溝', '澗', '正', '載'];
	const alaberNumberSplit = [];
	const alaberNumberSplitCount = alaberNumber.length / 4;
	
	for (let i = 0; i <= alaberNumberSplitCount; i++) {
			if (i === 0 && alaberNumber.length % 4 !== 0) {
					alaberNumberSplit[i] = alaberNumber.substr(0, alaberNumber.length % 4);
					alaberNumber = alaberNumber.slice(alaberNumber.length % 4);
			} else if (alaberNumber !== '') {
					alaberNumberSplit[i] = alaberNumber.substr(0, 4);
					alaberNumber = alaberNumber.slice(4);
			}
	}
	let chineseBigNumber = '';
	
	for (let i = 0; i < alaberNumberSplit.length; i++) {
			for (let j = 0; j < alaberNumberSplit[i].length; j++) {
					if (parseInt(alaberNumberSplit[i][0], 10) !== 0 && j === 1 
							&& parseInt(alaberNumberSplit[i][j], 10) === 0 
							&& alaberNumberSplit[i].length === 4 
							&& parseInt(alaberNumberSplit[i][2], 10) !== 0) {
							chineseBigNumber += chineseNumberArr[parseInt(alaberNumberSplit[i][j], 10)];
					} else if (parseInt(alaberNumberSplit[i][j], 10) !== 0) {
							chineseBigNumber += chineseNumberArr[parseInt(alaberNumberSplit[i][j], 10)];
							chineseBigNumber += amountSmallUnit[alaberNumberSplit[i].length - 1 - j];
					} else if (parseInt(alaberNumberSplit[i][j], 10) === 0 && parseInt(alaberNumberSplit[i][j - 1], 10) !== 0) {
							if (parseInt(alaberNumberSplit[i][alaberNumberSplit[i].length - 1], 10) !== 0) {
									chineseBigNumber += chineseNumberArr[parseInt(alaberNumberSplit[i][j], 10)];
							}
					}
			}
			if (parseInt(alaberNumberSplit[i], 10) !== 0) {
					chineseBigNumber += amountBigUnit[alaberNumberSplit.length - 1 - i];
			}
	}

	return chineseBigNumber;
};
