import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { goToStage } from 'models/cart';

const routes: CustomRoute = {
	path: '/list',
	components: () => [],
	render: (_, children) => children,
	onEnter: async () => {},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'sponsorList' */ './component')],
			render: ([SponsorList]) => <SponsorList />,
			onEnter: async ({ next, store }) => {
				console.log('on Enter sponsorList');
				await store.dispatch(goToStage(1));
				await next();
				console.log('on Enter sponsorList / end');
			},
		},
	],
};

export default routes;
