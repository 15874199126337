import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

import { getEventInfo, setEventInfoSubEventId } from 'models/event';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/:eventID',
	components: () => [],
	render: (_, children) => children,
	onEnter: async () => {},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'eventDetail' */ './component')],
			render: ([EventDetail]) => <EventDetail />,
			onEnter: async ({ next, store, params }) => {
				const { eventID } = params;
				console.log(`on Enter Event/${eventID}`);

				await store.dispatch(getEventInfo(`${eventID}`));
				// 先清空
				await store.dispatch(setEventInfoSubEventId(''));

				await next();
				console.log(`on Enter Event/${eventID} end`);
			},
		},
		{
			path: '/:subEventID',
			// 同樣使用 EventDetail，但是僅呈現子活動
			components: () => [import(/* webpackChunkName: 'EventDetail' */ './component')],
			render: ([EventDetail]) => <EventDetail />,
			onEnter: async ({ next, store, params }) => {
				const { eventID, subEventID } = params;
				console.log(`on Enter Event/${eventID}/${subEventID}`);
		
				await store.dispatch(getEventInfo(`${eventID}`));
		
				await store.dispatch(setEventInfoSubEventId(`${subEventID}`));
		
				await next();
				console.log(`on Enter Event/${eventID}/${subEventID} end`);
			},
		},
	],
};

export default routes;
