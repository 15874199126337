import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

import EventDetailRoute from './EventDetail';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/events',
	components: () => [],
	render: (_, children) => children,
	onEnter: async () => {},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'event' */ './component')],
			render: ([Event]) => <Event />,
			onEnter: async ({ next }) => {
				console.log('on Enter Event');
				await next();
				console.log('on Enter Event / end');
			},
		},
		EventDetailRoute,
	],
};
export default routes;
