import React, { SyntheticEvent, CSSProperties } from 'react';
import classnames from 'classnames';

import { isExist } from 'util/helper';

import styles from './index.module.css';

// large: 32, normal: 24, small: 20, x-small: 16, xx-small
export type IconSize = 'normal' | 'small' | 'large' | 'x-small' | 'xx-small' | 'inherit';

interface IconProperty {
	className?: string;
	badgeClassName?: string;
	src: string;
	size?: IconSize;
	style?: CSSProperties;
	badge?: string;
	onClick?: (event: SyntheticEvent) => void;
	onMouseEnter?: (event: SyntheticEvent) => void;
	onMouseLeave?: (event: SyntheticEvent) => void;
}

const Icon: React.FC<IconProperty> = ({
	className,
	badgeClassName,
	src: Svg,
	size = 'normal',
	badge,
	style,
	onClick,
	onMouseEnter = () => {},
	onMouseLeave = () => {},
}) => (
	<div
		className={classnames(styles.icon, isExist(size) && styles[size], {
			[styles.clickable]: isExist(onClick),
		}, className)}
		style={style}
		role="button"
		tabIndex={0}
		onClick={onClick}
		onKeyPress={() => {}}
		onMouseEnter={onMouseEnter}
		onMouseLeave={onMouseLeave}
	>
		{badge && <div className={classnames(styles.badge, badgeClassName)}>{badge}</div>}
		<Svg />
	</div>
);

export default Icon;
