import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/preview',
	components: () => [import(/* webpackChunkName: 'preview' */ './component')],
	render: ([Preview]) => <Preview />,
	onEnter: async () => {
		console.log('on Enter preview');
		console.log('on Enter preview / end');
	},
};

export default routes;
