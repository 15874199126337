/* eslint-disable camelcase */
import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';

import { State as GlobalState } from './reducers';

export interface HomeBannerItemProperty {
	id: number;
	name: string;
	title: string;
	subTitle?: string;
	type: 'IMAGE' | 'YOUTUBE';
	image?: string;
	link?: string;
	youtubeLink?: string;
}

type HomeBannerListItemPayload = Pick<State['homeBannerList'], 'data'>;

export const getHomeBannerList = createAction<Promise<HomeBannerListItemPayload>>('GET_HOME_BANNER_LIST', async () => {

	try {
		const { status, message, data } = await wrapFetch(
			'banners',
			{
				method: 'GET',
			},
		);

		if (status !== 200) {
			throw new Error(message);
		}

		const { data: homeBannerData } = data;

		const tmpData = homeBannerData.length < 5 ? homeBannerData : homeBannerData.slice(0, 5);

		return {
			data: tmpData,
		};
	} catch (error) {
		throw new Error((error as Error).message);
	}
});

export const clearHomeBannerList = createAction('CLEAR_HOME_BANNER_LIST');

export interface State {
	homeBannerList: {
		loading: boolean;
		error: string;
		data: HomeBannerItemProperty[];
	};
}

export const defaultState: State = {
	homeBannerList: {
		loading: false,
		error: '',
		data: [],
	},
};

export const reducer = {
	homeBanner: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_HOME_BANNER_LIST_PENDING: state => ({
				...state,
				homeBannerList: {
					...state.homeBannerList,
					loading: true,
					error: '',
				},
			}),

			GET_HOME_BANNER_LIST_FULFILLED: (state, action: Action<HomeBannerListItemPayload>) => ({
				...state,
				homeBannerList: {
					...state.homeBannerList,
					...action.payload,
					loading: false,
					data: [...state.homeBannerList.data, ...action.payload.data],
				},
			}),

			GET_HOME_BANNER_LIST_REJECTED: (state, action) => ({
				...state,
				homeBannerList: {
					...state.homeBannerList,
					loading: false,
					error: action.payload.message,
				},
			}),
			CLEAR_HOME_BANNER_LIST: () => defaultState,

		},
		defaultState,
	), // eslint-disable-line @typescript-eslint/no-explicit-any
};

/* +----------------------------------------------------------------------
++ useHomeBannerList ++
++----------------------------------------------------------------------*/

const selectHomeBannerList = (state: GlobalState) => state.homeBanner.homeBannerList;
// for local demo
// export const useHomeBannerList = () => useRedux(selectHomeBannerList, {});



const homeBannerListActionMap = { getHomeBannerList, clearHomeBannerList };
export const useHomeBannerList = () => useRedux(selectHomeBannerList, homeBannerListActionMap);
