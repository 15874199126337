/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createAction, handleActions, Action } from 'redux-actions';
import { createSelector } from 'reselect';
import { Dispatch } from 'redux';

import history from 'store/history';
import { clearCartData } from 'models/cart';
import { useRedux } from 'util/hook/redux';
import storage from 'util/storage';
import { wrapFetch } from 'util/api';
import { isExist } from 'util/helper';

import { openModal, TOAST_TYPE, MODAL_CATEGORY } from '../modal';
import { GetState, State as GlobalState } from '../reducers';

import {
	FormItemPayload,
	SignUpFormItemPayload,
	LOGIN_TYPE,
	LoginProcessProperty,
	LoginFormProperty,
	SignUpFormProperty,
	ResetPasswordProperty,
	ForgotPasswordFormProperty,
	ResetPasswordFormProperty,
} from './type';

import {
	defaultForgotPasswordForm,
	defaultLoginForm,
	defaultResetPasswordForm,
	defaultSignUpForm,
	setEmailToStorage,
	loginFacebook,
	loginGoogle,
	bindGoogle,
	unbindGoogle,
	bindFacebook,
	unbindFacebook,
} from './utils';

export const loginProcess = createAction(
	'LOGIN_PROGRESS',
	({ type, IDtoken }: LoginProcessProperty) =>
		async (dispatch: Dispatch, getState: GetState): Promise<void> => {
			switch (type) {
				case LOGIN_TYPE.GOOGLE:
					await dispatch(loginGoogle(IDtoken as string));
					break;
				case LOGIN_TYPE.FACEBOOK:
					await dispatch(loginFacebook());
					break;
				default:
					await dispatch(login());
					break;
			}

			const {
				auth: { token },
			} = getState();

			if (token) {
				if (window.history.length <= 2) {
					history.replace('/')
					return;
				}
				history.back();
			}
		},
);

/**
 * 一般登入
 */
export const login = createAction(
	'LOGIN',
	() =>
		async (dispatch: Dispatch, getState: GetState): Promise<null> => {
			const {
				auth: {
					loginForm: {
						email,
						password,
						remember,
					}
				},
			} = getState();

			const { data, status, message } = await wrapFetch('login', {
				method: 'POST',
				body: JSON.stringify({ email: email.value, password: password.value }),
			});

			if (status !== 200 || status !== 201) {
				if (message === '電子信箱非文總之友，請完成註冊程序') {
					await dispatch(updateLoginForm({ key: 'email', value: email.value, error: '電子信箱非文總之友，請完成註冊程序' }));
					return null;
				}
				if (message === '文總之友權限已被關閉，若需重新啟用請洽詢文總') {
					await dispatch(updateLoginForm({ key: 'email', value: email.value, error: '文總之友權限已被關閉，若需重新啟用請洽詢文總' }));
					return null;
				}
				if (message === '帳號或密碼輸入錯誤') {
					await dispatch(updateLoginForm({ key: 'email', value: email.value, error: '帳號或密碼輸入錯誤，請重新確認' }));
					await dispatch(updateLoginForm({ key: 'password', value: password.value, error: '帳號或密碼輸入錯誤，請重新確認' }));
					return null;
				}
			}

			if (remember.value) {
				setEmailToStorage(email.value);
			} else {
				setEmailToStorage('');
			}

			dispatch(updateAccessToken(data.data.token));
			dispatch(
				openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.SUCCESS, data: '登入成功' }),
			);
			return null;
		},
);

/**
 * 一般註冊
 */
export const signUp = createAction(
	'SIGN_UP',
	() =>	async (dispatch: Dispatch, getState: GetState): Promise<null> => {
		const {
			auth: {
				signUpForm,
			}
		} = getState();

		const formData = {
			name: signUpForm.name.value,
			email: signUpForm.email.value,
			password: signUpForm.password.value,
			password_confirmation: signUpForm.passwordConfirmation.value,
			gender: signUpForm.gender.value,
			birthday:
				`${signUpForm.birthday.year}-${signUpForm.birthday.month}-${signUpForm.birthday.day}` ===
				'--'
					? ''
					: `${signUpForm.birthday.year}-${signUpForm.birthday.month}-${signUpForm.birthday.day}`,
			dialing_code: signUpForm.dialCode.value.replace('+', ''),
			phone: signUpForm.phone.value,
			tel: signUpForm.tel.value,
			county: signUpForm.county.value,
			district: signUpForm.district.value,
			address: signUpForm.address.value,
		};

		const { status, message, data, extra } = await wrapFetch('register', {
			method: 'POST',
			body: JSON.stringify(formData),
		});

		console.log('response: ', message, data, extra);

		if (isExist(extra?.email[0])) {
			dispatch(
				openModal({
					category: MODAL_CATEGORY.TOAST,
					type: TOAST_TYPE.WARNING,
					data: extra.email[0],
				}),
			);
			return null;
		}

		if (status !== 200 && status !== 201) {
			dispatch(
				openModal({
					category: MODAL_CATEGORY.TOAST,
					type: TOAST_TYPE.WARNING,
					data: message,
				}),
			);
			return null;
		}

		await dispatch(updateAccessToken(data.data.token));
		dispatch(
			openModal({
				category: MODAL_CATEGORY.TOAST,
				type: TOAST_TYPE.SUCCESS,
				data: '謝謝您的註冊，註冊驗證信已發送到您的信箱，請點擊信中連結完成註冊',
			}),
		);

		const {
			auth: { token },
		} = getState();

		if (token) {
			history.replace('/welcome');
		}

		return null;
	},
);

/**
 * 寄送忘記密碼信
 */
export const sendResetPasswordEmail = createAction(
	'SEND_RESET_PASSWORD_EMAIL',
	(email: string) =>
		async (dispatch: Dispatch): Promise<null> => {
			const { status, message, data } = await wrapFetch('send-reset-password-email', {
				method: 'POST',
				body: JSON.stringify({ email }),
			});

			console.log('response: ', status, message, data);

			if (status !== 200 && status !== 201) {
				dispatch(
					openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.WARNING, data: message }),
				);
				return null;
			}

			dispatch(
				openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.SUCCESS, data: data.data }),
			);
			return null;
		},
);

/**
 * 重設密碼
 */
export const resetPassword = createAction(
	'RESET_PASSWORD',
	({ token, email }: ResetPasswordProperty) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			auth: {
				resetPasswordForm: {
					newPassword,
					confirmPassword,
				}
			},
		} = getState();

		const { status, message, data } = await wrapFetch('reset-password', {
			method: 'POST',
			body: JSON.stringify({
				token,
				email,
				password: newPassword.value,
				password_confirmation: confirmPassword.value,
			}),
		});

		console.log('response: ', status, message, data);

		if (status !== 200 && status !== 201) {
			dispatch(
				openModal({
					category: MODAL_CATEGORY.TOAST,
					type: TOAST_TYPE.WARNING,
					data: message,
				}),
			);
			return null;
		}

		dispatch(
			openModal({
				category: MODAL_CATEGORY.TOAST,
				type: TOAST_TYPE.SUCCESS,
				data: '密碼重新設定成功',
			}),
		);
		storage.removeToken();
		dispatch(updateAccessToken(''));

		history.replace('/login');
		return null;
	},
);

export const updateAccessToken = createAction('UPDATE_ACCESS_TOKEN', (newToken: string) => {
	storage.setToken(newToken);
	return newToken;
});

export const loadTokenFromLocalstorage = createAction('LOAD_TOKEN_FROM_LOCALSTORAGE', () => {
	const token = storage.getToken();
	return token;
});

export const logout = createAction('LOGOUT', () => async (dispatch: Dispatch) => {
	storage.removeToken();
	dispatch(updateAccessToken(''));
	dispatch(clearCartData());
	history.push('/');
	dispatch(
		openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.SUCCESS, data: '登出成功' }),
	);
	return null;
});

export const clearForm = createAction('CLEAR_FORM', (type: string) => {
	let clearData;

	switch (type) {
		case 'loginForm':
			clearData = defaultLoginForm;
			break;
		case 'signUpForm':
			clearData = defaultSignUpForm;
			break;
		case 'forgotPasswordForm':
			clearData = defaultForgotPasswordForm;
			break;
		case 'resetPasswordForm':
			clearData = defaultResetPasswordForm;
			break;
		default:
			break;
	}

	return {
		type,
		data: clearData,
	};
});

export const updateLoginForm = createAction('UPDATE_LOGIN_FORM',
	({ key, value, error }: FormItemPayload) => ({
		key,
		value,
		error,
	})
);

export const updateSignUpForm = createAction(
	'UPDATE_SIGN_UP_FORM',
	({ key, data, error }: SignUpFormItemPayload) => ({
		key,
		data,
		error,
	}),
);

export const updateForgotPasswordForm = createAction(
	'UPDATE_FORGOT_PASSWORD_FORM',
	({ key, value, error }: FormItemPayload) => ({
		key,
		value,
		error,
	}),
);

export const updateResetPasswordForm = createAction(
	'UPDATE_RESET_PASSWORD_FORM',
	({ key, value, error }: FormItemPayload) => ({
		key,
		value,
		error,
	}),
);

export interface State {
	loading: boolean;
	token: string | null;
	loginForm: LoginFormProperty;
	signUpForm: SignUpFormProperty;
	forgotPasswordForm: ForgotPasswordFormProperty;
	resetPasswordForm: ResetPasswordFormProperty;
}

export const defaultState: State = {
	loading: false,
	token: null,
	loginForm: defaultLoginForm,
	signUpForm: defaultSignUpForm,
	forgotPasswordForm: defaultForgotPasswordForm,
	resetPasswordForm: defaultResetPasswordForm,
};

export const reducer = {
	auth: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			LOGIN_PENDING: state => ({
				...state,
				loading: true,
			}),

			LOGIN_FULFILLED: state => ({
				...state,
				loading: false,
			}),

			LOAD_TOKEN_FROM_LOCALSTORAGE: (state, action: Action<string>) => ({
				...state,
				token: action.payload,
			}),

			LOGOUT: state => ({
				...state,
				token: null,
			}),

			UPDATE_ACCESS_TOKEN: (state, action: Action<string>) => ({
				...state,
				token: action.payload,
			}),

			CLEAR_FORM: (state, action) => ({
				...state,

				[action.payload.type]: action.payload.data,
			}),

			UPDATE_LOGIN_FORM: (state, action: Action<FormItemPayload>) => ({
				...state,
				error: '',
				loginForm: {
					...state.loginForm,
					[action.payload.key]: {
						...state.loginForm[action.payload.key],
						value: action.payload.value as string | boolean,
						error: action.payload.error as string,
					},
				},
			}),

			UPDATE_SIGN_UP_FORM: (state, action: Action<SignUpFormItemPayload>) => ({
				...state,
				error: '',
				signUpForm: {
					...state.signUpForm,
					[action.payload.key]: {
						...state.signUpForm[action.payload.key],
						...action.payload.data,
						error: action.payload.error as string,
					},
				},
			}),

			UPDATE_FORGOT_PASSWORD_FORM: (state, action: Action<FormItemPayload>) => ({
				...state,
				error: '',
				forgotPasswordForm: {
					...state.forgotPasswordForm,
					[action.payload.key]: {
						...state.forgotPasswordForm[action.payload.key],
						value: action.payload.value as string,
						error: action.payload.error as string,
					},
				},
			}),

			UPDATE_RESET_PASSWORD_FORM: (state, action: Action<FormItemPayload>) => ({
				...state,
				error: '',
				resetPasswordForm: {
					...state.resetPasswordForm,
					[action.payload.key]: {
						...state.resetPasswordForm[action.payload.key],
						value: action.payload.value as string,
						error: action.payload.error as string,
					},
				},
			}),
		},
		defaultState,
	),
};

const selectAuth = createSelector(
	(state: GlobalState) => state.auth.token,
	(state: GlobalState) => state.auth.loginForm,
	(state: GlobalState) => state.auth.signUpForm,
	(state: GlobalState) => state.auth.forgotPasswordForm,
	(state: GlobalState) => state.auth.resetPasswordForm,
	(token, loginForm, signUpForm, forgotPasswordForm, resetPasswordForm) => ({
		token,
		isLogin: isExist(token),
		loginForm,
		signUpForm,
		forgotPasswordForm,
		resetPasswordForm,
	}),
);

const authActionsMap = {
	loginProcess,
	signUp,
	logout,
	resetPassword,
	clearForm,
	updateLoginForm,
	updateSignUpForm,
	updateForgotPasswordForm,
	updateResetPasswordForm,
	sendResetPasswordEmail,
	bindGoogle,
	unbindGoogle,
	bindFacebook,
	unbindFacebook,
};

export const useAuth = () => useRedux(selectAuth, authActionsMap);
