import React from 'react';
import qs from 'qs';

import { CustomRoute } from 'util/hook/useRouter';
import { goToStage } from 'models/cart';
import { isExist } from 'util/helper';
import storage from 'util/storage';

const routes: CustomRoute = {
	path: '/sponsorship-success',
	components: () => [],
	render: (_, children) => children,
	onEnter: async () => {},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'sponsorshipSuccess' */ './component')],
			render: ([SponsorshipSuccess]) => <SponsorshipSuccess />,
			onEnter: async ({ next, store, history }) => {
				console.log('on Enter sponsorshipSuccess');
				await store.dispatch(goToStage(3));

				const orderNo = qs.parse(history.location.search, { ignoreQueryPrefix: true }).no;

				if (isExist(orderNo)) {
					storage.removeItem(`order-${orderNo as string}`);
				}

				await next();
				console.log('on Enter sponsorshipSuccess / end');
			},
		},
	],
};

export default routes;
