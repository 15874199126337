import { useState } from 'react';

type useNaviFunc = (data?: unknown) => {
	menuType: string;
	setMenuType: (target: string) => void;
};

const useNavi: useNaviFunc = () => {
	const [menuType, setMenuType] = useState("nav");

	return {
		menuType,
		setMenuType,
	};
};

export default useNavi;
