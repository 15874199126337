export type LangCode = 'EN' | 'TW' | 'JP';
export type LangText = 'EN' | '中' | '日';

export const langCodeList: LangCode[] = ['TW', 'EN', 'JP'];

export const LANGUAGE_NAME_MAP: Record<LangCode, LangText> = {
	'TW': '中',
	'EN': 'EN',
	'JP': '日',
};

export const LANGUAGE_OPTIONS: { label: string; value: string; }[] = [
	{
		label: '中',
		value: 'TW',
	},
	{
		label: 'EN',
		value: 'EN',
	},
	{
		label: '日',
		value: 'JP',
	},
];


