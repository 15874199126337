import { createAction, handleActions, Action } from 'redux-actions';
import { createSelector } from 'reselect';
import { Dispatch } from 'redux';
import { useRedux } from 'util/hook/redux';

import storage from 'util/storage';
import history from 'store/history';
import { isExist } from 'util/helper';
import { wrapAuthFetch } from 'util/api';

import { FormItemPayload } from './auth/type';
import { ProductInfo, goToStage, deleteCartProductProcess } from './cart';
import { GetState, State as GlobalState } from './reducers';

export interface OrderInfo {
	orderTime: string;
	status: string;
	totalPrice: number;
	sponsorshipPlans: SponsorshipPlanInfo[];
	addressee: string;
	dialingCode: number; // 從後端來的時候是數字，送出去是文字
	phone: string;
	county: string;
	district: string;
	address: string;
	sponsorRemark: string;
	deadline?: string;
}

export interface OrderItemProperty {
	no: string;
	orderTime: string;
	status: string;
	totalPrice: number;
	sponsorshipPlans: SponsorshipPlanInfo[];
}

export interface SponsorshipPlanInfo {
	id: number;
	name: string;
	mainImage: string;
	category: string;
	magazineSubscribeBeginSession: number;
	magazineBackIssuesSession: number[];
	spec: string;
	amount: number;
	subTotalPrice: number;
}

export interface OrderFormProperty {
	addressee: { value: string, error: string };
	address: { value: string, error: string };
	phone: { value: string, error: string };
	dialCode: { value: string, error: string };
	county: { value: string, error: string };
	district: { value: string, error: string };
	sponsorRemark: { value: string, error: string };
	cardNumber: { value: string, error: string };
	expirationMonth: { value: string, error: string };
	expirationYear: { value: string, error: string };
	cvc: { value: string, error: string };
}

export interface OrderShipProperty {
	addressee: string;
	address: string;
	county: string;
	dialCode: string;
	district: string;
	phone: string;
}

export interface OrderResultProperty {
	orderNo: string;
	html3DSecure: string;
}

export const getOrderData = createAction<Promise<OrderItemProperty>>(
	'GET_ORDER_DATA',
	async () => {
		const { data, status } = await wrapAuthFetch('orders', {
			method: 'GET',
		});

		if (status !== 200 && status !== 201) {
			return defaultState.orders;
		}

		return data.data;
	},
);

export const getOrderInfo = createAction<Promise<OrderInfo>, string>(
	'GET_ORDER_INFO',
	async no => {
		const { data, status } = await wrapAuthFetch(`orders/${no}`, {
			method: 'GET',
		});

		if (status !== 200 && status !== 201) {
			return defaultState.orderInfo;
		}

		return data.data;
	}
)

export const createOrder = createAction<(dispatch: Dispatch, getState: GetState) => Promise<OrderResultProperty>>(
	'CREATE_ORDER',
() => async (dispatch, getState) => {
	const { canSubmit } = selectOrder(getState());	

	if (!canSubmit) {
		console.warn("Can't submit!");
		return defaultState.orderResult;
	}

	const {
		cart: { singleProduct, products },
		order: { orderForm },
	} = getState();

	const isSingleProductCheckout = singleProduct !== null
	const currentCheckoutProducts = isSingleProductCheckout ? singleProduct : products;

	const sponsorshipPlans = (currentCheckoutProducts).map((product: ProductInfo) => ({
		id: product.sponsorshipPlanId,
		quantity: product.quantity,
		spec_id: product.spec.specId,
		fountain_extra_info: {
			subscribe_begin_id: product.subscribeBeginIssue.id,
			back_issues: product.backIssues.id,
			gift_id: product.spec.giftId,
		},
	}));

	const { status, message, data, extra } = await wrapAuthFetch(
		'orders',
		{
			method: 'POST',
			body: JSON.stringify({
				addressee: orderForm.addressee.value,
				county: orderForm.county.value,
				district: orderForm.district.value,
				address: orderForm.address.value,
				dialing_code: orderForm.dialCode.value.slice(1),
				phone: orderForm.phone.value,
				sponsor_remark: orderForm.sponsorRemark.value,
				cardNumber: orderForm.cardNumber.value,
				expirationDate: orderForm.expirationYear.value.slice(-2) + orderForm.expirationMonth.value,
				cvc: orderForm.cvc.value,
				sponsorship_plans: sponsorshipPlans,
			}),
		},
	);

	if (
		message === 'The given data was invalid.' &&
		extra.cardNumber.find((i: string) => i === 'The card number must be between 15 and 16 digits.') !== -1
	) {
		dispatch(updateOrderForm({
			key: 'cardNumber',
			value: orderForm.cardNumber.value,
			error: '請填寫完整卡號',
		}));
		return defaultState.orderResult;
	}

	if (isExist(data)) {
		// 將訂單資訊先存入 localStorage，這個資料會在進入付款成功頁時被清掉
		storage.setItem(`order-${data.data?.orderNo}`, JSON.stringify({
			orderForm,
			singleProduct: currentCheckoutProducts
		}));
	}

	if (status !== 200 && status !== 201) {
		history.replace(`/sponsorship-failed${isExist(data) ? `?no=${data.data?.orderNo}` : ''}`);
		return defaultState.orderResult;
	}

	await dispatch(goToStage(3));

	// 文總的購物車跟結帳沒有連動，所以購物車商品要手動打 api 清空
	if (!isSingleProductCheckout) {
		dispatch(deleteCartProductProcess());
	}

	return {
		orderNo: data.data?.orderNo,
		html3DSecure: data.data?.html3DSecure,
	};
});

export const repayOrder = createAction<(dispatch: Dispatch, getState: GetState) => Promise<OrderResultProperty>, string>(
	'REPAY_ORDER',
orderNo => async (dispatch, getState) => {
	const { canSubmit } = selectOrder(getState());	

	if (!canSubmit) {
		console.warn("Can't submit!");
		return defaultState.orderResult;
	}

	const {
		order: { orderForm },
	} = getState();

	const { status, data } = await wrapAuthFetch(
		'orders/repay',
		{
			method: 'POST',
			body: JSON.stringify({
				no: orderNo,
				cardNumber: orderForm.cardNumber.value,
				expirationDate: orderForm.expirationYear.value.slice(-2) + orderForm.expirationMonth.value,
				cvc: orderForm.cvc.value,
			}),
		},
	);

	if (status !== 200 && status !== 201) {
		history.replace(`/sponsorship-failed?no=${orderNo}`);
		return defaultState.orderResult;
	}

	await dispatch(goToStage(3));

	return {
		orderNo: data.data?.orderNo,
		html3DSecure: data.data?.html3DSecure,
	};
});

const determinateNoError = (data: OrderFormProperty) =>
	Object.keys(defaultState.orderForm).every(
		(key: string) => data[key as keyof OrderFormProperty].error === '',
	);

export const clearOrderForm = createAction('CLEAR_ORDER_FORM');

export const updateOrderForm = createAction<FormItemPayload, FormItemPayload>(
	'UPDATE_ORDER_FORM',
	({ key, value, error }) => ({ key, value, error }),
);

export const getOrderFormFromStorage = createAction<OrderFormProperty, string>(
	'GET_ORDER_FORM_FROM_STORAGE',
	orderNo => {
		const storageData = JSON.parse(storage.getItem(`order-${orderNo}`) as string)?.orderForm;
		return {
			...storageData,
			cardNumber: { value: '', error: '' },
			expirationMonth: { value: '', error: '' },
			expirationYear: { value: '', error: '' },
			cvc: { value: '', error: '' },
		};
	},
);

export interface State {
	loading: boolean;
	orders: OrderItemProperty[];
	orderInfo: OrderInfo;
	orderForm: OrderFormProperty;
	orderResult: OrderResultProperty;
}

export const defaultState: State = {
	loading: false,
	orders: [],
	orderInfo: {
		orderTime: '',
		status: '',
		totalPrice: 0,
		sponsorshipPlans: [],
		addressee: '',
		dialingCode: 886,
		phone: '',
		county: '',
		district: '',
		address: '',
		sponsorRemark: '',
	},
	orderForm: {
		addressee: { value: '', error: '' },
		address: { value: '', error: '' },
		dialCode: { value: '+886', error: '' },
		phone: { value: '', error: '' },
		county: { value: '', error: '' },
		district: { value: '', error: '' },
		sponsorRemark: { value: '', error: '' },
		cardNumber: { value: '', error: '' },
		expirationMonth: { value: '', error: '' },
		expirationYear: { value: '', error: '' },
		cvc: { value: '', error: '' },
	},
	orderResult: {
		orderNo: '',
		html3DSecure: '',
	}
};

export const reducer = {
	order: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_ORDER_DATA_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_ORDER_DATA_FULFILLED: (state, action: Action<OrderItemProperty[]>) => ({
				...state,
				orders: action.payload,
				loading: false,
			}),

			GET_ORDER_INFO_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_ORDER_INFO_FULFILLED: (state, action: Action<OrderInfo>) => ({
				...state,
				orderInfo: action.payload,
				loading: false,
			}),

			UPDATE_ORDER_FORM: (state, action: Action<FormItemPayload>) => ({
				...state,
				orderForm: {
					...state.orderForm,
					[action.payload.key]: {
						...state.orderForm[action.payload.key as keyof OrderFormProperty],
						value: action.payload.value,
						error: action.payload.error,
					},
				},
			}),

			GET_ORDER_FORM_FROM_STORAGE: (state, action: Action<OrderFormProperty>) => ({
				...state,
				orderForm: action.payload,
			}),

			CLEAR_ORDER_FORM: state => ({
				...state,
				orderForm: defaultState.orderForm,
			}),

			CREATE_ORDER_PENDING: state => ({
				...state,
				loading: true,
			}),

			CREATE_ORDER_FULFILLED: (state, action: Action<OrderResultProperty>) => ({
				...state,
				orderResult: action.payload,
				loading: false,
			}),

			REPAY_ORDER_PENDING: state => ({
				...state,
				loading: true,
			}),

			REPAY_ORDER_FULFILLED: (state, action: Action<OrderResultProperty>) => ({
				...state,
				orderResult: action.payload,
				loading: false,
			}),
		},
		defaultState,
	),
};

export const selectOrder = createSelector(
	(state: GlobalState) => state.order.loading,
	(state: GlobalState) => state.order.orders,
	(state: GlobalState) => state.order.orderInfo,
	(state: GlobalState) => state.order.orderForm,
	(state: GlobalState) => state.order.orderResult,
	(state: GlobalState) => state.user.data.isEmailVerified,
	(state: GlobalState) => state.cart.singleProduct,
	(state: GlobalState) => state.cart.products,
	(
		loading,
		orders,
		orderInfo,
		orderForm,
		orderResult,
		isEmailVerified,
		singleProduct,
		products,
	) => ({
		loading,
		orders,
		orderInfo,
		orderForm,
		orderResult: {
			...orderResult,
			html3DSecure: isExist(orderResult.html3DSecure) ? orderResult.html3DSecure
				.replace(/\\/g, "") : '',
		},
		canSubmit:
			isEmailVerified && determinateNoError(orderForm) && (singleProduct !== null || products.length !== 0),
	}),
);

export const useOrder = () => useRedux(selectOrder, { getOrderData, getOrderInfo, createOrder, updateOrderForm, repayOrder });
