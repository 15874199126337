import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { getPublicationList, clearPublicationList } from 'models/publication';

const routes: CustomRoute = {
	path: '/publications',
	components: () => [],
	render: (_, children) => children,
	onEnter: async () => {},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'publications' */ './component')],
			render: ([Publication]) => <Publication />,
			onEnter: async ({ next, store }) => {
				console.log('on Enter publications');
				store.dispatch(clearPublicationList());
				await store.dispatch(getPublicationList());
				await next();
				console.log('on Enter publications / end');
			},
		},
	],
};

export default routes;
