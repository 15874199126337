import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

import ThemeRoute from './Theme';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/multi-media-centre',
	components: () => [],
	render: (_, children) => children,
	onEnter: async () => {},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'multiMedia' */ './component')],
			render: ([MultiMedia]) => <MultiMedia />,
			onEnter: async ({ next }) => {
				console.log('on Enter MultiMedia');
				const children = await next();
				console.log('on Enter MultiMedia / end');
				return children;
			},
		},
		{
			path: '/read',
			components: () => [import(/* webpackChunkName: 'read' */ './Read/component')],
			render: ([Read]) => <Read />,
			onEnter: async ({ next }) => {
				console.log('on Enter MultiMedia/read');

				const children = await next();
				console.log('on Enter MultiMedia/read / end');
				return children;
			},
		},
		ThemeRoute,
	],
};
export default routes;
