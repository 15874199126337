import React, { useEffect, useRef } from 'react';
import { useGlobal } from 'models/global';

import classnames from 'classnames';

import { useTranslation } from 'react-i18next';

import { useMedia } from 'util/event';

import useNavi from 'util/hook/useNavi';
import { useEventCategory, useEventList } from 'models/event';
import { useMultiMediaCategory, useMultiMediaThemeList } from 'models/multiMedia';
import { useNewsList } from 'models/news';

import Icon from 'components/atoms/Icon';
import Link from 'components/atoms/Link';

import NaviLang from 'components/atoms/NaviLang';

import Navigation, { NaviMenuProperty } from 'components/molecules/Navigation';
import NaviFunctionButtonGroup from 'components/molecules/NaviFunctionButtonGroup';
import Accordion from 'components/molecules/Accordion';
import Hamburger from 'components/atoms/Hamburger';

import Logo from 'images/icon/logo.inline.svg';
import sponsor from 'images/icon/general/hand-holding-heart-color.inline.svg';
import global from 'images/icon/general/global.inline.svg';

import styles from './index.module.css';

const Header: React.FC = () => {
	const { t } = useTranslation('nav');
	const device = useMedia();
	const [{ data: eventCategoryData }] = useEventCategory();
	const [, { getEventList }] = useEventList();
	const [{ data: multiMediaCategoryData }] = useMultiMediaCategory();
	const [, { getMultiMediaThemeList }] = useMultiMediaThemeList();

	const [
		{ isNavOpen },
		{ setBackgroundScrollY, restoreBackgroundScrollY, setNavOpen, setNavClose },
	] = useGlobal();

	const { menuType, setMenuType } = useNavi();

	const [, { getNewsList }] = useNewsList();

	const naviRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (isNavOpen && menuType !== 'nav') {
			setMenuType('nav');
		}

		if (isNavOpen) {
			setBackgroundScrollY();
		} else {
			restoreBackgroundScrollY();
		}

		if (naviRef.current) {
			if (naviRef.current.clientHeight + 60 < window.innerHeight) {
				document.getElementById('phoneNaviFunction')?.classList.add(styles.fixed);
			} else {
				document.getElementById('phoneNaviFunction')?.classList.remove(styles.fixed);
			}
		}
	}, [isNavOpen]);

	const NaviData: NaviMenuProperty[] = [
		{
			id: 'news',
			title: t('news'),
			link: '/news',
			sub_group: [
				{
					id: 0,
					title: t('all'),
					link: `/news`,
					onClick: () => {
						getNewsList(1, 'ALL');
					},
				},
				{
					id: 'ADMINISTRATION',
					title: t('news-1'),
					link: `/news`,
					onClick: () => {
						getNewsList(1, 'ADMINISTRATION');
					},
				},
				{
					id: 'MEDIA',
					title: t('news-2'),
					link: `/news`,
					onClick: () => {
						getNewsList(1, 'MEDIA');
					},
				},
				{
					id: 'EVENT',
					title: t('news-3'),
					link: `/news`,
					onClick: () => {
						getNewsList(1, 'EVENT');
					},
				},
			],
		},
		{
			id: 'events',
			title: t('event'),
			link: '/events',
			sub_group: [
				{
					id: 0,
					title: t('all'),
					link: `/events`,
					onClick: () => {
						getEventList({ type: 'DEFAULT', categoryId: 0 });
					},
				},
				...eventCategoryData.map(item => ({
					id: item.id,
					title: item.name,
					onClick: () => {
						getEventList({ type: 'DEFAULT', categoryId: item.id });
					},
					link: `/events?categoryId=${item.id}`,
				})),
			],
		},
		{
			id: 'multi-media-centre',
			title: t('multi-media-centre'),
			link: '/',
			sub_group: [
				{
					id: 0,
					title: t('all'),
					link: `/multi-media-centre`,
					onClick: () => {
						getMultiMediaThemeList({ categoryId: 0 });
					},
				},
				...multiMediaCategoryData.map(item => ({
					id: item.id,
					title: item.name,
					link: `/multi-media-centre?categoryId=${item.id}`,
					onClick: () => {
						getMultiMediaThemeList({ categoryId: item.id });
					},
				})),
			],
		},
		{
			id: 'south-taipei-space',
			title: t('south-taipei-space'),
			link: '/',
			sub_group: [
				{
					id: 1,
					title: t('south-taipei-space-1'),
					link: `/south-taipei-space-one`,
				},
				{
					id: 2,
					title: t('south-taipei-space-2'),
					link: `/south-taipei-space-two`,
				},
			],
		},
		{
			id: 'fountain',
			title: t('fountain'),
			link: '/fountain',
		},
		{
			id: 'campusambassador',
			title: t('campus-ambassador'),
			link: '/campus-ambassador',
		},
		{
			id: 'collection',
			title: t('collection'),
			link: '/',
			sub_group: [
				{
					id: 1,
					title: t('past-artistic-events'),
					link: `/past-artistic-events`,
				},
				{
					id: 2,
					title: t('collection-2'),
					link: `/presidential-cultural-award`,
				},
				{
					id: 3,
					title: t('collection-3'),
					link: `/publications`,
				},
			],
		},
		{
			id: 'about',
			title: t('about'),
			link: '/',
			sub_group: [
				{
					id: 1,
					title: t('about-1'),
					link: `/our-mission`,
				},
				{
					id: 2,
					title: t('about-2'),
					link: `/history`,
				},
				{
					id: 3,
					title: t('about-3'),
					link: `/org-structure`,
				},
				{
					id: 4,
					title: t('about-4'),
					link: `/sponsored-by`,
				},
				{
					id: 5,
					title: t('about-5'),
					link: `/transport-info`,
				},
			],
		},
	];

	// 桌機才有
	if (device === 'desktop') {
		return (
			<header className={styles.header}>
				<div className={styles.top}>
					<Link to="/">
						<Icon className={styles.logo} src={Logo} />
					</Link>

					<Navigation data={NaviData} />

					<NaviFunctionButtonGroup />

					<div className={styles.bar} />

					<NaviLang className={styles.naviLang} />

					<Link className={styles.sponsor} to="/sponsor-us">
						<Icon size="normal" src={sponsor} />
						<span>{t('sponsor')}</span>
					</Link>
				</div>
			</header>
		);
	}

	// tablet, mobile
	return (
		<div className={classnames(styles.header, styles.mobile, isNavOpen && styles.open)}>
			<div className={styles.top}>
				<Link to="/">
					<Icon className={styles.logo} src={Logo} />
				</Link>

				<NaviFunctionButtonGroup />
				<div className={styles.bar} />
				<Hamburger
					className={styles.hamburger}
					open={isNavOpen}
					onClick={isNavOpen ? setNavClose : setNavOpen}
				/>
			</div>
			{isNavOpen && (
				<div className={styles.bottom} ref={naviRef}>
					<div className={styles.wrapper}>
						<Accordion data={NaviData} />
					</div>

					<div id="phoneNaviFunction" className={styles.functionGroup}>
						<Link className={styles.sponsor} to="/sponsor-us">
							<Icon size="normal" src={sponsor} />
							{t('sponsor')}
						</Link>
						<div className={styles.lang}>
							<Icon src={global} size="x-small" />
							<NaviLang className={styles.naviLang} color="white" />
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Header;
