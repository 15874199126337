import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { clearForm } from 'models/auth';

const routes: CustomRoute = {
	path: '/signup',
	components: () => [import('./component')],
	render: ([Signup]) => <Signup />,
	onEnter: async ({ next, store }) => {
		console.log('on Enter Register');

		store.dispatch(clearForm('signUpForm'));

		const children = await next();

		console.log('on Enter Register / end');
		return children;
	},
};

export default routes;
