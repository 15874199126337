import React, { MouseEvent, ChangeEvent, FocusEvent } from 'react';
import classnames from 'classnames';

import Button from 'components/atoms/Button';

import styles from './index.module.css';
import Icon, { IconSize } from '../Icon';

interface InputProperty {
	className?:string;
	hasError?: boolean;
	inputIcon?: string;
	inputIconSize?: IconSize;
	placeholder: string;
	value?: string;
	hasButton?: boolean;
	buttonText?: string;
	buttonName?: string;
	buttonIcon?: string;
	hasBorder?: boolean;
	onClickButton?: (e: MouseEvent) => void;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
	onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProperty> = ({
	className,
	hasError = false,
	inputIcon,
	inputIconSize = 'normal',
	placeholder = '',
	value = '',
	hasButton = false,
	buttonText = '',
	buttonName = '',
	buttonIcon,
	hasBorder = false,
	onClickButton = () => {},
	onChange = () => {},
	onBlur = () => {},
	onFocus = () => {},
}) => (
	<div
		className={classnames(className, styles.input, {
			[styles.hasError]: hasError,
			[styles.hasBorder]: hasBorder,
			[styles.hasButton]: hasButton,
			[styles.hasValue]: value.length > 0 
		})}
	>
		{inputIcon && (
			<Icon
				className={classnames(styles.icon, { [styles.hasValue]: value.length > 0 })}
				src={inputIcon}
				size={inputIconSize}
			/>
		)}
		<input
			className={classnames(styles.input, { [styles.hasValue]: value.length > 0 })}
			placeholder={placeholder}
			name={buttonName}
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			onFocus={onFocus}
		/>
		{hasButton && (
			<Button
				className={classnames(styles.button, buttonIcon && styles.withIcon)}
				Icon={buttonIcon}
				onClick={onClickButton}
			>
				{buttonText}
			</Button>
		)}
	</div>
);

export default Input;
