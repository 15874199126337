import React from 'react';
import classnames from 'classnames';

import { useTranslation } from 'react-i18next';

import Icon from 'components/atoms/Icon';
import Link from 'components/atoms/Link';

import logoWhite from 'images/icon/logo-white.inline.svg';
import fbIcon from 'images/icon/socialMedia/facebook-outline.inline.svg';
import igIcon from 'images/icon/socialMedia/ig-outline.inline.svg';
import lineIcon from 'images/icon/socialMedia/line-outline.inline.svg';
import youtubeIcon from 'images/icon/socialMedia/youtube.inline.svg';
import twitterIcon from 'images/icon/socialMedia/twitter.inline.svg';
import backToTop from 'images/icon/general/back-to-top.inline.svg';

import styles from './index.module.css';

interface FooterProperty {
	className?: string;
}

interface LinkItemProperty {
	text: string;
	link: string;
}

interface MediaItemProperty extends LinkItemProperty {
	icon: string;
}

interface FooterDataProperty {
	logo: string;
	zipCode: number | string;
	address: string;
	tel: string;
	mail: string;
	link: string;
	linkItem: LinkItemProperty[];
	followUs: string;
	mediaItem: MediaItemProperty[];
	year: number;
	rightReserved: string;
}

const Footer: React.FC<FooterProperty> = ({ className }) => {
	const { t } = useTranslation('footer');

	const footerData: FooterDataProperty = {
		logo: logoWhite,
		zipCode: '100057',
		address: t('address'),
		tel: '02-23964256',
		mail: 'service@mail.create.org.tw',
		link: t('link'),
		linkItem: [
			{
				text: t('linkItem.1.text'),
				link: '/privacy',
			},
			{
				text: t('linkItem.2.text'),
				link: '/copyright',
			},
			{
				text: t('linkItem.3.text'),
				link: '/links',
			},
		],
		followUs: t('followUs'),
		mediaItem: [
			{
				text: 'Facebook',
				link: 'https://www.facebook.com/GACCTW/',
				icon: fbIcon,
			},
			{
				text: 'Instagram',
				link: 'https://www.instagram.com/gacc.tw/',
				icon: igIcon,
			},
			{
				text: 'Line',
				link: 'https://page.line.me/hrp5564g?openQrModal=true',
				icon: lineIcon,
			},
			{
				text: 'YouTube',
				link: 'https://www.youtube.com/channel/UCJ6QOe58WVsGcRkjQX3x6bg',
				icon: youtubeIcon,
			},

			{
				text: 'X (Twitter)',
				link: 'https://twitter.com/gacc_tw',
				icon: twitterIcon,
			},
		],
		year: new Date().getFullYear(),
		rightReserved: t('rightReserved'),
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	};

	return (
		<footer className={classnames(styles.footer, className)}>
			<div className={styles.wrapper}>
				<div className={styles.backToTop}>
					<Icon className={styles.arrow} src={backToTop} onClick={() => scrollToTop()} />
					Back to Top
				</div>
				<div className={styles.logo}>
					<Icon className={styles.icon} src={footerData.logo} size="large" />
				</div>
				<div className={styles.footerContent}>
					<div className={styles.top}>
						<div className={styles.footerBlock}>
							<div className={styles.footerItem}>
								<p>{footerData.zipCode}</p>
								<p>{footerData.address}</p>
							</div>
							<div className={styles.footerItem}>
								<div className={styles.footerSubGrid}>
									<p className={styles.label}>TEL</p>
									<p>{footerData.tel}</p>
									<p className={styles.label}>MAIL</p>
									<p>{footerData.mail}</p>
								</div>
							</div>
						</div>
						<div className={classnames(styles.footerBlock, styles.reverse)}>
							<div className={styles.footerItem}>
								<p>{footerData.link}</p>
								<div className={styles.footerList}>
									{footerData.linkItem.map(item => (
										<Link
											key={item.text}
											to={item.link}
											className={classnames(styles.footerItemLink, styles.withBar)}
										>
											{item.text}
										</Link>
									))}
								</div>
							</div>
							<div className={styles.footerItem}>
								<p>{footerData.followUs}</p>
								<div className={classnames(styles.footerList, styles.followUs)}>
									{footerData.mediaItem.map(item => (
										<Link
											key={item.text}
											to={item.link}
											className={classnames(styles.footerItemLink, styles.withIcon)}
											type="external"
										>
											<Icon className={styles.icon} src={item.icon} size="small" />
											{item.text}
										</Link>
									))}
								</div>
							</div>
						</div>
					</div>
					<div className={styles.copyRight}>
						<p>
							© {footerData.year} {footerData.rightReserved}
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
