import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { getEventList, getEventYear } from 'models/event';

import ArtisticEventDetailRoute from './ArtisticEventDetail';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/past-artistic-events',
	components: () => [],
	render: (_, children) => children,
	onEnter: async () => {},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'artisticEvent' */ './component')],
			render: ([Event]) => <Event />,
			onEnter: async ({ next, store }) => {
				console.log('on Enter ArtisticEvent');
				await store.dispatch(getEventList({ type: 'MEMORIAL', categoryId: 0 }));
				await store.dispatch(getEventYear('MEMORIAL'));
				await next();
				console.log('on Enter ArtisticEvent / end');
			},
		},
		ArtisticEventDetailRoute,
	],
};
export default routes;
