import React from 'react';
import i18n from 'i18next';

import { getOrderInfo } from 'models/order';
import { CustomRoute } from 'util/hook/useRouter';

import { baseUrl } from 'store/history';
import { isExist } from 'util/helper';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/:orderID',
	components: () => [import(/* webpackChunkName: 'sponsorshipDetail' */ './component')],
	render: ([SponsorshipDetail], _, orderID) => <SponsorshipDetail orderID={orderID} />,
	onPreEnter: async ({ next, params, store, history, search }) => {
		const { orderID } = params;
		console.log(`on Enter Sponsorship/${orderID}`);

		const {
			auth: { token },
		} = store.getState();

		if (!isExist(token)) {
			history.replace(`${baseUrl}/${i18n.language}${isExist(search) ? search : ''}`);
		}


		await store.dispatch(getOrderInfo(`${orderID}`));
		await next();
		console.log(`on Enter Sponsorship/${orderID} end`);
		return orderID as string
	},
};

export default routes;
