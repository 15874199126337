import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { clearForm } from 'models/auth';

const routes: CustomRoute = {
	path: '/reset-password',
	components: () => [import('./component')],
	render: ([ResetPassword]) => <ResetPassword />,
	onEnter: async ({ store, next }) => {
		console.log('on Enter ResetPassword');

		store.dispatch(clearForm('resetPasswordForm'));

		const children = await next();

		console.log('on Enter ResetPassword / end');
		return children;
	},
};

export default routes;
