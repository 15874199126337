import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

import { getNewsInfo } from 'models/news';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/:newsID',
	components: () => [import(/* webpackChunkName: 'newsDetail' */ './component')],
	render: ([NewsDetail]) => <NewsDetail />,
	onEnter: async ({ next, store, params }) => {
		const { newsID } = params;
		console.log(`on Enter News/${newsID}`);

		await store.dispatch(getNewsInfo(`${newsID}`));
		await next();
		console.log(`on Enter News/${newsID} end`);
	},
};

export default routes;
