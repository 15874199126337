import React, { MouseEvent } from 'react';

import classnames from 'classnames';

import Icon from 'components/atoms/Icon';
import { useRouting } from 'models/routing';
import { useLanguage } from 'models/i18n';
import { useGlobal } from 'models/global';

import backArrow from 'images/icon/general/arrow-left-with-tail.inline.svg';

import styles from './index.module.css';

interface LinkProperty {
	className?: string;
	type?: 'internal' | 'external';
	// eslint-disable-next-line react/no-unused-prop-types
	to?: string;
	onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

const Link: React.FC<LinkProperty> = ({
	className,
	type = 'internal',
	to,
	onClick = () => { },
	children,
}) => {
	const [lang] = useLanguage();
	const [, { pushRoute }] = useRouting();
	const [{ isNavOpen }, { setNavClose }] = useGlobal();

	const onClickHandler =
		(callback: (e: MouseEvent<HTMLAnchorElement>) => void) =>
			(e: MouseEvent<HTMLAnchorElement>): void => {
				const { pathname, search } = e.currentTarget;

				e.preventDefault();

				if (isNavOpen) {
					setNavClose();
				}

				callback(e);

				pushRoute({
					pathname,
					search,
				});
			};

	if (type === 'external') {
		return (
			<a
				className={className}
				href={to?.includes('http') ? to : `/${lang}${to}`}
				target="_blank"
				rel="noopener noreferrer"
				onClick={onClick}
			>
				{children}
			</a>
		);
	}

	return (
		<a
			className={className}
			href={`/${lang}${to}`}
			role="button"
			tabIndex={0}
			onClick={onClickHandler(onClick)}
			onKeyPress={() => { }}
		>
			{children}
		</a>
	);
};

export const BackLink: React.FC<LinkProperty> = ({
	className,
	type = 'internal',
	to,
	onClick = () => { },
	children,
}) => (
	<Link
		className={classnames(styles.link, styles.backLink, className)}
		type={type}
		to={to}
		onClick={onClick}
	>
		<Icon className={styles.icon} src={backArrow} />
		{children}
	</Link>
);

export const UnderlineLink: React.FC<LinkProperty> = ({
	className,
	type = 'internal',
	to,
	onClick = () => { },
	children,
}) => (
	<Link
		className={classnames(styles.link, styles.linkUnderlined, className)}
		type={type}
		to={to}
		onClick={onClick}
	>
		{children}
	</Link>
);

export default Link;
