import { useState, useEffect, useRef } from 'react';

export const useMedia = () => {
	const mobileMedia = window.matchMedia('(max-width: 767px)');
	const tabletMedia = window.matchMedia('(max-width: 1279px) and (min-width: 768px)');
	const desktopMedia = window.matchMedia('(min-width: 1279px)');

	let defaultMedia = 'desktop';

	if (mobileMedia.matches) {
		defaultMedia = 'mobile';
	}

	if (tabletMedia.matches) {
		defaultMedia = 'tablet';
	}

	const [media, setMedia] = useState(defaultMedia);

	const handleMediaChange = (mediaName: string) => (mediaHandler: MediaQueryListEventInit) => {
		if (mediaHandler.matches && mediaName !== media) {
			setMedia(mediaName);
		}
	};

	useEffect(() => {
		const mobileHandler = handleMediaChange('mobile');
		const tabletHandler = handleMediaChange('tablet');
		const desktopHandler = handleMediaChange('desktop');

		mobileMedia.addListener(mobileHandler);
		tabletMedia.addListener(tabletHandler);
		desktopMedia.addListener(desktopHandler);

		return () => {
			mobileMedia.removeListener(mobileHandler);
			tabletMedia.removeListener(tabletHandler);
			desktopMedia.removeListener(desktopHandler);
		};
	}, [media]);

	return media;
};

interface EventHandlers {
	[key: string]: EventListenerOrEventListenerObject;
}

// Handle the Dom event
export const useDom = (eventHandlers: EventHandlers) => {
	// Only subscribe/unsubscribe on mount/unmount lifecycle
	useEffect(() => {
		Object.keys(eventHandlers).forEach(event =>
			window.addEventListener(event, eventHandlers[event]),
		);

		return () => {
			Object.keys(eventHandlers).forEach(event =>
				window.removeEventListener(event, eventHandlers[event]),
			);
		};
	}, []);
};

export const useResize = () => {
	const { clientWidth, clientHeight } = document.documentElement;

	const [size, setState] = useState({
		width: clientWidth,
		height: clientHeight,
	});

	const preventTrigger = useRef(false);

	const resizeEvent = () => {
		if (!preventTrigger.current) {
			window.requestAnimationFrame(() => {

				setState({
					width: document.documentElement.clientWidth,
					height: document.documentElement.clientHeight,
				});

				preventTrigger.current = false;
			});
		}
		preventTrigger.current = true;
	};

	useDom({ resize: resizeEvent });

	return size;
};

export const useScroll = () => {
	const [scrollY, setState] = useState(0);

	const scrollEvent = () => {
		setState(window.scrollY);
	};

	useDom({ scroll: scrollEvent });

	return { scrollY };
};

export const useElement = <T extends HTMLElement>(
	{
		defaultHeight = 0,
		defaultWidth = 0,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		onUpdateOffsetTop = (_top: number) => {},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		onUpdateHeight = (_height: number) => {},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		onUpdateWidth = (_width: number) => {},
	} = {},
	triggerEffect = [],
) => {
	const [{ height, width, offsetTop }, setState] = useState({
		height: defaultHeight,
		width: defaultWidth,
		offsetTop: 0,
	});
	const refChild = useRef<T>(null);
	// Get real height, width, offsetTop to check should upldate state or not
	const lastUpdateHeight = useRef(height);
	const lastUpdateWidth = useRef(width);
	const lastUpdateOffsetTop = useRef(offsetTop);

	const handleOffsetTop = () => {
		const node = refChild.current;
		const bodyRect = document.body.getBoundingClientRect();

		if (node) {
			const elemRect = node.getBoundingClientRect();
			const newOffsetTop = elemRect.top - bodyRect.top;

			if (lastUpdateOffsetTop.current !== newOffsetTop) {
				setState(prevState => ({ ...prevState, offsetTop: newOffsetTop }));

				onUpdateOffsetTop(newOffsetTop);
				lastUpdateOffsetTop.current = newOffsetTop;
			}
		}
	};

	const handleHeight = () => {
		const node = refChild.current;

		if (node && lastUpdateHeight.current !== node.offsetHeight && node.offsetHeight > 0) {
			// Update the component height
			setState(prevState => ({ ...prevState, height: node.offsetHeight }));

			onUpdateHeight(node.offsetHeight);
			lastUpdateHeight.current = node.offsetHeight;
		}
	};

	const handleWidth = () => {
		const node = refChild.current;

		if (node && lastUpdateWidth.current !== node.offsetWidth && node.offsetWidth > 0) {
			// Update the component width
			setState(prevState => ({ ...prevState, width: node.offsetWidth }));

			onUpdateWidth(node.offsetWidth);
			lastUpdateWidth.current = node.offsetWidth;
		}
	};

	const handleDomEvent = () => {
		if (refChild.current !== null) {
			handleHeight();
			handleWidth();
			handleOffsetTop();
		}
	};

	const handleScroll = () => {
		window.requestAnimationFrame(handleDomEvent);
	};

	const handleResize = () => {
		window.requestAnimationFrame(handleDomEvent);
	};

	const handleMutation = () => {
		window.requestAnimationFrame(handleDomEvent);
	};

	useDom({ scroll: handleScroll, resize: handleResize, DOMNodeInserted: handleMutation });

	useEffect(() => {
		let didUnsubscribe = false;

		const updateDomHandle = () => {
			if (didUnsubscribe) {
				return;
			}
			handleDomEvent();
		};

		// Setup initial value
		updateDomHandle();

		return () => {
			didUnsubscribe = true;
		};
	}, [refChild, ...triggerEffect]);

	return { refChild, height, width, offsetTop };
};
