import React from 'react';
import i18n from 'i18next';

import { changeLng, isLanguageSupportWithRoute } from 'util/i18n';
import { CustomRoute } from 'util/hook/useRouter';
import { baseUrl } from 'store/history';
import { LangCode } from 'util/i18n/config';
import { isExist, sleep, lazyExecute } from 'util/helper';

import { getUser } from 'models/user';
import { getCartData } from 'models/cart';
import { getEventCategory, getEventList } from 'models/event';
import { getMultiMediaCategory } from 'models/multiMedia';
import { clearHomeBannerList, getHomeBannerList } from 'models/homeBanner';
import { getNewsList } from 'models/news';

import PageRoute from './Page';
import NewsRoute from './News';
import EventRoute from './Events';
import PreviewRoute from './Preview';
import MultiMediaRoute from './MultiMedia';
import FountainRoute from './Fountain';
import AwardRoute from './Award';
import SearchRoute from './Search';
import ArtisticEventRoute from './ArtisticEvent';
import SouthTaipeiSpaceRoute from './SouthTaipeiSpace';
import SponsorListRoute from './SponsorList';
import PublicationRoute from './Publication';
import LoginRoute from './User/Login';
import SignUpRoute from './User/SignUp';
import SponsorshipRoute from './Sponsorship';
import SponsorUsRoute from './SponsorUs';
import SponsorCheckoutRoute from './SponsorCheckout';
import ForgotPasswordRoute from './User/ForgotPassword';
import ResetPasswordRoute from './User/ResetPassword';
import WelcomeRoute from './User/Welcome';
import MemberRoute from './Member';
import SponsorshipSuccessRoute from './SponsorshipSuccess';
import SponsorshipFailRoute from './SponsorshipFail';
import Copyright from './About/Copyright';
import History from './About/History';
import Links from './About/Links';
import OrgStructure from './About/OrgStructure';
import OurMission from './About/OurMission';
import Privacy from './About/Privacy';
import SponsoredBy from './About/SponsoredBy';
import TransportInfo from './About/TransportInfo';
import Unsubscribe from './Unsubscribe';
import Campusambassador from './campusAmbassador';

const routes: CustomRoute = {
	path: '/',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		console.log('on Enter Root');
		const children = await next();
		lazyExecute(() => {
			window.scrollTo({
				top: 0,
				left: 0,
			});
		});
		console.log('on Enter Root / end');
		return children;
	},
	children: [
		{
			path: '',
			components: () => [],
			render: (_, children) => children,
			onEnter: async ({ next, history, store, pathname, search }) => {
				const {
					i18n: { lang },
				} = store.getState();
				console.log('on redirect to multilingual');

				// 如果一進來沒有語系，就先塞預設
				history.replace(`${baseUrl}/${lang}${pathname}${isExist(search) ? search : ''}`);
				const children = await next();
				return children;
			},
		},
		{
			path: '/:lang',
			components: () => [],
			render: (_, children) => children,
			onEnter: async ({ store, history, pathname, search, next, params }) => {
				const {
					i18n: { lang },
				} = store.getState();

				const newLang = params.lang;

				if (!isLanguageSupportWithRoute(`${newLang}`)) {
					console.log('not support lang');
					history.replace(`${baseUrl}/${lang}${pathname}${isExist(search) ? search : ''}`);
					return null;
				}

				if (newLang !== lang) {
					changeLng(newLang as LangCode);
					await sleep(500);
				}

				console.log(`on Enter ${i18n.language}`);

				// get dynamic navigation
				await store.dispatch(getEventCategory());
				await store.dispatch(getMultiMediaCategory());

				const {
					auth: { token },
				} = store.getState();

				if (isExist(token)) {
					await Promise.all([store.dispatch(getUser()), store.dispatch(getCartData())]); // getUser, getCart, getOrder
				}

				const children = await next();
				console.log(`on Enter ${i18n.language} / end`);
				return children;
			},

			children: [
				{
					path: '',
					components: () => [import(/* webpackChunkName: 'home' */ './Home')],
					render: ([Home]) => <Home />,
					onEnter: async ({ next, store }) => {
						console.log('on Enter Home');

						await store.dispatch(clearHomeBannerList());
						await store.dispatch(getHomeBannerList());
						await store.dispatch(getNewsList(1, 'ALL'));
						await store.dispatch(getEventList({ type: 'DEFAULT', categoryId: 0 }));

						const children = await next();
						console.log('on Enter Home / end');
						return children;
					},
				},
				PageRoute,
				NewsRoute,
				PreviewRoute,
				EventRoute,
				MultiMediaRoute,
				FountainRoute,
				AwardRoute,
				SearchRoute,
				ArtisticEventRoute,
				PublicationRoute,
				SouthTaipeiSpaceRoute,
				SponsorListRoute,
				SponsorshipRoute,
				LoginRoute,
				SponsorUsRoute,
				SignUpRoute,
				ForgotPasswordRoute,
				ResetPasswordRoute,
				WelcomeRoute,
				MemberRoute,
				SponsorCheckoutRoute,
				SponsorshipSuccessRoute,
				SponsorshipFailRoute,
				Copyright,
				History,
				Links,
				OrgStructure,
				OurMission,
				Privacy,
				SponsoredBy,
				TransportInfo,
				Unsubscribe,
				Campusambassador
			],
		},
	],
};

export default routes;
