/* eslint-disable camelcase */

import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';
import { isExist } from 'util/helper';

import readImg from 'files/read/banner.jpg';

import { State as GlobalState } from './reducers';

export interface MultiMediaThemeItemProperty {
	id: number;
	name: string;
	image: string;
	content?: string;
	categoryName?: string;
	articleCategoryName?: string;
	date: string;
	categoryID?: string;
}

type MultiMediaThemeListItemPayload = Pick<
	State['multiMediaThemeList'],
	'data' | 'currentCategoryId'
>;

type getMultiMediaThemeListProperty = {
	categoryId: number;
};

export const getMultiMediaThemeList = createAction<Promise<MultiMediaThemeListItemPayload>, getMultiMediaThemeListProperty>('GET_MULTIMEDIA_THEME_LIST', async ({ categoryId }) => {

	try {
		let query = 'article/themes';

		if (categoryId !== 0) {
			query += `?article_category_id=${categoryId}`;
		}

		const { status, message, data } = await wrapFetch(query, {
			method: 'GET',
		});

		if (status !== 200) {
			throw new Error(message);
		}

		const { data: multiMediaData } = data;

		// 為台灣文學朗讀，固定寫死在前台
		multiMediaData.push({
			id: 999,
			name: '為台灣文學朗讀',
			image: readImg,
			categoryName: '台灣走向世界',
			categoryID: 'read',
		});

		return {
			data: multiMediaData.map(({ categoryId: categoryID, ...args }) => ({
				categoryID: `${categoryID}`,
				...args,
			})),
			currentCategoryId: categoryId,
		};
	} catch (error) {
		throw new Error((error as Error).message);
	}
});

interface ActionButton {
	text: string;
	link: string;
}

interface EpisodeItemProperty {
	id: number;
	name: string;
	type: 'IMAGE' | 'YOUTUBE';
	image: string;
	content: string;
	youtubeLink?: string;
	categoryName: string;
	date: string;
}

export interface MultiMediaThemeInfoProperty extends MultiMediaThemeItemProperty {
	actionButtons: ActionButton[];
	episodes: EpisodeItemProperty[];
}

type MultiMediaThemeInfoPayload = Pick<State['multiMediaThemeInfo'], 'data'>;

export const getMultiMediaThemeInfo = createAction<Promise<MultiMediaThemeInfoPayload>, string>(
	'GET_MULTIMEDIA_THEME_INFO',
	async themeId => {
		try {
			const { status, message, data } = await wrapFetch(
				`article/themes/${themeId}`,
				{ method: 'GET', },
			);

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			const { data: themeInfoData } = data;

			return {
				...themeInfoData,
				image: themeInfoData.image.length > 0 && themeInfoData.image,
				episodes: isExist(themeInfoData.episodes) ? themeInfoData.episodes.map(ep => ({
					...ep,
					image: ep.image.length > 0 && ep.image,
				})) : [],
			};
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

export const setThemeInfoPreview = createAction('SET_THEME_INFO_PREVIEW', (data: MultiMediaThemeInfoPayload) => ({
	...data
}));

export const clearMultiMediaThemeList = createAction('CLEAR_MULTIMEDIA_THEME_LIST');

export interface MultiMediaCategoryProperty {
	id: number;
	name: string;
}

type MultiMediaCategoryPayload = Pick<State['multiMediaCategory'], 'data'>;

export const getMultiMediaCategory = createAction<Promise<MultiMediaCategoryPayload>>(
	'GET_MULTIMEDIA_CATEGORY',
	async () => {
		try {
			const { status, message, data } = await wrapFetch('article/categories', {
				method: 'GET',
			});

			if (status !== 200) {
				throw new Error(message);
			}

			return data;
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

export interface MultiMediaEpisodeProperty {
	id: number;
	name: string;
	type: 'IMAGE' | 'YOUTUBE';
	image: string;
	youtubeLink?: string;
	date: string;
	content: string;
	categoryName: string;
	articleCategoryName?: string;
	categoryId: string | number;
	themeId: string | number;
	themeName: string;
}

export interface MultiMediaEpisodeInfoProperty extends MultiMediaEpisodeProperty {
	otherEpisodesWithSameTheme: MultiMediaEpisodeProperty[];
}

type EpisodeInfoPayload = Pick<State['episodeInfo'], 'data'>;

export const getMultiMediaEpisodeInfo = createAction<Promise<EpisodeInfoPayload>, string>(
	'GET_MULTIMEDIA_EPISODE_INFO',
	async episodeId => {
		try {
			const { status, message, data } = await wrapFetch(`article/episodes/${episodeId}`, {
				method: 'GET',
			});

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			const { data: episodeInfoData } = data;

			return {
				...episodeInfoData,
				image: episodeInfoData.image.length > 0 && episodeInfoData.image,
				otherEpisodesWithSameTheme: isExist(episodeInfoData.otherEpisodesWithSameTheme) ? episodeInfoData.otherEpisodesWithSameTheme.map(ep => ({
					...ep,
					image: ep.image.length > 0 && ep.image,
				})) : [],
			};
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

export const setEpisodeInfoPreview = createAction(
	'SET_EPISODE_INFO_PREVIEW',
	(data: EpisodeInfoPayload) => ({
		...data,
	}),
);

export interface State {
	multiMediaThemeList: {
		loading: boolean;
		error: string;
		data: MultiMediaThemeItemProperty[];
		currentCategoryId: number;
	};
	multiMediaThemeInfo: {
		loading: boolean;
		error: string;
		data: MultiMediaThemeInfoProperty;
	};
	multiMediaCategory: {
		loading: boolean;
		error: string;
		data: MultiMediaCategoryProperty[];
	};
	episodeInfo: {
		loading: boolean;
		error: string;
		data: MultiMediaEpisodeInfoProperty;
	};
}

export const defaultState: State = {
	multiMediaThemeList: {
		loading: false,
		error: '',
		data: [],
		currentCategoryId: 0,
	},
	multiMediaThemeInfo: {
		loading: false,
		error: '',
		data: {
			id: 0,
			name: '',
			content: '',
			image: '',
			categoryName: '',
			categoryID: '',
			actionButtons: [{ text: '', link: '' }],
			episodes: [],
		},
	},
	multiMediaCategory: {
		loading: false,
		error: '',
		data: [],
	},
	episodeInfo: {
		loading: false,
		error: '',
		data: {
			id: 0,
			type: 'IMAGE',
			date: '',
			name: '',
			content: '',
			image: '',
			youtubeLink: '',
			categoryName: '',
			categoryId: '',
			themeId: 'string | number',
			themeName: '',
			otherEpisodesWithSameTheme: [],
		},
	},
};

export const reducer = {
	multiMedia: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_MULTIMEDIA_THEME_LIST_PENDING: state => ({
				...state,
				multiMediaThemeList: {
					...state.multiMediaThemeList,
					loading: true,
					error: '',
				},
			}),

			GET_MULTIMEDIA_THEME_LIST_FULFILLED: (
				state,
				action: Action<MultiMediaThemeListItemPayload>,
			) => ({
				...state,
				multiMediaThemeList: {
					...state.multiMediaThemeList,
					...action.payload,
					loading: false,
					data: [...action.payload.data],
					currentCategoryId: action.payload.currentCategoryId,
				},
			}),

			GET_MULTIMEDIA_THEME_LIST_REJECTED: (state, action) => ({
				...state,
				multiMediaThemeList: {
					...state.multiMediaThemeList,
					loading: false,
					error: action.payload.message,
				},
			}),
			CLEAR_MULTIMEDIA_THEME_LIST: () => defaultState,

			GET_MULTIMEDIA_THEME_INFO_PENDING: state => ({
				...state,
				multiMediaThemeInfo: {
					...state.multiMediaThemeInfo,
					loading: true,
					error: '',
				},
			}),

			GET_MULTIMEDIA_THEME_INFO_FULFILLED: (state, action: Action<MultiMediaThemeInfoPayload>) => ({
				...state,
				multiMediaThemeInfo: {
					...state.multiMediaThemeInfo,

					loading: false,
					data: { ...action.payload },
				},
			}),

			GET_MULTIMEDIA_THEME_INFO_REJECTED: (state, action) => ({
				...state,
				multiMediaThemeInfo: {
					...state.multiMediaThemeInfo,
					loading: false,
					error: action.payload.message,
				},
			}),

			SET_THEME_INFO_PREVIEW: (state, action) => ({
				...state,
				multiMediaThemeInfo: {
					...state.multiMediaThemeInfo,
					data: { ...action.payload },
				},
			}),

			GET_MULTIMEDIA_CATEGORY_PENDING: state => ({
				...state,
				multiMediaCategory: {
					...state.multiMediaCategory,
					loading: true,
					error: '',
				},
			}),

			GET_MULTIMEDIA_CATEGORY_FULFILLED: (state, action: Action<MultiMediaCategoryPayload>) => ({
				...state,
				multiMediaCategory: {
					...state.multiMediaCategory,
					...action.payload,
					loading: false,
					data: action.payload.data,
				},
			}),

			GET_MULTIMEDIA_CATEGORY_REJECTED: (state, action) => ({
				...state,
				multiMediaCategory: {
					...state.multiMediaCategory,
					loading: false,
					error: action.payload.message,
				},
			}),

			GET_MULTIMEDIA_EPISODE_INFO_PENDING: state => ({
				...state,
				episodeInfo: {
					...state.episodeInfo,
					loading: true,
					error: '',
				},
			}),

			GET_MULTIMEDIA_EPISODE_INFO_FULFILLED: (state, action: Action<EpisodeInfoPayload>) => ({
				...state,
				episodeInfo: {
					...state.episodeInfo,

					loading: false,
					data: { ...action.payload },
				},
			}),

			GET_MULTIMEDIA_EPISODE_INFO_REJECTED: (state, action) => ({
				...state,
				episodeInfo: {
					...state.episodeInfo,
					loading: false,
					error: action.payload.message,
				},
			}),

			SET_EPISODE_INFO_PREVIEW: (state, action) => ({
				...state,
				episodeInfo: {
					...state.episodeInfo,
					data: { ...action.payload },
				},
			}),
		},
		defaultState,
	), // eslint-disable-line @typescript-eslint/no-explicit-any
};

/* +----------------------------------------------------------------------
++ useMultiMediaThemeList ++
++----------------------------------------------------------------------*/

const selectMultiMediaThemeList = (state: GlobalState) => state.multiMedia.multiMediaThemeList;
// for local demo
// export const useMultiMediaThemeList = () => useRedux(selectMultiMediaThemeList, {});

const themeActionMap = { getMultiMediaThemeList, clearMultiMediaThemeList };
export const useMultiMediaThemeList = () => useRedux(selectMultiMediaThemeList, themeActionMap);

/* +----------------------------------------------------------------------
++ useMultiMediaThemeInfo ++
++----------------------------------------------------------------------*/
const selectMultiMediaThemeInfo = (state: GlobalState) => state.multiMedia.multiMediaThemeInfo;
// for local demo
// export const useMultiMediaThemeInfo = () => useRedux(selectMultiMediaThemeInfo, {});

const themeInfoActionMap = { getMultiMediaThemeInfo, clearMultiMediaThemeList };
export const useMultiMediaThemeInfo = () => useRedux(selectMultiMediaThemeInfo, themeInfoActionMap);

/* +----------------------------------------------------------------------
++ useMultiMediaCategory ++
++----------------------------------------------------------------------*/

const selectMultiMediaCategory = (state: GlobalState) => state.multiMedia.multiMediaCategory;
// for local demo
// export const useMultiMediaCategory = () => useRedux(selectMultiMediaCategory, {});

const categoryActionMap = { getMultiMediaCategory };
export const useMultiMediaCategory = () => useRedux(selectMultiMediaCategory, categoryActionMap);

/* +----------------------------------------------------------------------
++ useMultiMediaEpisodeInfo ++
++----------------------------------------------------------------------*/
const selectMultiMediaEpisodeInfo = (state: GlobalState) => state.multiMedia.episodeInfo;
// for local demo
// export const useMultiMediaEpisodeInfo = () => useRedux(selectMultiMediaEpisodeInfo, {});

const episodeInfoActionMap = { getMultiMediaEpisodeInfo };
export const useMultiMediaEpisodeInfo = () =>
	useRedux(selectMultiMediaEpisodeInfo, episodeInfoActionMap);
