import { createBrowserHistory, To } from 'history';

const originHistory = createBrowserHistory();

export const baseUrl = process.env.DEPLOY_ENV === 'page' ? '/gacc/web' : '';

const history = {
	...originHistory,
	push: (to: To, state?: string) => {
		if (typeof to === 'string') {
			originHistory.push(`${baseUrl}${to}`, state);

			return;
		}

		originHistory.push(
			{
				...to,
				pathname: `${baseUrl}${to.pathname}`,
			},
			state,
		);
	},
};

export default history;
