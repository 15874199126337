import { createAction, handleActions, Action } from 'redux-actions';
import { createSelector } from 'reselect';
import { Dispatch } from 'redux';

import { useRedux } from 'util/hook/redux';
import { wrapAuthFetch } from 'util/api';

import { FormItemProperty, BirthdayProperty, FormBooleanItem } from 'models/auth/type';

import { openModal, TOAST_TYPE, MODAL_CATEGORY } from './modal';

import { GetState, State as GlobalState } from './reducers';

export type UserPayload = State['data'];

export interface UserInfoPayload {
	name?: string;
	email?: string;
	gender?: string;
	birthday?: string;
	phone?: string;
	tel?: string;
	county?: string;
	district?: string;
	address?: string;
}

export interface MemberFormItemPayload {
	key: string;
	data: FormItemProperty;
}

export const updateMemberInfoForm = createAction(
	'UPDATE_MEMBER_INFO_FORM',
	({ key, data }: MemberFormItemPayload) => ({
		key,
		data,
	}),
);

export const clearMemberInfoForm = createAction('CLEAR_MEMBER_INFO_FORM', () => ({
	data: defaultMemberInfoForm,
}));

export const getUser = createAction<Promise<UserPayload>>('GET_USER', async () => {
	const { data, status } = await wrapAuthFetch('me', {
		method: 'GET',
	});

	if (status !== 200 && status !== 201) {
		return defaultState.data;
	}
	
	return data.data;
});

/**
 * email 驗證
 */
export const emailVerification = createAction(
	'EMAIL_VERIFICATION',
	() => async (dispatch: Dispatch) => {
		const { status, message } = await wrapAuthFetch('email/verification-notification', {
			method: 'POST',
		});

		if (status !== 200 && status !== 201) {
			dispatch(
				openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.ERROR, data: message }),
			);
		}

		dispatch(
			openModal({
				category: MODAL_CATEGORY.TOAST,
				type: TOAST_TYPE.SUCCESS,
				data: '驗證信已發送到您的信箱，請點擊信中連結完成驗證',
			}),
		);
	},
);

const generateUpdateUserData = (form: MemberInfoFormProperty, originalData: State['data']) => ({
	name: form.name.value === '' ? originalData.name : form.name.value,
	email: form.email.value === '' ? originalData.email : form.email.value,
	gender: form.gender.value === '' ? originalData.gender : form.gender.value,
	birthday:
		`${form.birthday.year}-${form.birthday.month}-${form.birthday.day}` === '--'
			? originalData.birthday
			: `${form.birthday.year}-${form.birthday.month}-${form.birthday.day}`,
	dialing_code: form.dialingCode.value === '' ? originalData.dialingCode : form.dialingCode.value.replace('+', ''),
	phone: form.phone.value === '' ? originalData.phone : form.phone.value,
	tel: form.tel.value === '' ? originalData.tel : form.tel.value,
	county: form.county.value === '' ? originalData.county : form.county.value,
	district: form.district.value === '' ? originalData.district : form.district.value,
	address: form.address.value === '' ? originalData.address : form.address.value,
});

/**
 * 使用者資料修改
 */
export const updateMemberInfo = createAction(
	'UPDATE_MEMBER_INFO',
	({ type = 'default', closeModal = () => {}, handleVerifiedMailAction = () => {} }) =>
		async (dispatch: Dispatch, getState: GetState): Promise<null> => {
			const {
				user: { data, memberInfoForm },
			} = getState();

			const updateUserData = generateUpdateUserData(memberInfoForm, data);
			
			const { status, message, extra } = await wrapAuthFetch('me', {
				method: 'PUT',
				body: JSON.stringify(updateUserData),
			});

			if (status !== 200 && status !== 201) {
				if (extra.email[0] === 'The email has already been taken.') {
					dispatch(
						updateMemberInfoForm({
							key: 'email',
							data: {
								value: memberInfoForm.email.value,
								error: '此電子信箱已被註冊，請改用另一組電子信箱',
							},
						}),
					);
					throw new Error('此電子信箱已被註冊，請改用另一組電子信箱');
				}
				dispatch(
					openModal({
						category: MODAL_CATEGORY.TOAST,
						type: TOAST_TYPE.WARNING,
						data: message,
					}),
				);
				throw new Error(message);
			}

			if (type === 'email') {
				handleVerifiedMailAction();
			  dispatch(emailVerification());
			} else {
				await dispatch(clearMemberInfoForm());
				await closeModal();
			}
			
			await dispatch(
				openModal({
					category: MODAL_CATEGORY.TOAST,
					type: TOAST_TYPE.SUCCESS,
					data: '會員資料修改成功',
				}),
			);
			await dispatch(getUser());
			return null;
		},
);

/**
 * 使用者密碼修改
 */
export const changePassword = createAction(
	'CHANGE_PASSWORD',
	(closeModal = () => {}) =>
		async (dispatch: Dispatch, getState: GetState): Promise<null> => {
			const {
				user: { memberInfoForm },
			} = getState();

			const { status, message } = await wrapAuthFetch('change-password', {
				method: 'PUT',
				body: JSON.stringify({
					old_password: memberInfoForm.oldPassword.value,
					new_password: memberInfoForm.newPassword.value,
					new_password_confirmation: memberInfoForm.newPasswordConfirmation.value,
				}),
			});

			if (status !== 200 && status !== 201) {
				if (message === 'old password is incorrect') {
					dispatch(
						updateMemberInfoForm({
							key: 'oldPassword',
							data: {
								value: memberInfoForm.oldPassword.value,
								error: '舊密碼輸入不正確',
							},
						}),
					);
					throw new Error('舊密碼輸入不正確');
				}
				throw new Error(message);
			}

			await dispatch(clearMemberInfoForm());
			await closeModal();
			await dispatch(
				openModal({
					category: MODAL_CATEGORY.TOAST,
					type: TOAST_TYPE.SUCCESS,
					data: '會員資料修改成功',
				}),
			);
			await dispatch(getUser());
			return null;
		},
);

export interface State {
	loading: boolean;
	data: {
		id: number;
		name: string;
		email: string;
		gender: string;
		birthday: string;
		phone: string;
		tel: string;
		address: string;
		county: string;
		dialingCode: string;
		district: string;
		isSuspended: boolean;
		isEmailVerified: boolean;
		isGoogleLink: boolean;
		isFacebookLink: boolean;
	};
	memberInfoForm: MemberInfoFormProperty;
}

export const defaultMemberInfoForm = {
	name: { value: '', error: '' },
	email: { value: '', error: '' },
	oldPassword: { value: '', error: '' },
	newPassword: { value: '', error: '' },
	newPasswordConfirmation: { value: '', error: '' },
	gender: { value: '', error: '' },
	birthday: { year: '', month: '', day: '', error: '' },
	dialingCode: { value: '+886', error: '' },
	phone: { value: '', error: '' },
	tel: { value: '', error: '' },
	county: { value: '', error: '' },
	district: { value: '', error: '' },
	address: { value: '', error: '' },
	isEmailVerified: { value: false, error: '' },
	isGoogleLink: { value: false, error: '' },
	isFacebookLink: { value: false, error: '' },
};

export interface MemberInfoFormProperty {
	name: FormItemProperty;
	email: FormItemProperty;
	oldPassword: FormItemProperty;
	newPassword: FormItemProperty;
	newPasswordConfirmation: FormItemProperty;
	gender: FormItemProperty;
	birthday: BirthdayProperty;
	dialingCode: FormItemProperty;
	phone: FormItemProperty;
	tel: FormItemProperty;
	district: FormItemProperty;
	county: FormItemProperty;
	address: FormItemProperty;
	isEmailVerified: FormBooleanItem;
	isGoogleLink: FormBooleanItem;
	isFacebookLink: FormBooleanItem;
	[key: string]: FormItemProperty | BirthdayProperty | FormBooleanItem;
}

export const defaultState: State = {
	loading: false,
	data: {
		id: 0,
		name: '',
		email: '',
		gender: '',
		birthday: '',
		dialingCode: '',
		phone: '',
		tel: '',
		district: '',
		county: '',
		address: '',
		isSuspended: false,
		isEmailVerified: false,
		isGoogleLink: false,
		isFacebookLink: false,
	},
	memberInfoForm: defaultMemberInfoForm,
};

export const reducer = {
	user: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_USER_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_USER_FULFILLED: (state, action: Action<UserPayload>) => ({
				...state,
				data: action.payload,
				loading: false,
			}),

			UPDATE_MEMBER_INFO_FORM: (state, action: Action<MemberFormItemPayload>) => ({
				...state,
				memberInfoForm: {
					...state.memberInfoForm,
					[action.payload.key]: {
						...state.memberInfoForm[action.payload.key],
						...action.payload.data,
					},
				},
			}),

			CLEAR_MEMBER_INFO_FORM: (state, action) => ({
				...state,
				memberInfoForm: {
					...action.payload.data,
				},
			}),
		},

		defaultState,
	),
};

export const selectUser = createSelector(
	(state: GlobalState) => state.user.data,
	(state: GlobalState) => state.user.memberInfoForm,
	(data, memberInfoForm) => ({
		data,
		memberInfoForm,
	}),
);

const userActionsMap = {
	getUser,
	emailVerification,
	updateMemberInfo,
	updateMemberInfoForm,
	changePassword,
	clearMemberInfoForm,
};

export const useUser = () => useRedux(selectUser, userActionsMap);
