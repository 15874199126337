import React, { useEffect } from 'react';
import classnames from 'classnames';

import { useModal, MODAL_CATEGORY } from 'models/modal';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'util/hook';

import { isExist } from 'util/helper';

import Success from 'images/icon/general/toast-success.inline.svg';
import Warning from 'images/icon/general/toast-warning.inline.svg';
import Error from 'images/icon/general/toast-error.inline.svg';
import Icon from '../Icon';

import styles from './index.module.css';

const Toast: React.FC = () => {
	const [showToast, { setFalse, setTrue }] = useBoolean({ defaultBoolean: false });
	const [
		{
			modal: {
				toast: { type, data, i18n },
			},
		},
		{ closeModal },
	] = useModal();

	const { t } = useTranslation(['toast', i18n]);

	useEffect(() => {
		if (isExist(type)) {
			setTrue();
			setTimeout(() => setFalse(), 3000);
			setTimeout(() => closeModal(MODAL_CATEGORY.TOAST), 3500);
		}
	}, [type]);

	return (
		<div
			className={classnames(styles.toast, {
				[styles.open]: showToast,
				[styles.close]: !showToast,
			})}
		>
			<div className={classnames(styles.toastContainer, {
				[styles.success]: type === 'success',
				[styles.warning]: type === 'warning',
				[styles.error]: type === 'error',
			})}>
				{type === 'success' && <Icon src={Success} />}
				{type === 'warning' && <Icon src={Warning} />}
				{type === 'error' && <Icon src={Error} />}
				<div className={styles.text}>
					{i18n ? t(`${i18n}:${data}`) : data}
				</div>
			</div>
		</div>
	);
};

export default Toast;
