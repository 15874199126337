import { useState, useEffect } from 'react';

import { SelectValue } from 'types/SelectValue';

interface Option {
	onTrue?: () => void;
	onFalse?: () => void;
	defaultBoolean?: boolean;
}

type useBooleanFunc = (
	options: Option,
) => [boolean, { toggle: () => void; setFalse: () => void; setTrue: () => void }];

export const useBoolean: useBooleanFunc = options => {
	const { onTrue = () => { }, onFalse = () => { }, defaultBoolean = false } = options;

	const [state, setState] = useState<boolean>(defaultBoolean);

	const toggle = () => {
		if (state) {
			onFalse();
		} else {
			onTrue();
		}
		setState(!state);
	};

	const setFalse = () => {
		setState(false);
		onFalse();
	};

	const setTrue = () => {
		setState(true);
		onTrue();
	};

	return [state, { toggle, setFalse, setTrue }];
};

type useSelectParams<T> = {
	defaultValue?: T[];
	propsValue?: T[];
	onChange?: (v: T[]) => void;
};

export const useSelect = <T = SelectValue>({
	defaultValue = [],
	propsValue = [],
	onChange = () => { },
}: useSelectParams<T>): [T[], (v: T[]) => void] => {
	const [value, setState] = useState<T[]>(defaultValue);

	const onChangeValue = (v: T[]) => {
		setState(v);
		onChange(v);
	};

	useEffect(() => {
		if (propsValue) {
			setState(propsValue);
		}
	}, [propsValue]);

	return [value, onChangeValue];
};