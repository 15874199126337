import React, { useState, useRef, MouseEvent } from 'react';
import classnames from 'classnames';
import i18n from 'i18next';

import { useHistory } from 'models/routing';
import { useMedia } from 'util/event';
import { useBoolean } from 'util/hook';
import { useGlobal } from 'models/global';
import { isExist } from 'util/helper';

import searchIcon from 'images/icon/general/search.inline.svg';
import Input from '../Input';
import Button from '../Button';
import Icon from '../Icon';
import styles from './index.module.css';

const Search: React.FC = () => {
	const device = useMedia();
	const history = useHistory();
	const contentRef = useRef<HTMLDivElement>(null);
	const [value, setValue] = useState('');
	const [openSearchField, { setFalse, toggle }] = useBoolean({ defaultBoolean: false });
	const [{ isNavOpen }, { setNavClose }] = useGlobal();

	const handleClickSearch = () => {
		if (isNavOpen) {
			setNavClose();
		}
		toggle();
	};

	const outsideClick: (e: MouseEvent) => void = e => {
		if (openSearchField && contentRef.current && !contentRef.current.contains(e.target as Node)) {
			setFalse();
		}
	};

	const onSearch: () => void = () => {
		if (isExist(value)) {
			history.push(`/${i18n.language}/search?q=${value}`, '_self');
			history.go(0);
		}
	};

	return (
		<div className={styles.search}>
			<Icon className={styles.icon} src={searchIcon} onClick={handleClickSearch} />
			<div
				className={classnames(styles.searchWrapper, {
					[styles.open]: openSearchField,
					[styles.close]: !openSearchField,
				})}
				onClick={outsideClick}
				role="presentation"
			>
				<div ref={contentRef} className={styles.container}>
					<div className={styles.searchInput}>
						<Input
							className={styles.input}
							value={value}
							placeholder="請輸入要搜尋的內容"
							onChange={e => setValue(e.target.value)}
						/>
						<Button
							className={styles.button}
							Icon={searchIcon}
							size={device === 'mobile' ? 'medium' : 'large'}
							onClick={onSearch}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Search;
