import { createAction, handleActions, Action } from 'redux-actions';
import { createSelector } from 'reselect';
import { Dispatch } from 'redux';
import { useRedux } from 'util/hook/redux';
import { SponsorUsCategory, SponsorUsProductStatus } from 'types/SponsorUs';

import storage from 'util/storage';
import { wrapAuthFetch } from 'util/api';
import { isExist } from 'util/helper';
import { openModal, TOAST_TYPE, MODAL_CATEGORY } from './modal';
import { GetState, State as GlobalState } from './reducers';

export interface ProductInfo {
	id: number;
	name: string;
	price: number;
	quantity: number;
	subscribeBeginIssue: {
		id: number | null;
		text: string;
	};
	backIssues: {
		id: number[];
		text: string;
	};
	spec: {
		specId: number | null;
		giftId: number | null;
		text: string | undefined;
	};
	category: string | SponsorUsCategory;
	status: string | SponsorUsProductStatus;
	mainImage: string;
	sponsorshipPlanId: number;
}

export interface FountainInfoFromBE {
	startingIssue: string;
	backIssue: string;
}

export interface FountainInfoToBE {
	startingIssue: number;
	backIssue: number[];
}

export interface ProductPayload {
	id: number;
	specId?: number;
	specLabel?: string;
	gift: string;
	fountainExtraInfo?: FountainInfoToBE;
	quantity: number; //  贊助數量
}
export type CartPayload = Pick<State, 'products' | 'grandTotal'>;

/**
 * 取得購物車資料
 * 
 * Note：
 * 每一次更新購物車後都會跟後端拿一次最新資料
 * 文總購物車資料會紀錄在後端，所以不用另外存進 localStorage
 */
export const getCartData = createAction<Promise<CartPayload>>(
	'GET_CART_DATA',
	async () => {
		const { status, data } = await wrapAuthFetch('shopping-cart', {
			method: 'GET',
		});

		if (status !== 200 && status !== 201) {
			return { products: defaultState.products, grandTotal: 0 };
		}

		return {
			products: data.data,
			grandTotal: isExist(data.grandTotal) ? data.grandTotal : 0,
		};
	},
);

/**
 * 新增商品至購物車
 * 
 * Note.
 * 資料從 model/product.selectProduct 裡面拿
 * 直接打新增商品 API 給後端
 */
const addCartProduct = createAction<(dispatch: Dispatch, getState: GetState) => Promise<void>>(
	'ADD_CART_PRODUCT',
() => async (dispatch, getState) => {
	const {
		product: {
			selectProduct,
		}
	} = getState();

	if (selectProduct.id.value === -1) {
		return undefined;
	}

	const payload = {
		id: selectProduct.id.value,
		quantity: selectProduct.quantity.value,
		spec_id: selectProduct.specId.value !== -1 ? selectProduct.specId.value : null,
		fountain_extra_info: {
			subscribe_begin_id: selectProduct.subscribeBeginId.value !== -1 ? selectProduct.subscribeBeginId.value : null,
			back_issues: selectProduct.backIssues.value.length !== 0 ? selectProduct.backIssues.value : [],
			gift_id: selectProduct.giftId.value !== -1 && selectProduct.giftId.value !== 0
				? selectProduct.giftId.value : null,
		},
	};

	const { status, message } = await wrapAuthFetch('shopping-cart', {
		method: 'POST',
		body: JSON.stringify(payload),
	});

	if (status !== 200 && status !== 201) {
		dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.ERROR, data: message }));
		return undefined;
	}

	dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.SUCCESS, data: '成功加入贊助方案' }));
	return undefined;
});

export const addCartProductProcess = createAction<(dispatch: Dispatch) => Promise<void>>(
	'ADD_CART_PRODUCT_PROCESS',
() => async dispatch => {
	await dispatch(addCartProduct());
	dispatch(getCartData());
});

/**
 * 刪除購物車商品
 * 
 * Note.
 * 直接打刪除商品 API 給後端
 * 如果有帶 id 會刪除該項商品，如果沒帶 id，會清除全部商品
 */
const deleteCartProduct = createAction<(dispatch: Dispatch) => Promise<void>, number | void>(
	'DELETE_CART_PRODUCT',
id => async dispatch => {
	const { status, message } = await wrapAuthFetch(
		`shopping-cart${isExist(id) ? `/${id}` : ''}`,
		{
			method: 'DELETE',
		},
	);

	if (status !== 200 && status !== 201) {
		dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.ERROR, data: message }));
		return undefined;
	}

	return undefined;
});

export const deleteCartProductProcess = createAction<(dispatch: Dispatch) => Promise<void>, number | void>(
	'DELETE_CART_PRODUCT_PROCESS',
id => async dispatch => {
	await dispatch(deleteCartProduct(id));
	dispatch(getCartData());
});

export const setSingleProduct = createAction<(dispatch: Dispatch, getState: GetState) => Promise<ProductInfo>>(
	'SET_SINGLE_PRODUCT',
() => async (_dispatch, getState) => {
	const {
		product: {
			productInfo,
			selectProduct,
		}
	} = getState();

	const selectSubscribeBeginId = selectProduct.subscribeBeginId.value !== -1 ? selectProduct.subscribeBeginId.value : null;

	// 判斷開始訂閱的期數。如果是從‘下一期’開始訂閱，則相關資料在 nextFountain，不在 fountainList
	let subscribeBeginText = '';
	if (selectSubscribeBeginId === productInfo.fountainList[0].id) {
		subscribeBeginText = `${productInfo.fountainList[0].publishDate} 第 ${productInfo.fountainList[0].session} 期`;
	} else if (selectSubscribeBeginId === productInfo.nextFountain[0].id) {
		subscribeBeginText = `${productInfo.nextFountain[0].publishDate} 第 ${productInfo.nextFountain[0].session} 期`;
	}

	const specName = productInfo.gifts.find(s => s.id === selectProduct.giftId.value)?.name || '';

	// 回饋品可能有分規格也可能沒有。規格的部分在 specId，贈品名稱在 giftId
	let specText = '';
	if (selectProduct.specId.value !== -1) {
		specText = `${productInfo.specs.find(s => s.id === selectProduct.specId.value)?.spec}、${specName}`
	} else if (selectProduct.giftId.value !== -1 && selectProduct.giftId.value !== 0) {
		specText = `${productInfo.gifts.find(s => s.id === selectProduct.giftId.value)?.name}`
	}
	
	return [{
		id: 0,
		name: productInfo.name,
		price: productInfo.price * selectProduct.quantity.value,
		quantity: selectProduct.quantity.value,
		subscribeBeginIssue: {
			id: selectSubscribeBeginId,
			text: subscribeBeginText,
		},
		backIssues: {
			id: selectProduct.backIssues.value.length !== 0 ? selectProduct.backIssues.value : [],
			text: selectProduct.backIssues.value.length !== 0 ? selectProduct.backIssues.value.map(b => `
				${productInfo.fountainList.find(f => f.id === b)?.publishDate}
				第 ${productInfo.fountainList.find(f => f.id === b)?.session} 期
			`).join('、') : '',
		},
		spec: {
			specId: selectProduct.specId.value !== -1 ? selectProduct.specId.value : null,
			giftId: selectProduct.giftId.value !== -1 && selectProduct.giftId.value !== 0
				? selectProduct.giftId.value : null,
			text: specText,
		},
		category: productInfo.category,
		status: productInfo.status,
		mainImage: productInfo.mainImage,
		sponsorshipPlanId: productInfo.id,
	}];
});

export const getSingleProductFromStorage = createAction<ProductInfo, string>(
	'GET_SINGLE_PRODUCT_FROM_STORAGE',
	orderNo => JSON.parse(storage.getItem(`order-${orderNo}`) as string)?.singleProduct,
)

export const goToStage = createAction<State['stage'], State['stage']>('GO_TO_STAGE', stage => stage);
export const clearCartData = createAction('CLEAR_CART_DATA');
export const clearSingleProduct = createAction('CLEAR_SINGLE_PRODUCT');

export interface State {
	loading: boolean;
	singleProduct: ProductInfo[] | null;
	products: ProductInfo[];
	grandTotal: number;
	stage: 1 | 2 | 3;
}

export const defaultState: State = {
	loading: false,
	singleProduct: null, // singleProduct 代表單一商品結帳，它的優先權大於所有商品結帳（只要 singleProduct 有資料，結帳就只會結 singleProduct 這筆資料）
	products: [],
	grandTotal: 5000,
	stage: 1, // 購物車(list)是 1，填寫訂單資訊(checkout)是２，贊助成功變 3（贊助沒成功保留在 2）
};

export const reducer = {
	cart: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_CART_DATA_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_CART_DATA_FULFILLED: (state, action: Action<CartPayload>) => ({
				...state,
				products: action.payload.products,
				grandTotal: action.payload.grandTotal,
				loading: false,
			}),

			CLEAR_CART_DATA: state => ({
				...state,
				products: defaultState.products,
				grandTotal: defaultState.grandTotal,
			}),

			SET_SINGLE_PRODUCT_FULFILLED: (state, action: Action<ProductInfo[]>) => ({
				...state,
				singleProduct: action.payload,
			}),

			CLEAR_SINGLE_PRODUCT: state => ({
				...state,
				singleProduct: null,
			}),

			GET_SINGLE_PRODUCT_FROM_STORAGE: (state, action: Action<ProductInfo[]>) => ({
				...state,
				singleProduct: action.payload,
			}),

			GO_TO_STAGE: (state, action: Action<State['stage']>) => ({
				...state,
				stage: action.payload,
			}),
		},
		defaultState
	),
};

export const selectCart = createSelector(
	(state: GlobalState) => state.cart.stage,
	(state: GlobalState) => state.cart.singleProduct,
	(state: GlobalState) => state.cart.products,
	(state: GlobalState) => state.cart.grandTotal,
	(stage, singleProduct, products, grandTotal) => ({
		stage,
		grandTotal,
		singleProduct,
		products,
	}),
);

export const useCart = () => useRedux(selectCart, { getCartData, addCartProductProcess, deleteCartProductProcess, setSingleProduct, goToStage, clearSingleProduct });
