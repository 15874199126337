/* eslint-disable no-shadow */
export interface FormItemProperty {
	value: HTMLInputElement['value'];
	error: string;
}

export interface BirthdayProperty {
	year: HTMLInputElement['value'];
	month: HTMLInputElement['value'];
	day: HTMLInputElement['value'];
	error: string;
}

export interface FormBooleanItem {
	value: boolean;
	error: string;
}

export interface FormItemPayload {
	key: string;
	value: HTMLInputElement['value'] | boolean;
	error?: string;
}

export interface SignUpFormItemPayload {
	key: string;
	value: HTMLInputElement['value'];
	data: Date;
	error?: string;
}

export enum LOGIN_TYPE {
	NORMAL = 'NORMAL',
	GOOGLE = 'GOOGLE',
	FACEBOOK = 'FACEBOOK',
}

export interface LoginProcessProperty {
	type: LOGIN_TYPE;
	email?: string;
	password?: string;
	IDtoken?: string; // Google Encoded JWT ID token
}

export interface SignUpProperty {
	name: string;
	email: string;
	password: string;
	passwordConfirmation: string;
	gender: string;
	birthday: string;
	dialCode: string;
	phone: string;
	tel: string;
	county: string;
	district: string;
	address: string;
}

export interface ResetPasswordProperty {
	token: string;
	email: string;
}

export interface LoginFormProperty {
	email: FormItemProperty;
	password: FormItemProperty;
	remember: FormBooleanItem;
	[key: string]: FormItemProperty | FormBooleanItem;
}
export interface SignUpFormProperty {
	name: FormItemProperty;
	email: FormItemProperty;
	password: FormItemProperty;
	passwordConfirmation: FormItemProperty;
	gender: FormItemProperty;
	birthday: BirthdayProperty;
	dialCode: FormItemProperty;
	phone: FormItemProperty;
	tel: FormItemProperty;
	county: FormItemProperty;
	district: FormItemProperty;
	address: FormItemProperty;
	[key: string]: FormItemProperty | BirthdayProperty | FormBooleanItem;
}

export interface ForgotPasswordFormProperty {
	email: FormItemProperty;
	[key: string]: FormItemProperty | FormBooleanItem;
}

export interface ResetPasswordFormProperty {
	newPassword: FormItemProperty;
	confirmPassword: FormItemProperty;
	[key: string]: FormItemProperty;
}
