import { createAction, handleActions, Action } from 'redux-actions';
import { createSelector } from 'reselect';
import { useRedux } from 'util/hook/redux';

import { wrapFetch } from 'util/api';
import { SponsorUsProductStatus, SponsorUsCategory, SponsorUsGiftType } from 'types/SponsorUs';

import { State as GlobalState } from './reducers';

export interface Product {
	id: number;
	name: string;
	price: number;
	category: SponsorUsCategory;
	mainImage: string;
	description: string;
	status: SponsorUsProductStatus;
}
export interface ProductInfo extends Product {
	content: string;
	isSubscription: boolean;
	backIssueNum: number;
	giftType: SponsorUsGiftType;
	gifts: ProductGift[];
	images: ProductImage[];
	specs: ProductSpec[];
	fountainList: Fountain[];
	nextFountain: Fountain[];
}

export interface ProductsData {
	data: Product[];
}

export interface ProductImage {
	id: number;
	name: string;
	url: string;
}

export interface ProductGift {
	id: number;
	name: string;
}

export interface ProductSpec {
	id: number;
	spec: string;
}

export interface Fountain {
	id: number;
	image: string;
	session: number;
	publishDate: string;
	title: string;
	stockStatus: string;
}

export interface SelectProductForm {
	id: { value: number, error: string };
	specId: { value: number, error: string };
	subscribeBeginId: { value: number, error: string };
	backIssues: { value: number[], error: string };
	giftId: { value: number, error: string };
	quantity: { value: number, error: string };
}
export interface SelectProductFormPayload {
	key: string;
	value: string | number;
	error?: string;
}

/**
 * 取得贊助方案清單
 */
export const getProducts = createAction<Promise<ProductsData>>(
	'GET_PRODUCTS',
	async () => {
		const { status, data } = await wrapFetch(
			`sponsorship-plans`,
			{
				method: 'GET',
			},
		);

		if (status !== 200 && status !== 201) {
			return defaultState.productsData;
		}

		return {
			data: data.data,
		};
	}
);

/**
 * 取得贊助方案資訊
 */
export const getProductInfo = createAction<Promise<ProductInfo>, string>(
	'GET_PRODUCT_INFO',
	async id => {
		const { status, data } = await wrapFetch(
			`sponsorship-plans/${id}`,
			{
				method: 'GET',
			},
		);

		if (status !== 200 && status !== 201) {
			return defaultState.productInfo;
		}

		return data.data;
	},
);

type productInfoData = {
	data: ProductInfo
}

export const setProductInfoPreview = createAction('SET_PRODUCT_INFO_PREVIEW', (data: productInfoData) => ({
	...data,
}));

/**
 * 選擇商品規格
 */
export const selectProductSpecification = createAction<SelectProductFormPayload, SelectProductFormPayload>(
	'SELECT_PRODUCT_SPECIFICATION', ({ key, value, error }) => ({ key, value, error }));

export const clearProductInfo = createAction('CLEAR_PRODUCT_INFO');
export const clearProductSpecification = createAction('CLEAR_PRODUCT_SPECIFICATION');

export interface State {
	productsData: ProductsData;
	selectProduct: SelectProductForm;
	productInfo: ProductInfo;
	loading: boolean;
}

export const defaultState: State = {
	loading: false,
	selectProduct: {
		id: { value: -1, error: '' },
		specId: { value: -1, error: '' },
		subscribeBeginId: { value: -1, error: '' },
		backIssues: { value: [], error: '' },
		giftId: { value: -1, error: '' },
		quantity: { value: 1, error: '' },
	},
	productsData: {
		data: [],
	},
	productInfo: {
		id: 0,
		name: '',
		description: '',
		price: 0,
		mainImage: '',
		content: '',
		isSubscription: false,
		backIssueNum: 0,
		category: 'FOUNTAIN',
		status: 'AVAILABLE',
		giftType: 'NONE',
		gifts: [],
		images: [],
		specs: [],
		fountainList: [],
		nextFountain:[],
	},
};

export const reducer = {
	product: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_PRODUCTS_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_PRODUCTS_FULFILLED: (state, action: Action<ProductsData>) => ({
				...state,
				productsData: action.payload,
				loading: false,
			}),

			GET_PRODUCT_INFO_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_PRODUCT_INFO_FULFILLED: (state, action: Action<ProductInfo>) => ({
				...state,
				productInfo: action.payload,
				loading: false,
			}),

			SET_PRODUCT_INFO_PREVIEW: (state, action) => ({
				...state,
				productInfo: {
					...state.productInfo,
					...action.payload
				},
			}),

			SELECT_PRODUCT_SPECIFICATION: (state, action: Action<SelectProductFormPayload>) => ({
				...state,
				selectProduct: {
					...state.selectProduct,
					[action.payload.key]: {
						...state.selectProduct[action.payload.key as keyof SelectProductForm],
						value: action.payload.value,
						error: action.payload.error,
					},
				},
			}),

			CLEAR_PRODUCT_INFO: state => ({
				...state,
				productInfo: defaultState.productInfo,
			}),

			CLEAR_PRODUCT_SPECIFICATION: state => ({
				...state,
				selectProduct: defaultState.selectProduct,
			})
		},
		defaultState,
	),
};


export const mapProductToHook = createSelector(
	(state: GlobalState) => state.product.productInfo,
	(state: GlobalState) => state.product.productsData.data,
	(state: GlobalState) => state.product.selectProduct,
	(productInfo, productsData, selectProduct) => ({ productInfo, productsData, selectProduct }),
);

export const useProduct = () => useRedux(mapProductToHook, { getProducts, getProductInfo, selectProductSpecification });
