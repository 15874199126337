/* eslint-disable camelcase */

import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';

import { State as GlobalState } from './reducers';


interface PublicationItemProperty {
	id: number;
	name: string;
	cover: string;
	category: string;
}

type PublicationListItemPayload = Pick<State['publicationList'], 'data'>;

export const getPublicationList = createAction<Promise<PublicationListItemPayload>>(
	'GET_PUBLICATION_LIST',
	async () => {
		try {
			const { status, message, data } = await wrapFetch('publications', {
				method: 'GET',
			});

			if (status !== 200) {
				throw new Error(message);
			}

			return data;
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

export const clearPublicationList = createAction('CLEAR_PUBLICATION_LIST');

export interface State {
	publicationList: {
		loading: boolean;
		error: string;
		data: PublicationItemProperty[];
	};
}

export const defaultState: State = {
	publicationList: {
		loading: false,
		error: '',
		data: [],
	},
};

export const reducer = {
	publication: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_PUBLICATION_LIST_PENDING: state => ({
				...state,
				publicationList: {
					...state.publicationList,
					loading: true,
					error: '',
				},
			}),

			GET_PUBLICATION_LIST_FULFILLED: (state, action: Action<PublicationListItemPayload>) => ({
				...state,
				publicationList: {
					...state.publicationList,
					...action.payload,
					loading: false,
					data: [...state.publicationList.data, ...action.payload.data],
				},
			}),

			GET_PUBLICATION_LIST_REJECTED: (state, action) => ({
				...state,
				publicationList: {
					...state.publicationList,
					loading: false,
					error: action.payload.message,
				},
			}),
			CLEAR_PUBLICATION_LIST: () => defaultState,
		},
		defaultState,
	), // eslint-disable-line @typescript-eslint/no-explicit-any
};

/* +----------------------------------------------------------------------
++ usePublicationList ++
++----------------------------------------------------------------------*/

const selectPublicationList = (state: GlobalState) => state.publication.publicationList;
// for local demo
// export const usePublicationList = () => useRedux(selectPublicationList, {});

const publicationListActionMap = { getPublicationList, clearPublicationList };
export const usePublicationList = () => useRedux(selectPublicationList, publicationListActionMap);
