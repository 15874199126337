import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';

import { State as GlobalState } from './reducers';

export interface CampusAmbassadorItem {
  title: string;
  description: string;
  mainCatButton: {
    text: string;
    link: string;
  };
  subCatButton: {
    text: string;
    link: string;
  };
  banner: { id: number; imagePath: string }[];
  pictures: { id: number; imagePath: string }[];
}
// 歷屆
interface CampusAmbassadorEdition {
	data: {
		edition: number;
	};
}

// 學生
interface StudentAmbassadorItem {
	id: number;
	name: string;
	description: string;
	image: string;
	collegeName: string;
	collegeMajor: string;
	edition: number;
  }

type campusAmbassadorListItemPayload = Pick<State['campusAmbassadorList'], 'data'>;
export const getCampusAmbassadorList = createAction<Promise<campusAmbassadorListItemPayload>>(
	'GET_CAMPUSAMBASSADOR_LIST',
	async () => {
		try {
			const { status, message, data } = await wrapFetch('student_ambassadors/page', {
				method: 'GET',
			});

			if (status !== 200) {
				throw new Error(message);
			}

			return data;
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

export const getCampusAmbassadorEdition = createAction<Promise<CampusAmbassadorEdition>>(
	'GET_CAMPUSAMBASSADOR_EDITION',
	async () => {
	  try {
			const { status, message, data } = await wrapFetch('student_ambassadors/edition', {
		  method: 'GET',
			});
  
			if (status !== 200) {
		  throw new Error(message);
			}
  
			return data;
	  } catch (error) {
			throw new Error((error as Error).message);
	  }
	},
);

export const getStudentAmbassadorList = createAction<Promise<{ data: StudentAmbassadorItem[] }>>(
	'GET_STUDENT_AMBASSADOR_LIST',
	async () => {
	  	try {
			const { status, message, data } = await wrapFetch('student_ambassadors', {
				method: 'GET',
			});
  
			if (status !== 200) {
		  		throw new Error(message);
			}
  
			return data;
	  	} catch (error) {
			throw new Error((error as Error).message);
	  	}
	},
);


export interface State {
	campusAmbassadorList: {
		loading: boolean;
		error: string;
		data: CampusAmbassadorItem;
	};
	campusAmbassadorEdition: {
		loading: boolean;
		error: string;
		data: CampusAmbassadorEdition;
	};
	studentAmbassadorList: {
		loading: boolean;
		error: string;
		data: StudentAmbassadorItem[];
	};
}

export const defaultState: State = {
	campusAmbassadorList: {
		loading: false,
		error: '',
		data: undefined as unknown as CampusAmbassadorItem, 
	},
	campusAmbassadorEdition: {
		loading: false,
		error: '',
		data: undefined as unknown as CampusAmbassadorEdition, 
	},
	studentAmbassadorList: {
		loading: false,
		error: '',
		data: [],
	},
};

export const reducer = {
	campusambassador: handleActions<State, any>(
	  {
			GET_CAMPUSAMBASSADOR_LIST_FULFILLED: (state, action: Action<campusAmbassadorListItemPayload>) => ({
		  ...state,
		  campusAmbassadorList: {
					...state.campusAmbassadorList,
					loading: false,
					data: action.payload.data || state.campusAmbassadorList.data,
					error: ''
		  },
			}),
			GET_CAMPUSAMBASSADOR_EDITION_FULFILLED: (state, action: Action<CampusAmbassadorEdition>) => ({
		  ...state,
		  campusAmbassadorEdition: {
					...state.campusAmbassadorEdition,
					loading: false,
					data: action.payload || state.campusAmbassadorEdition.data,
					error: ''
		  },
			}),
			GET_STUDENT_AMBASSADOR_LIST_FULFILLED: (state, action: Action<{ data: StudentAmbassadorItem[] }>) => ({
				...state,
				studentAmbassadorList: {
				  ...state.studentAmbassadorList,
				  loading: false,
				  data: action.payload.data || state.studentAmbassadorList.data,
				  error: '',
				},
			  }),
	  },
	  defaultState
	)
}

const selectCampusAmbassadorList = (state: GlobalState) => state.campusambassador.campusAmbassadorList;
const selectCampusAmbassadorEdition = (state: GlobalState) => state.campusambassador.campusAmbassadorEdition;
const selectStudentAmbassadorList = (state: GlobalState) => state.campusambassador.studentAmbassadorList;

const campusAmbassadorListActionMap = { getCampusAmbassadorList, getCampusAmbassadorEdition, getStudentAmbassadorList};
export const useCampusAmbassadorList = () => useRedux(selectCampusAmbassadorList, campusAmbassadorListActionMap);
export const useCampusAmbassadorEdition = () => useRedux(selectCampusAmbassadorEdition, campusAmbassadorListActionMap);
export const useStudentAmbassadorList = () => useRedux(selectStudentAmbassadorList, campusAmbassadorListActionMap);

