import React, { MouseEvent } from 'react';
import classnames from 'classnames';
import { isExist } from 'util/helper';

import Icon, { IconSize } from 'components/atoms/Icon';

import styles from './index.module.css';

export interface ButtonProperty {
	className?: string;
	disabled?: boolean;
	color?: 'primary' | 'secondary' | 'grey' | 'transparent';
	outline?: boolean;
	shape?: 'rectangle' | 'pill';
	size?: 'large' | 'medium' | 'small';
	Icon?: string;
	sizeIcon?: IconSize;
	onClick?: (e: MouseEvent) => void;
}

const Button: React.FC<ButtonProperty> = ({
	className,
	disabled = false,
	outline = false,
	shape = 'rectangle',
	size = 'large',
	color = 'primary',
	children,
	Icon: PropsIcon,
	sizeIcon,
	onClick = () => {},
	...props
}) => (
	<button
		className={classnames(
			styles.button,
			className,
			{
				[styles.disabled]: disabled,
				[styles.filled]: !outline,
				[styles.outline]: outline,
				[styles.hasIcon]: isExist(PropsIcon),
			},
			styles[shape],
			styles[size],
			styles[color],
		)}
		type="button"
		onClick={e => {
			if (!disabled) {
				onClick(e);
			}
		}}
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
	>
		<span>{children}</span>
		{PropsIcon && <Icon className={styles.icon} src={PropsIcon} size={sizeIcon} />}
	</button>
);

export default Button;
