import React from 'react';
import { getProducts } from 'models/product';
import { CustomRoute } from 'util/hook/useRouter';
import SponsorUsDetailRoute from './SponsorUsDetail';


export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/sponsor-us',
	components: () => [],
	render: (_, children) => children,
	onEnter: async () => {},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'sponsorUs' */ './component')],
			render: ([SponsorUs]) => <SponsorUs />,
			onEnter: async ({next, store}) => {
				console.log('on Enter SponsorUs');
				store.dispatch(getProducts());
				await next();
				console.log('on Enter SponsorUs / end');
			},
		},
		SponsorUsDetailRoute,
	],
};
export default routes;
