import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';

import { isExist } from 'util/helper';

import { useNewsList } from 'models/news';
import { useEventList } from 'models/event';
import { useMultiMediaThemeList } from 'models/multiMedia';

import Link from 'components/atoms/Link';
import Icon from 'components/atoms/Icon';
import arrowIcon from 'images/icon/general/arrow-right-with-tail.inline.svg';
import arrowLeftIcon from 'images/icon/general/arrow-left-with-tail.inline.svg';

import styles from './index.module.css';

export interface NaviItemProperty {
	id: string | number;
	title: string;
	link?: string;
	onClick?: () => void;
}

export interface NaviMenuProperty extends NaviItemProperty {
	sub_group?: NaviItemProperty[];
}

export interface NavigatorProperty {
	data: NaviMenuProperty[];
}

interface ActiveMap {
	news: string;
	events: string;
	'multi-media-centre': string;
}

const LiWithSubMenu: React.FC<{
	menu: NaviMenuProperty; activeMap: ActiveMap;
}> = ({ menu, activeMap }) => {
	const liRef = useRef<HTMLLIElement>(null);
	return (
		<li
			className={styles.group}
			key={menu.id}
			id={`${menu.id}`}
			ref={liRef}
		>
			<div className={styles.groupTitle}>
				<span>{menu.title}</span>
			</div>
			<div
				className={styles.groupWrapper}
				style={{
					left: `${liRef.current?.getBoundingClientRect().x}px`,
					top: liRef.current?.getBoundingClientRect().y
				}}
			>
				<ul className={styles.groupContent}>
					{menu.sub_group?.map(subItem => (
						<li
							key={subItem.id}
							className={classnames(styles.innerItem, {
								[styles.active]: subItem.id.toString() === activeMap[menu.id.toString()],
							})}
						>
							<Link to={subItem.link} onClick={subItem.onClick}>
								{subItem.title}
								<Icon src={arrowIcon} size="x-small" />
							</Link>
						</li>
					))}
				</ul>
			</div>
		</li>
	)
}

const Navigator: React.FC<NavigatorProperty> = ({ data }) => {
	const navContainerRef = useRef<HTMLDivElement>(null);
	const navRef = useRef<HTMLElement>(null);

	const [showLeftArrow, setShowLeftArrow] = useState(false);
	const [showRightArrow, setShowRightArrow] = useState(false);
	const [, setScrollLeft] = useState<number>(0);

	const [{ category: newsCategory }] = useNewsList();
	const [{ currentCategoryId: eventCategory }] = useEventList();
	const [{ currentCategoryId: mediaCategory }] = useMultiMediaThemeList();

	// 0: All
	const activeMap = {
		news: newsCategory === 'ALL' ? '0' : newsCategory, // string
		events: eventCategory.toString(), // number
		'multi-media-centre': mediaCategory.toString(), // number
	};

	const scrollUnit = 120;

	const scrollToLeft = () => {
		if (navRef.current) {
			navRef.current.scrollTo({ left: navRef.current.scrollLeft - scrollUnit, behavior: 'smooth' })
		}
	}

	const scrollToRight = () => {
		if (navRef.current) {
			navRef.current.scrollTo({ left: navRef.current.scrollLeft + scrollUnit, behavior: 'smooth' })
		}
	}

	const handleSetArrowState = () => {
		if (navRef.current) {
			setScrollLeft(navRef.current.scrollLeft)
			setShowLeftArrow(navRef.current.scrollLeft > 0);
			setShowRightArrow(navRef.current.scrollLeft < navRef.current.scrollWidth - navRef.current.clientWidth);
		}
	}

	useEffect(() => {
		if (navRef.current) {
			navRef.current.scrollLeft = 0;
		}
		handleSetArrowState();
	}, [data]);

	useLayoutEffect(() => {
		window.addEventListener('resize', handleSetArrowState);
		if (navRef.current) {
			navRef.current.addEventListener('scroll', handleSetArrowState);
		}

		return () => {
			window.removeEventListener('resize', handleSetArrowState)
			if (navRef.current) {
				navRef.current.removeEventListener('scroll', handleSetArrowState);
			}
		};
	}, []);

	return (
		<div className={styles.navContainer} ref={navContainerRef}>
			<AnimatePresence>
				{showLeftArrow && (
					<motion.div
						initial={{ opacity: 1, width: '0' }}
						animate={{ opacity: 1, width: '83px' }}
						exit={{ opacity: 1, width: '0' }}
						transition={{ duration: 0.15 }}
						style={{ overflow: 'hidden' }}
						className={styles.arrowIcon}
					>
						<Icon
							src={arrowLeftIcon}
							size='x-small'
							onClick={() => scrollToLeft()}
						/>
					</motion.div>)}
			</AnimatePresence>
			<nav className={styles.navigator} ref={navRef}>
				<ul>
					{data.map(menu => {
						if (isExist(menu.sub_group)) {
							return (
								<LiWithSubMenu menu={menu} activeMap={activeMap} />
							);
						}

						return (
							<li key={menu.id}>
								<Link to={menu.link}>{menu.title}</Link>
							</li>
						);
					})}
				</ul>
			</nav >
			<AnimatePresence>
				{showRightArrow && (
					<motion.div
						initial={{ opacity: 1, width: '0' }}
						animate={{ opacity: 1, width: '83px' }}
						exit={{ opacity: 1, width: '0', position: 'absolute' }}
						transition={{ duration: 0.15 }}
						style={{ overflow: 'hidden' }}
						className={classnames(styles.arrowIcon, styles.rightArrow)}
					>
						<Icon
							src={arrowIcon}
							size='x-small'
							onClick={() => scrollToRight()}
						/>
					</motion.div>)}
			</AnimatePresence>
		</div>
	);
};

export default Navigator;
