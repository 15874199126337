import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

import { getMultiMediaEpisodeInfo } from 'models/multiMedia';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/:episodeID',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next, store, params }) => {
		const { episodeID } = params;
		console.log(`on Enter MultiMedia/Theme/${episodeID}`);

		await store.dispatch(getMultiMediaEpisodeInfo(`${episodeID}`));
		const children = await next();
		console.log(`on Enter MultiMedia/Theme/${episodeID} end`);
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'episode' */ './component')],
			render: ([Episode]) => <Episode />,
		},
	],
};

export default routes;
