import { createAction, handleActions, Action } from 'redux-actions';
import { Dispatch } from 'redux';
import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';
import { isExist } from 'util/helper';

import { GetState, State as GlobalState } from './reducers';

export interface PublicationItemProperty {
	id: number;
	title: string;
	session: number;
	publishDate: string;
	image: string;
	stockStatus: string;
}

export interface MagazineItemProperty {
	id: number;
	image: string;
	session: number;
}

type PublicationListItemPayload = Pick<State['publicationList'], 'data' | 'currentYear'>;

type MagazineListItemPayload = Pick<State['magazineList'], 'data' | 'currentYear'>;

export const getPublicationList = createAction<Promise<PublicationListItemPayload>, string>('GET_PUBLICATION_LIST', async year => {
	try {
		const newYear = isExist(year) && year !== defaultState.publicationList.currentYear ? year : '';

		const { status, message, data } = await wrapFetch(
			`magazines?type=fountain&year=${newYear}`,
			{
				method: 'GET',
			},
		);

		if (status !== 200) {
			throw new Error(message);
		}

		return {
			...data,
			currentYear: newYear,
		};
	} catch (error) {
		throw new Error((error as Error).message);
	}
});

export const getMagazineList = createAction<Promise<MagazineListItemPayload>, string>('GET_MAGAZINE_LIST', async year => {
	try {
		const newYear = isExist(year) && year !== defaultState.publicationList.currentYear ? year : '';

		const { status, message, data } = await wrapFetch(
			`magazines?type=old&year=${newYear}`,
			{
				method: 'GET',
			},
		);

		if (status !== 200) {
			throw new Error(message);
		}

		return {
			...data,
			currentYear: newYear,
		};
	} catch (error) {
		throw new Error((error as Error).message);
	}
});

export const clearPublicationList = createAction('CLEAR_PUBLICATION_LIST');

export const clearMagazineList = createAction('CLEAR_MAGAZINE_LIST');

export type PublicationYearsListProperty = string[];

export type MagazineYearsListProperty = string[];

export const getPublicationYearsList = createAction<Promise<PublicationYearsListProperty>>(
	'GET_PUBLICATION_YEARS_LIST',
	async () => {
		try {
			const { status, message, data } = await wrapFetch(
				`magazines/years?type=fountain`,
				{
					method: 'GET',
				},
			);

			if (status !== 200) {
				throw new Error(message);
			}

			return data.data;
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

export const getMagazineYearsList = createAction<Promise<MagazineYearsListProperty>>(
	'GET_MAGAZINE_YEARS_LIST',
	async () => {
		try {
			const { status, message, data } = await wrapFetch(
				`magazines/years?type=old`,
				{
					method: 'GET',
				},
			);

			if (status !== 200) {
				throw new Error(message);
			}

			return data.data;
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

export interface PublicationInfoProperty extends PublicationItemProperty {
	type: string;
	story: string;
	catalogue: string;
	otherSession: PublicationItemProperty[];
}

export interface MagazineInfoProperty extends MagazineItemProperty {
	type: string;
	title: string;
	publishDate: string;
	intro: string;
	catalogue: string;
	pdfFile: string;
}

const setPublicationInfo = createAction<PublicationInfoProperty, PublicationInfoProperty>(
	'SET_PUBLICATION_INFO',
	data => data,
);

const setMagazineInfo = createAction<MagazineInfoProperty, MagazineInfoProperty>(
	'SET_MAGAZINE_INFO',
	data => data,
);

export const getFountainInfoProcess = createAction<(dispatch: Dispatch, getState: GetState) => Promise<string>, string>(
	'GET_FOUNTAIN_INFO_PROCESS',
id => async (dispatch: Dispatch) => {
	try {
		const { status, message, data } = await wrapFetch(
			`magazines/${id}`,
			{ method: 'GET' },
		);

		if (status !== 200) {
			throw new Error(message);
		}

		const { data: fountainData } = data;

		if (fountainData.type === 'FOUNTAIN') {
			dispatch(setPublicationInfo(fountainData));
		} else {
			dispatch(setMagazineInfo(fountainData));
		};

		return fountainData.type;
	} catch (error) {
		throw new Error((error as Error).message);
	}
});

export const setFountainPreview = createAction('SET_FOUNTAIN_PREVIEW', (data: PublicationInfoProperty | MagazineInfoProperty) => (dispatch: Dispatch) => {
	if (data.type === 'FOUNTAIN') {
		dispatch(setPublicationInfo(data as PublicationInfoProperty));
	} else {
		dispatch(setMagazineInfo(data as MagazineInfoProperty));
	}

	return data.type;
});

export interface State {
	publicationList: {
		loading: boolean;
		error: string;
		data: PublicationItemProperty[];
		yearsList: PublicationYearsListProperty;
		currentYear: string,
	};

	magazineList: {
		loading: boolean;
		error: string;
		data: MagazineItemProperty[];
		yearsList: MagazineYearsListProperty;
		currentYear: string,
	};

	fountainInfo: {
		loading: boolean;
		error: string;
		type: string;
		publicationInfo: PublicationInfoProperty;
		magazineInfo: MagazineInfoProperty;
	}
}

export const defaultState: State = {
	publicationList: {
		loading: false,
		error: '',
		data: [],
		yearsList: [],
		currentYear: '最新出刊',
	},

	magazineList: {
		loading: false,
		error: '',
		data: [],
		yearsList: [],
		currentYear: '最新出刊',
	},

	fountainInfo: {
		loading: false,
		error: '',
		type: '',
		publicationInfo: {
			id: 0,
			type: '',
			image: '',
			session: 0,
			publishDate: '',
			title: '',
			story: '',
			catalogue: '',
			stockStatus: '',
			otherSession: [],
		},
		magazineInfo: {
			id: 0,
			type: '',
			title: '',
			image: '',
			session: 0,
			publishDate: '',
			intro: '',
			catalogue: '',
			pdfFile: '',
		},
	}
};

export const reducer = {
	fountain: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_PUBLICATION_LIST_PENDING: state => ({
				...state,
				publicationList: {
					...state.publicationList,
					loading: true,
					error: '',
				},
			}),

			GET_PUBLICATION_LIST_FULFILLED: (state, action: Action<PublicationListItemPayload>) => ({
				...state,
				publicationList: {
					...state.publicationList,
					loading: false,
					data: [
						...state.publicationList.data,
						...action.payload.data,
					],
					currentYear: action.payload.currentYear,
				},
			}),

			GET_PUBLICATION_LIST_REJECTED: (state, action) => ({
				...state,
				publicationList: {
					...state.publicationList,
					loading: false,
					error: action.payload.message,
				},
			}),

			GET_MAGAZINE_LIST_PENDING: state => ({
				...state,
				magazineList: {
					...state.magazineList,
					loading: true,
					error: '',
				},
			}),

			GET_MAGAZINE_LIST_FULFILLED: (state, action: Action<MagazineListItemPayload>) => ({
				...state,
				magazineList: {
					...state.magazineList,
					loading: false,
					data: [
						...state.magazineList.data,
						...action.payload.data,
					],
					currentYear: action.payload.currentYear,
				},
			}),

			GET_MAGAZINE_LIST_REJECTED: (state, action) => ({
				...state,
				magazineList: {
					...state.magazineList,
					loading: false,
					error: action.payload.message,
				},
			}),

			GET_PUBLICATION_YEARS_LIST_PENDING: state =>({
				...state,
				publicationList: {
					...state.publicationList,
					loading: true,
					error: '',
				},
			}),

			GET_PUBLICATION_YEARS_LIST_FULFILLED: (state, action: Action<PublicationYearsListProperty>) => ({
				...state,
				publicationList: {
					...state.publicationList,
					loading: false,
					yearsList: action.payload,
				},
			}),

			GET_PUBLICATION_YEARS_LIST_REJECT: (state, action) => ({
				...state,
				publicationList: {
					...state.publicationList,
					loading: false,
					error: action.payload.message,
				},
			}),

			GET_MAGAZINE_YEARS_LIST_PENDING: state =>({
				...state,
				magazineList: {
					...state.magazineList,
					loading: true,
					error: '',
				},
			}),

			GET_MAGAZINE_YEARS_LIST_FULFILLED: (state, action: Action<MagazineYearsListProperty>) => ({
				...state,
				magazineList: {
					...state.magazineList,
					loading: false,
					yearsList: action.payload,
				},
			}),

			GET_MAGAZINE_YEARS_LIST_REJECT: (state, action) => ({
				...state,
				magazineList: {
					...state.magazineList,
					loading: false,
					error: action.payload.message,
				},
			}),

			GET_FOUNTAIN_INFO_PROCESS_PENDING: state => ({
				...state,
				fountainInfo: {
					...state.fountainInfo,
					loading: true,
					type: '',
					error: '',
				},
			}),

			GET_FOUNTAIN_INFO_PROCESS_FULFILLED: (state, action: Action<string>) => ({
				...state,
				fountainInfo: {
					...state.fountainInfo,
					loading: false,
					type: action.payload,
					error: '',
				},
			}),

			GET_FOUNTAIN_INFO_PROCESS_REJECTED: (state, action) => ({
				...state,
				fountainInfo: {
					...state.fountainInfo,
					loading: false,
					type: '',
					error: action.payload.message,
				},
			}),

			SET_PUBLICATION_INFO: (state, action: Action<PublicationInfoProperty>) => ({
				...state,
				fountainInfo: {
					...state.fountainInfo,
					publicationInfo: action.payload,
				},
			}),

			SET_MAGAZINE_INFO: (state, action: Action<MagazineInfoProperty>) => ({
				...state,
				fountainInfo: {
					...state.fountainInfo,
					magazineInfo: action.payload,
				},
			}),

			CLEAR_PUBLICATION_LIST: state => ({
				...state,
				publicationList: {
					...state.publicationList,
					loading: false,
					error: '',
					data: [],
				},
			}),

			CLEAR_MAGAZINE_LIST: state => ({
				...state,
				magazineList: {
					...state.magazineList,
					loading: false,
					error: '',
					data: [],
				},
			}),

			SET_FOUNTAIN_PREVIEW: (state, action: Action<string>) => ({
				...state,
				fountainInfo: {
					...state.fountainInfo,
					type: action.payload,
				},
			}),
		},
		defaultState,
	), // eslint-disable-line @typescript-eslint/no-explicit-any
};

const selectPublicationList = (state: GlobalState) => ({
	...state.fountain.publicationList
});

export const usePublicationList = () => useRedux(selectPublicationList, { getPublicationList, clearPublicationList });

const selectMagazineList = (state: GlobalState) => ({
	...state.fountain.magazineList
});

export const useMagazineList = () => useRedux(selectMagazineList, { getMagazineList, clearMagazineList });

const selectFountainYearsList = (state: GlobalState) => ({
	publicationYearsList: state.fountain.publicationList.yearsList,
	magazineYearsList: state.fountain.magazineList.yearsList,
});

export const useFountainYearsList = () => useRedux(selectFountainYearsList, { getPublicationYearsList, getMagazineYearsList });

export const selectFountainInfoType = (state: GlobalState) => state.fountain.fountainInfo.type;

const selectFountainInfo = (state: GlobalState) => state.fountain.fountainInfo;

export const useFountainInfo = () => useRedux(selectFountainInfo, {});

