import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

import { getEventInfo, setEventInfoSubEventId } from 'models/event';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '',
	components: () => [],
	render: (_, children) => children,
	onEnter: async () => {},
	children: [
		{
			path: '',
			components: () => [],
			onEnter: async ({ next }) => {
				const children = await next();
				return children;
			},
			render: (_, children) => children,
			children: [
				{
					path: '/south-taipei-space-one',
					components: () => [],
					render: (_, children) => children,
					onEnter: async () => {},
					children: [
						{
							path: '/:southTaipeiSpaceID',
							components: () => [],
							render: (_, children) => children,
							onEnter: async () => {},
							children: [
								{
									path: '',
									components: () => [
										import(/* webpackChunkName: 'southTaipeiSpaceDetail' */ './component'),
									],
									render: ([SouthTaipeiSpaceDetail]) => <SouthTaipeiSpaceDetail />,
									onEnter: async ({ next, store, params }) => {
										const { southTaipeiSpaceID } = params;
										console.log(`on Enter SouthTaipeiSpace/${southTaipeiSpaceID}`);

										await store.dispatch(getEventInfo(`${southTaipeiSpaceID}`));
										// 先清空
										await store.dispatch(setEventInfoSubEventId(''));
										await next();
										console.log(`on Enter SouthTaipeiSpace/${southTaipeiSpaceID} end`);
									},
								},
								{
									path: '/:subEventID',
									components: () => [import(/* webpackChunkName: 'subSouthTaipeiSpaceDetail' */ './component')],
									render: ([SouthTaipeiSpaceDetail]) => <SouthTaipeiSpaceDetail />,
									onEnter: async ({ next, store, params }) => {
										const { southTaipeiSpaceID, subEventID } = params;
										console.log(`on Enter SouthTaipeiSpace/${southTaipeiSpaceID}/${subEventID}`);
								
										await store.dispatch(getEventInfo(`${southTaipeiSpaceID}`));
										await store.dispatch(setEventInfoSubEventId(`${subEventID}`));
								
										await next();
										console.log(`on Enter SouthTaipeiSpace/${southTaipeiSpaceID}/${subEventID} end`);
									},
								},
							],
						},
					],
				},
				{
					path: '/south-taipei-space-two',
					components: () => [],
					render: (_, children) => children,
					onEnter: async () => {},
					children: [
						{
							path: '/:southTaipeiSpaceID',
							components: () => [],
							render: (_, children) => children,
							onEnter: async () => {},
							children: [
								{
									path: '',
									components: () => [
										import(/* webpackChunkName: 'southTaipeiSpaceDetail' */ './component'),
									],
									render: ([SouthTaipeiSpaceDetail]) => <SouthTaipeiSpaceDetail />,
									onEnter: async ({ next, store, params }) => {
										const { southTaipeiSpaceID } = params;
										console.log(`on Enter SouthTaipeiSpace/${southTaipeiSpaceID}`);

										await store.dispatch(getEventInfo(`${southTaipeiSpaceID}`));
										// 先清空
										await store.dispatch(setEventInfoSubEventId(''));
										await next();
										console.log(`on Enter SouthTaipeiSpace/${southTaipeiSpaceID} end`);
									},
								},
								{
									path: '/:subEventID',
									components: () => [import(/* webpackChunkName: 'subSouthTaipeiSpaceDetail' */ './component')],
									render: ([SouthTaipeiSpaceDetail]) => <SouthTaipeiSpaceDetail />,
									onEnter: async ({ next, store, params }) => {
										const { southTaipeiSpaceID, subEventID } = params;
										console.log(`on Enter SouthTaipeiSpace/${southTaipeiSpaceID}/${subEventID}`);
								
										await store.dispatch(getEventInfo(`${southTaipeiSpaceID}`));
										await store.dispatch(setEventInfoSubEventId(`${subEventID}`));
								
										await next();
										console.log(`on Enter SouthTaipeiSpace/${southTaipeiSpaceID}/${subEventID} end`);
									},
								},
							],
						},
					],
				},
			],
		},
	],
};

export default routes;
