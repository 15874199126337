import React from 'react';

import {
	clearProductInfo,
	clearProductSpecification,
	getProductInfo,
	selectProductSpecification,
} from 'models/product';
import { 
	getPublicationList,
	clearPublicationList,
} from 'models/fountain';
import { CustomRoute } from 'util/hook/useRouter';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/:sponsorUsID',
	components: () => [import(/* webpackChunkName: 'sponsorUsDetail' */ './component')],
	render: ([SponsorUsDetail]) => <SponsorUsDetail />,
	onEnter: async ({ next, params, store }) => {
		const { sponsorUsID } = params;
		console.log(`on Enter SponsorUs/${sponsorUsID}`);

		store.dispatch(clearProductInfo());
		store.dispatch(clearProductSpecification());
		await store.dispatch(getProductInfo(sponsorUsID as string));

		const {
			product: { productInfo },
		} = store.getState();

		// TODO: 這邊之後可以改成贊助類別  === 'Fountain' 才拿雜誌的資料
		await store.dispatch(clearPublicationList());
		await store.dispatch(getPublicationList(''));
		store.dispatch(selectProductSpecification({ key: 'id', value: productInfo.id }));

		await next();
		console.log(`on Enter SponsorUs/${sponsorUsID} end`);
	},
};

export default routes;
