import React from 'react';
import classnames from 'classnames';

import { useLanguage } from 'models/i18n';
import { useRouting } from 'models/routing';
// import { useBoolean } from 'util/hook';
import { LangCode, langCodeList } from 'util/i18n/config';
import { mapLanguageOption } from 'util/i18n';

import styles from './index.module.css';

interface NaviLangProperty {
	className?: string;
	color?: 'default' | 'white';
}

const NaviLang: React.FC<NaviLangProperty> = ({ className, color = 'default' }) => {
	const [prevLan] = useLanguage();
	const [{ search }, { pushRoute }] = useRouting();

	const onClickHandler = (nextLang: LangCode) => () => {
		pushRoute({ nextLang, search });
	};

	return (
		<div className={classnames(styles.naviLang, styles[color], className)}>
			{langCodeList.map(lang => {
				const { value, label } = mapLanguageOption(lang);

				return (
					<div
						key={value}
						className={classnames(styles.item, { [styles.active]: value === prevLan })}
						role="button"
						tabIndex={0}
						onKeyPress={() => {}}
						onClick={onClickHandler(lang)}
					>
						{label}
					</div>
				);
			})}
		</div>
	);
};

export default NaviLang;
