import React from 'react';
import i18n from 'i18next';

import { CustomRoute } from 'util/hook/useRouter';
import { getOrderFormFromStorage } from 'models/order';
import { goToStage, getSingleProductFromStorage } from 'models/cart';

import storage from 'util/storage'
import { baseUrl } from 'store/history';
import { isExist } from 'util/helper';

const routes: CustomRoute = {
	path: '/checkout',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ store, history, search }) => {
		const {
			auth: { token },
		} = store.getState();

		if (!isExist(token)) {
			history.replace(`${baseUrl}/${i18n.language}${isExist(search) ? search : ''}`);
		}
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'sponsorCheckout' */ './component')],
			render: ([SponsorCheckout]) => <SponsorCheckout />,
			onEnter: async ({ store }) => {
				console.log('on Enter sponsorCheckout');
				await store.dispatch(goToStage(2));
				console.log('on Enter sponsorCheckout / end');
			},
		},
		{
			path: '/:orderNo',
			components: () => [import(/* webpackChunkName: 'sponsorCheckout' */ './component')],
			render: ([SponsorCheckout], _, orderNo) => <SponsorCheckout isRepayStatus={isExist(orderNo)} orderNo={orderNo} />,
			onPreEnter: async ({ store, params }) => {
				console.log('on Enter sponsorCheckout');

				await store.dispatch(goToStage(2));

				const orderNo = params?.orderNo;
				console.log('orderNo in onPreEnter: ', orderNo)

				// 網址帶有訂單編號 no，視為重新付款狀態，從 localStorage 取出暫存的訂單內容
				if (isExist(orderNo) && isExist(storage.getItem(`order-${orderNo}`))) {
					store.dispatch(getOrderFormFromStorage(orderNo as string));
					store.dispatch(getSingleProductFromStorage(orderNo as string));
				}

				console.log('on Enter sponsorCheckout / end');
				return orderNo;
			},
		},
	],
};

export default routes;
