import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';


const routes: CustomRoute = {
	path: '/page',
	components: () => [],
	render: (_, children) => children,
	onEnter: async () => {},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'page' */ './component')],
			render: ([Page]) => <Page />,
			onEnter: async ({ next }) => {
				console.log('on Enter page');
				await next();
				console.log('on Enter page / end');
			},
		},
	],
};

export default routes;
