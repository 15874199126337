import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { clearAwardList, getAwardList, getAwardInfo } from 'models/award';


const routes: CustomRoute = {
	path: '/presidential-cultural-award',
	components: () => [],
	render: (_, children) => children,
	onEnter: async () => {},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'award' */ './component')],
			render: ([Award]) => <Award />,
			onEnter: async ({ next, store }) => {
				console.log('on Enter award');
				await store.dispatch(clearAwardList());
				await store.dispatch(getAwardList());
				await store.dispatch(getAwardInfo('latest'));
				await next();
				console.log('on Enter award / end');
			},
		},
	],
};

export default routes;
