/* eslint-disable no-shadow */
/* eslint-disable camelcase */

import { createAction, handleActions, Action } from 'redux-actions';
import { Dispatch } from 'redux';
import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';

import { State as GlobalState } from './reducers';
import { setNewsPreview } from './news';
import { setEventInfoPreview, setSubEventInfoPreview } from './event';
import { setThemeInfoPreview, setEpisodeInfoPreview } from './multiMedia';
import { setAwardInfoPreview, setAwardWinnerInfoPreview } from './award';
import { setFountainPreview } from './fountain';
import { setProductInfoPreview } from './product';



export enum PAGE_TYPE {
	NEWS = 'news',
	EVENT = 'event',
	SUBEVENT = 'sub_event',
	FOUNTAIN = 'magazine',
	THEME = 'article_theme', // 文化視聽
	EPISODE = 'article_episode', // 文化視聽單集
	AWARD = 'president_award',
	WINNER = 'president_award_winner',
	PLAN = 'sponsorship_plan'
}

export const getPreview = createAction(
	'GET_PREVIEW',
	(type: string, id: string, previewToken: string) => async (dispatch: Dispatch) => {
		try {
			const { status, message, data } = await wrapFetch(`preview/${type}/${id}`, {
				method: 'GET',
				headers: {
					'X-PREVIEW-TOKEN': previewToken,
				},
			});

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			const { data: previewData } = data;

			console.log('type:', type, "data:", data);

			switch (type) {
				case PAGE_TYPE.NEWS:
					dispatch(setNewsPreview(previewData));
					break;
				case PAGE_TYPE.EVENT:
					dispatch(setEventInfoPreview(previewData));
					break;
				case PAGE_TYPE.SUBEVENT:
					dispatch(setSubEventInfoPreview(previewData));
					break;
				case PAGE_TYPE.THEME:
					dispatch(setThemeInfoPreview(previewData));
					break;
				case PAGE_TYPE.EPISODE:
					dispatch(setEpisodeInfoPreview(previewData));
					break;
				case PAGE_TYPE.AWARD:
					dispatch(setAwardInfoPreview(previewData));
					break;
				case PAGE_TYPE.WINNER:
					dispatch(setAwardWinnerInfoPreview(previewData));
					break;
				case PAGE_TYPE.FOUNTAIN:
					dispatch(setFountainPreview(previewData));
					break;
				case PAGE_TYPE.PLAN:
					dispatch(setProductInfoPreview(previewData));
					break;
				default:
					break;
			}

			return type;
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

export const clearPreview = createAction('CLEAR_PREVIEW');

export interface State {
	preview: {
		loading: boolean;
		error: string;
		type: string;
	};
}

export const defaultState: State = {
	preview: {
		loading: false,
		error: '',
		type: '',
	},
};

export const reducer = {
	preview: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			CLEAR_PREVIEW: () => defaultState,

			GET_PREVIEW_PENDING: state => ({
				...state,
				preview: {
					...state.preview,
					loading: true,
					error: '',
				},
			}),

			GET_PREVIEW_FULFILLED: (state, action: Action<string>) => ({
				...state,
				preview: {
					...state.preview,
					loading: false,
					type: action.payload,
				},
			}),

			GET_PREVIEW_REJECTED: (state, action) => ({
				...state,
				preview: {
					...state.preview,
					loading: false,
					error: action.payload.message,
				},
			}),
		},
		defaultState,
	), // eslint-disable-line @typescript-eslint/no-explicit-any
};

/* +----------------------------------------------------------------------
++ usePreview ++
++----------------------------------------------------------------------*/

const selectPreview = (state: GlobalState) => state.preview.preview;
// for local demo
// export const usePreview = () => useRedux(selectPreview, {});

const previewActionMap = { getPreview, clearPreview };
export const usePreview = () => useRedux(selectPreview, previewActionMap);
