import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';


const routes: CustomRoute = {
	path: '/links',
	components: () => [],
	render: (_, children) => children,
	onEnter: async () => {},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'links' */ './component')],
			render: ([Links]) => <Links />,
			onEnter: async ({ next }) => {
				console.log('on Enter links');
				await next();
				console.log('on Enter links / end');
			},
		},
	],
};

export default routes;
