import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

interface SEOProperty {
	title?: string;
	description?: string;
	image?: string;
	keywords?: string;
}

const SEO: React.FC<SEOProperty> = ({
	title = '中華文化總會',
	description,
	image = 'https://www.gacc.org.tw/s3/prod/gacc_upload/s3_upload/gacc-ogimage.png',
	keywords,
}) => {
	const url = window.location.href;

	// resize image
	const width = 500;
	const [height, setHeight] = useState(500);

	useEffect(() => {
		const img = new Image();
		img.addEventListener('load', () => {
			setHeight((img.naturalHeight / img.naturalWidth) * width);
		});
		img.src = image;
	}, []);

	useEffect(() => {
		document.title = title;
		return () => {
			document.title = '中華文化總會';
		};
	}, [title]);

	return (
		<Helmet encodeSpecialCharacters={false}>
			<meta name="description" content={description} />
			<meta name="image" content={image} />
			<meta name="keywords" content={keywords} />
			<meta name="robots" content="index, follow ,max-image-preview:large" />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:url" content={url} />
			<meta property="og:image" content={image} />
			<meta property="og:image:secure_url" content={image} />
			<meta property="og:image:width" content={width.toString()} />
			<meta property="og:image:height" content={height.toString()} />
			<meta property="og:type" content="website" />
			<meta property="og:site_name" content="中華文化總會" />
			<meta property="og:locale" content="zh_TW" />
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:url" content={url} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:image" content={image} />
			<link rel="canonical" href={url} />
		</Helmet>
	);
};

export default SEO;
