import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/member',
	components: () => [],
	render: (_, children) => children,
	onEnter: async () => {},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'Member' */ './component')],
			render: ([Member]) => <Member />,
			onEnter: async ({ store, next, history: { replace } }) => {
				const {
					auth: { token },
				} = store.getState();
				
				if (!token) {
					replace('/login');
				}
				
				console.log('on Enter member');
				await next();
				console.log('on Enter member end');
			},
		},
	],
};
export default routes;
